import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Slider, Input, makeStyles, Typography } from '@material-ui/core'
import { useDebouncedCallback } from 'shared/util/hooks'
import { ContainerFullWidth } from './Common'

const useStyles = makeStyles({
  input: {
    width: 60,
    '& .MuiInput-input': {
      textAlign: 'end',
    },
  },
})

interface Props {
  id: string
  label: string
  onChange: (value: number) => void
  min: number
  max: number
  value: number
}

/**
 * A combined input with a rangeslider and text.
 * The range triggers onChange only when a different value is selected.
 * The text input triggers onChange after a delay if a different value is given.
 * The text input +/- has a step of 10% of the whole range.
 */
const InputSlider = ({ id, value, label, onChange, min, max }: Props) => {
  const classes = useStyles()
  const [currentValue, setCurrentValue] = useState(value)

  const [debounced, cancel] = useDebouncedCallback(onChange, 250)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const handleSliderChange = useCallback((_, newValue: number | number[]) => {
    setCurrentValue(Array.isArray(newValue) ? newValue[0] : newValue)
  }, [])

  const handleSliderCommit = useCallback(
    (_, valueOrArray: number | number[]) => {
      const newValue = Array.isArray(valueOrArray) ? valueOrArray[0] : valueOrArray
      if (value !== newValue) onChange(newValue)
    },
    [onChange, value]
  )

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = Math.max(min, Math.min(max, Number(event.target.value)))
      setCurrentValue(newValue)
      debounced(newValue)
    },
    [min, max, debounced]
  )

  const handleBlur = useCallback(() => {
    if (value !== currentValue) onChange(currentValue)
    cancel()
  }, [cancel, onChange, value, currentValue])

  return (
    <ContainerFullWidth>
      <Typography id={id} gutterBottom className='label' component='span'>
        {label}
      </Typography>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs>
          <Slider
            color='secondary'
            value={currentValue}
            min={min}
            max={max}
            onChange={handleSliderChange}
            onChangeCommitted={handleSliderCommit}
            aria-labelledby={id}
          />
        </Grid>
        <Grid item>
          <Input
            className={classes.input}
            value={currentValue}
            margin='dense'
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: Math.ceil((max - min) / 10),
              min,
              max,
              type: 'number',
              'aria-labelledby': id,
            }}
            disableUnderline
          />
        </Grid>
      </Grid>
    </ContainerFullWidth>
  )
}

export default InputSlider
