import React, { PropsWithChildren, ReactNode, useCallback, useState, Children } from 'react'
import { Collapse, List, ListItem, ListItemText, makeStyles, styled } from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import theme from 'theme'

export const sectionName = 'sectionmenu-section'
export const contentName = 'sectionmenu-content'

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
})

interface Props {
  labels: (string | undefined)[]
  stretch: number
  id?: string
}

/**
 * A list that allows toggling children visiblity on and off.
 */
const SectionMenu = ({ id, labels, stretch, children }: PropsWithChildren<Props>) => {
  const classes = useStyles()

  return (
    <List id={id} className={classes.root}>
      {Children.map(children, (child, index) => {
        const label = labels[index]
        if (label)
          return <Section id={`toolbar-section-${label}`} item={child} label={label} stretch={stretch === index} />
        return <div className={contentName}>{child}</div>
      })}
    </List>
  )
}

interface SectionProps {
  label: string
  item: ReactNode
  stretch: boolean
  id?: string
}

const Section = ({ item, label, stretch, id }: SectionProps) => {
  const [open, setOpen] = useState(true)

  const classes = useCollapseStyles()
  const stretchedClasses = useStrecthedStyles()

  const handleClick = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])
  return (
    <>
      <ColoredListItem id={id} button onClick={handleClick} className={`${sectionName} MuiPaper-elevation2`}>
        <ListItemText primary={label} primaryTypographyProps={{ variant: 'subtitle1' }} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ColoredListItem>
      <Collapse
        in={open}
        timeout='auto'
        unmountOnExit
        className={contentName}
        classes={stretch ? stretchedClasses : classes}
      >
        {item}
      </Collapse>
    </>
  )
}

const ColoredListItem = styled(ListItem)({
  backgroundColor: theme.palette.toolbar.header,
})

const useCollapseStyles = makeStyles({
  root: {
    flexGrow: 0,
    flexShrink: 0,
    overflow: 'hidden',
  },
  wrapper: {
    height: '100%',
    width: '100%',
  },
})

const useStrecthedStyles = makeStyles({
  root: {
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'hidden',
  },
  wrapper: {
    height: '100%',
    width: '100%',
  },
})

export default SectionMenu
