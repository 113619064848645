import { useMemo, useEffect } from 'react'
import { scaleLinear, rgb } from 'd3'
import { IScenario, IResultData, IMarkedScenario } from 'analyses/analysis.model'
import theme from 'theme'
import { ClickCoordinates, DrawingArea } from './useD3'
import { hexToRgb } from './colormap'

type Locations = Record<string, { x: number; y: number }>
type Colors = Record<string, string>
type Sizes = Record<string, number>

/**
 * Return the base size for each scatter point.
 */
const useSizes = (scenarios: IScenario[]) =>
  useMemo(() => {
    const { scatterBaseSize, scatterScalingSize } = theme.shape.contour
    const result = {} as Sizes
    const data = scenarios.map((item) => item.consistency)
    const min = Math.min(...data)
    const max = Math.max(...data)
    if (min === max) {
      scenarios.forEach((item) => {
        result[item.id] = scatterBaseSize
      })
    }
    scenarios.forEach((item) => {
      result[item.id] = ((item.consistency - min) / (max - min)) * scatterScalingSize + scatterBaseSize
    })
    return result
  }, [scenarios])

/**
 * Return the drawing coordinate for each scatter point.
 */
const useLocations = (contour: IResultData) =>
  useMemo(() => {
    const limitX = { min: contour.limit_x[0], max: contour.limit_x[1] }
    const limitY = { min: contour.limit_y[0], max: contour.limit_y[1] }
    const dimension = contour.height_grid.length
    const x = scaleLinear().domain([limitX.min, limitX.max]).range([0, dimension])
    const y = scaleLinear().domain([limitY.min, limitY.max]).range([dimension, 0])

    const result = {} as Locations
    contour.scenarios.forEach((item) => {
      result[item.id] = {
        x: x(item.x) ?? 0,
        y: y(item.y) ?? 0,
      }
    })
    return result
  }, [contour.scenarios, contour.limit_x, contour.limit_y, contour.height_grid])

/**
 * Return the selected scatter point, if any.
 */
const useSelecting = (
  locations: Locations,
  scatterScale: number,
  clickCoordinates: ClickCoordinates,
  onSelected: (selected: number) => void,
  onEditing: (editing: number) => void
) => {
  useEffect(() => {
    if (!clickCoordinates) {
      return
    }
    let minDelta = Number.MAX_VALUE
    let minIndex = null as number | null
    Object.values(locations).forEach((item, index) => {
      const delta = Math.sqrt((clickCoordinates.x - item.x) ** 2 + (clickCoordinates.y - item.y) ** 2)
      if (delta < minDelta) {
        minDelta = delta
        minIndex = index
      }
    })
    if (minIndex !== null && minDelta < theme.shape.contour.selectionPrecision / scatterScale) {
      onSelected(minIndex)
      if (clickCoordinates.isDouble) onEditing(minIndex)
    }
    // scatterScale missing on purpose so zoom won't reselect.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, clickCoordinates, onSelected, onEditing])
}

const hightlightRgb = hexToRgb(theme.palette.secondary.main) ?? { r: 255, g: 255, b: 255 }
const highlightColor = rgb(hightlightRgb.r, hightlightRgb.g, hightlightRgb.b, 1.0).formatRgb()

/**
 * Return the color for each scatter point.
 */
const useColors = (scenarios: IScenario[], marked: Record<string, IMarkedScenario>) =>
  useMemo(() => {
    const result = {} as Colors
    scenarios.forEach((item) => {
      const color = hexToRgb(marked[item.id]?.color)
      if (color) {
        result[item.id] = rgb(color.r, color.g, color.b, 1.0).formatRgb()
      } else {
        result[item.id] = theme.palette.contour.defaultColor
      }
    })
    return result
  }, [scenarios, marked])

/**
 * Return the selection color for each scatter point.
 */
const useSelectedColors = (
  scenarios: IScenario[],
  marked: Record<string, IMarkedScenario>,
  selected: number[],
  useSelectionDiamonds: boolean
) =>
  useMemo(() => {
    const result = {} as Colors
    scenarios
      .filter((item) => selected.includes(item.id))
      .forEach((item) => {
        if (useSelectionDiamonds) {
          const color = hexToRgb(marked[item.id]?.color)
          if (color) {
            result[item.id] = rgb(color.r, color.g, color.b, theme.palette.contour.selectionOpacity).formatRgb()
          } else {
            result[item.id] = theme.palette.contour.defaultSelectColor
          }
        } else {
          result[item.id] = highlightColor
        }
      })
    return result
  }, [scenarios, marked, selected, useSelectionDiamonds])

/**
 * Combines the data for each scatter point.
 */
const useData = (
  scenarios: IScenario[],
  locations: Locations,
  marked: Record<string, IMarkedScenario>,
  colors: Colors,
  sizes: Sizes
) =>
  useMemo(
    () =>
      scenarios
        .map((item) => ({
          x: locations[item.id].x,
          y: locations[item.id].y,
          color: colors[item.id],
          radius: sizes[item.id],
          label: marked[item.id]?.label,
        }))
        .reverse(),
    [scenarios, locations, marked, sizes, colors]
  )

/**
 * Combines the label data for each scatter point.
 */
const useLabelData = (scenarios: IScenario[], locations: Locations, marked: Record<string, IMarkedScenario>) =>
  useMemo(
    () =>
      scenarios
        .filter((item) => marked[item.id]?.label)
        .map((item) => ({
          x: locations[item.id].x,
          y: locations[item.id].y,
          color: marked[item.id]?.color,
          label: marked[item.id]?.label,
        })),
    [scenarios, locations, marked]
  )

/**
 * Combines the color data for each scatter point.
 */
const useMarkedColorData = (
  scenarios: IScenario[],
  locations: Locations,
  marked: Record<string, IMarkedScenario>,
  sizes: Sizes
) =>
  useMemo(
    () =>
      scenarios
        .filter((item) => marked[item.id]?.color)
        .map((item) => ({
          x: locations[item.id].x,
          y: locations[item.id].y,
          color: marked[item.id]?.color,
          radius: sizes[item.id],
        })),
    [scenarios, locations, marked, sizes]
  )

const defaultArray = [] as never[]
/**
 * Combines the selected data for each scatter point.
 */
const useSelectedData = (locations: Locations, selected: number[], colors: Colors, useSelectionDiamonds: boolean) =>
  useMemo(() => {
    if (!useSelectionDiamonds) return defaultArray
    return selected.map((id) => ({
      x: locations[id].x,
      y: locations[id].y,
      color: colors[id],
    }))
  }, [locations, selected, colors, useSelectionDiamonds])

/**
 * Combines base colors and selected colors to a single map if diamonds are not in use.
 */
const useCombinedColors = (colors: Colors, selected: Colors, useSelectionDiamonds: boolean) =>
  useMemo(() => {
    if (useSelectionDiamonds) return colors
    return {
      ...colors,
      ...selected,
    }
  }, [colors, selected, useSelectionDiamonds])

const createDiamondPath = ({ x, y }: { x: number; y: number }, offset: number) =>
  `M ${x - offset} ${y} ${x} ${y - offset} ${x + offset} ${y} ${x} ${y + offset} Z`

export const useScatter = (
  drawingArea: DrawingArea,
  scatterScale: number,
  clickCoordinates: ClickCoordinates,
  contour: IResultData,
  marked: Record<string, IMarkedScenario>,
  selected: number[],
  useSelectionDiamonds: boolean,
  onSelected: (selected: number) => void,
  onEditing: (editing: number) => void
) => {
  // Lots of hooks are needed to avoid recalculating everything on zoom or select.
  const locations = useLocations(contour)
  useSelecting(locations, scatterScale, clickCoordinates, onSelected, onEditing)
  const sizes = useSizes(contour.scenarios)
  const baseColors = useColors(contour.scenarios, marked)
  const selectedColors = useSelectedColors(contour.scenarios, marked, selected, useSelectionDiamonds)
  const colors = useCombinedColors(baseColors, selectedColors, useSelectionDiamonds)
  const data = useData(contour.scenarios, locations, marked, colors, sizes)
  const labelData = useLabelData(contour.scenarios, locations, marked)
  const markedData = useMarkedColorData(contour.scenarios, locations, marked, sizes)
  const selectedData = useSelectedData(locations, selected, selectedColors, useSelectionDiamonds)

  useEffect(() => {
    if (!drawingArea) return

    const sqrtScale = Math.sqrt(scatterScale)
    const { svg, g } = drawingArea
    svg.selectAll('pattern').remove()

    g.selectAll('circle').remove()
    g.selectAll('text').remove()
    g.selectAll('path.selection').remove()

    if (useSelectionDiamonds) {
      const diamondOffset = theme.shape.contour.diamondSize / sqrtScale
      const selectedDots = g.selectAll('dot').data(selectedData).enter()
      selectedDots
        .append('path')
        .attr('class', 'selection')
        .attr('d', (item) => createDiamondPath(item, diamondOffset))
        .style('stroke-width', 1.0 / scatterScale)
        .style('fill', (item) => item.color)
        .style('stroke', 'black')
    }

    const scatterDots = g.selectAll('dot').data(data).enter()
    const scale = 0.5 / sqrtScale

    scatterDots
      .append('circle')
      .attr('cx', (item) => item.x)
      .attr('cy', (item) => item.y)
      .attr('r', (item) => item.radius * scale)
      .style('fill', (item) => item.color)
      .style('stroke-width', 1.0 / scatterScale)
      .style('stroke', theme.palette.contour.scatterLineColor)

    const textOffset = theme.shape.contour.textOffset * sqrtScale
    const textDots = g.selectAll('dot').data(labelData).enter()
    if (!useSelectionDiamonds) {
      markedData.forEach((item) => {
        const pattern = svg.append('defs').append('pattern')
        pattern
          .attr('id', `${item.color}-pattern`)
          .attr('width', 2.0 / sqrtScale)
          .attr('height', 2.0 / sqrtScale)
          .attr('patternUnits', 'userSpaceOnUse')
          .attr('patternTransform', 'rotate(60)')
        pattern
          .append('rect')
          .attr('height', 2.0 / sqrtScale)
          .attr('width', 1.0 / sqrtScale)
          .attr('fill', item.color)
      })
      const markedDots = g.selectAll('dot').data(markedData).enter()
      markedDots
        .append('circle')
        .attr('cx', (item) => item.x)
        .attr('cy', (item) => item.y)
        .attr('r', (item) => item.radius * scale)
        .style('fill', (item) => `url(#${item.color}-pattern)`)
    }
    textDots
      .append('text')
      .attr('font-size', theme.typography.contour.fontSize / scatterScale)
      .attr('font-weight', theme.typography.contour.fontWeight)
      .attr('stroke-linecap', 'round')
      .attr('stroke-linejoin', 'round')
      .attr('text-anchor', 'middle')
      .text((d) => d.label)
      .style('fill', (item) => item.color)
      .attr('dy', `-${textOffset}em`)
      .attr('x', (d) => d.x)
      .attr('y', (d) => d.y)
  }, [drawingArea, data, scatterScale, labelData, selectedData, markedData, useSelectionDiamonds])
}
