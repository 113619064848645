export const Role = {
  Admin: 'Admin',
  User: 'User',
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export declare type Role = typeof Role[keyof typeof Role]

export interface IAccount {
  userName: string
  lastName?: string
  firstNames?: string
  roles: Array<Role>
  token: string
  can_add: boolean
}
