import { MenuItem, Checkbox, FormControlLabel, Divider, styled, Typography, AppBar } from '@material-ui/core'
import React, { memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { IAnalysisOverview, IRevision } from 'analyses/analysis.model'
import { useTranslate } from 'translation'
import IconBookmark from '@material-ui/icons/Bookmark'
import theme from 'theme'
import DropdownMenu from 'components/DropdownMenu'
import { useCanChangeModel, useCurrentVersion, useOnlyBookmarks, useRevisions } from 'analyses/hooks'
import { revertToRevision, setOnlyBookmarks } from '../reducers'

const useRevisionDescription = (revision: IRevision) => {
  const t = useTranslate()
  if (revision.description) return revision.description
  return t(revision.operation)
}

const formatSelection = (revision: IRevision) => {
  const datetime = new Date(revision.changed_at)
  if (Number.isNaN(datetime.getTime())) return ''
  const time = datetime.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' }).replace('.', ':')
  const date = datetime.toLocaleDateString()
  return `${time} ${date} - ${revision.changed_by}`
}

interface Props {
  width: number
  analysis?: IAnalysisOverview
}

/**
 * A select dropdown of revisions.
 * Allows toggling whether to only show bookmarked.
 * Bookmarked revisions include the description.
 * When not bookmarked, the operation is shown instead.
 */
const RevisionDropdown = ({ width, analysis }: Props) => {
  const dispatch = useDispatch()
  let canChange = useCanChangeModel()
  let currentVersion = useCurrentVersion()
  let revisions = useRevisions()
  if (analysis) {
    currentVersion = analysis.current_version
    revisions = analysis.revisions
    canChange = analysis.permissions?.change ?? false
  }
  const currentRevision = revisions.find((item) => item.version === currentVersion)

  const handleSetRevision = useCallback(
    (value: number) => {
      dispatch(revertToRevision(value))
    },
    [dispatch]
  )

  const renderRevision = useCallback(
    () => <Typography color='textSecondary'>{currentRevision ? formatSelection(currentRevision) : ''}</Typography>,
    [currentRevision]
  )

  if (!canChange) return null

  return (
    <DropdownMenu
      value={String(currentRevision?.version ?? '')}
      onChange={handleSetRevision}
      renderValue={renderRevision}
      startTools={<Toolbar />}
      width={width}
    >
      {revisions.map((item) => (
        <MenuItem key={item.version} value={String(item.version)}>
          <MemoizedItem item={item} />
        </MenuItem>
      ))}
    </DropdownMenu>
  )
}

const Item = ({ item }: { item: IRevision }) => {
  const description = useRevisionDescription(item)
  return (
    <>
      {item.description ? <IconBookmark /> : <IconFiller />}
      <div>
        {formatSelection(item)}
        <Typography variant='body2'>{description}</Typography>
      </div>
    </>
  )
}

const MemoizedItem = memo(Item)

const Toolbar = () => {
  const dispatch = useDispatch()
  const onlyBookmarks = useOnlyBookmarks()
  const t = useTranslate()
  const handleSetOnlyBookmarks = useCallback(() => {
    dispatch(setOnlyBookmarks(!onlyBookmarks))
  }, [dispatch, onlyBookmarks])

  return (
    <AppBar position='sticky' elevation={0}>
      <Header>
        <Typography color='textSecondary'>
          <IconHeader />
          <HeaderTitle>{t('Versions')}</HeaderTitle>
        </Typography>
        <FormControlLabel
          control={<Checkbox checked={onlyBookmarks} onChange={handleSetOnlyBookmarks} name={t('Only bookmarked')} />}
          label={<Typography color='textSecondary'>{t('Only bookmarked')}</Typography>}
        />
      </Header>
      <Divider />
    </AppBar>
  )
}

const Header = styled('div')({
  display: 'flex',
  paddingLeft: theme.shape.listItemPadding,
  justifyContent: 'space-between',
})

const IconFiller = styled(IconBookmark)({
  visibility: 'hidden',
})

// Some extra styling needed to make the title vertically centered.
const HeaderTitle = styled('span')({
  verticalAlign: 'middle',
})
const IconHeader = styled(IconFiller)({
  height: '100%',
  verticalAlign: 'middle',
})

export default RevisionDropdown
