/**
 * Contains simple reusable styled components.
 */
import { Button, CircularProgress, Dialog, styled, Table, TableCell, Typography, withStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'
import React from 'react'
import theme from 'theme'

const hideOverflow = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
} as CreateCSSProperties

const header = {
  fontweight: theme.typography.fontWeightBold,
  backgroundColor: theme.palette.background.table,
  color: theme.palette.text.primary,
  padding: theme.shape.headPadding,
  lineHeight: 'inherit',
} as CreateCSSProperties

export const DialogWithFulLheight = withStyles({
  paper: {
    height: `calc(100% - ${theme.shape.modalSpacing}px)`,
  },
})(Dialog)

export const CenteredButton = styled(Button)({
  margin: 'auto',
  padding: 0,
})

export const CenteredTypography = styled(Typography)({
  flexGrow: 1,
  margin: 'auto',
})

export const VerticallyCenteredDiv = styled('div')({
  marginTop: 'auto',
  marginBottom: 'auto',
})

export const CenteredDiv = styled('div')({
  margin: 'auto',
  textAlign: 'center',
  padding: 0,
})

export const FlexGrow = styled(CenteredTypography)({
  flexGrow: 1,
})

export const TypographyWithEllipsis = styled(Typography)({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflowX: 'hidden',
})

export const HideOverflowCell = styled(TableCell)(hideOverflow)

export const HideOverflow = styled('div')(hideOverflow)

export const VerticalHideOverflow = styled(HideOverflow)({
  transform: 'rotate(180deg)',
  writingMode: 'vertical-lr',
  height: '100%',
})

export const ContainerFullHeight = styled('div')({
  height: '100%',
})

export const Container = styled('div')({
  width: '100%',
  height: '100%',
})
export const ContainerFullWidth = styled('div')({
  width: '100%',
})

export const Flex = styled('div')({
  display: 'flex',
})

export const Progress = () => (
  <ProgressContainer>
    <CircularProgress color='secondary' />
  </ProgressContainer>
)

export const ProgressContainer = styled('div')({
  display: 'flex',
  flexGrow: 1,
  margin: 'auto',
  alignItems: 'center',
})

export const VerticalFlex = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  padding: 0,
})

export const AlignEnd = styled('div')({
  textAlign: 'end',
})

export const FixedTable = styled(Table)({
  tableLayout: 'fixed',
})

/**
 * Adds a line over an icon to indicate disable/off value.
 */
export const Disabler = styled('div')({
  background: `
        linear-gradient(to top right,
        rgba(0,0,0,0) 0%,
        rgba(0,0,0,0) calc(50% - 2px),
        ${theme.palette.text.secondary} 50%,
        rgba(0,0,0,0) calc(50% + 2px),
        rgba(0,0,0,0) 100%)
      `,
  width: '1em',
  height: '1em',
})

export const HeaderCell = styled(TableCell)(header)

export const HideOverflowHeaderCell = styled(TableCell)({ ...header, ...hideOverflow })
