import React, { useRef } from 'react'
import { Switch, Route } from 'react-router'
import AppRoutes from 'appRoutes'
import Login from 'views/login/Login'
import Layout from 'shared/layout/Layout'
import PrivateRoute from 'shared/auth/PrivateRoute'
import AnalysisLayout from 'analyses/MainLayout'
import OpenDialog from 'analyses/OpenDialog'
import { useStoreDimension } from 'shared/util/hooks'
import { styled } from '@material-ui/core'
import LoadingOverlay from 'analyses/LoadingOverlay'
import theme from 'theme'
import { setDimension } from 'analyses/reducers'

const Container = styled('div')({
  height: '100%',
  backgroundColor: theme.palette.background.default,
})

const Component = () => {
  const ref = useRef<HTMLDivElement>(null)
  useStoreDimension(ref, setDimension)
  return (
    <Layout>
      <LoadingOverlay />
      <OpenDialog />
      <Container ref={ref}>
        <AnalysisLayout />
      </Container>
    </Layout>
  )
}

const App: React.FC = () => (
  <Switch>
    <Route path={AppRoutes.Login} component={Login} />
    <PrivateRoute path='/analysis/:id' component={Component} />
    <PrivateRoute path='' component={Component} />
  </Switch>
)

export default App
