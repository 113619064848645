import { IconButton, Button, styled } from '@material-ui/core'
import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { SketchPicker } from 'react-color'
import ClearIcon from '@material-ui/icons/Clear'
import SimplePopover from 'components/SimplePopver'
import SimpleInput from 'components/SimpleInput'
import { useTranslate } from 'translation'
import ScalingList, { FullLabel } from 'components/ScalingList'
import theme from 'theme'
import { useRefreshingState } from 'shared/util/hooks'
import { markScenario, setEditScenarioLabel } from '../../reducers'
import { useMarkedScenario } from '../../hooks'

interface Props {
  id: number | undefined
  index: number | undefined
}

const ScenarioProperties = ({ id, index }: Props) => {
  const dispatch = useDispatch()
  const marked = useMarkedScenario(index ?? 0)
  const initialLabel = marked?.label ?? ''
  const initialColor = marked?.color ?? ''
  const ref = useRef<HTMLInputElement>(null)

  const editLabel = useCallback(() => {
    ref.current?.focus()
  }, [])

  useEffect(() => {
    dispatch(setEditScenarioLabel(editLabel))
  }, [dispatch, editLabel])

  const update = useCallback(
    (label, color) => {
      if (id !== undefined && index !== undefined) {
        if (label !== initialLabel || color !== initialColor) {
          dispatch(markScenario(id, index, color, label))
        }
      }
    },
    [dispatch, id, index, initialLabel, initialColor]
  )

  const handleLabel = useCallback(
    (label: string) => {
      if (initialLabel !== label) update(label, initialColor)
    },
    [update, initialLabel, initialColor]
  )

  const handleColor = useCallback(
    (color: string) => {
      if (initialColor !== color) update(initialLabel, color)
    },
    [update, initialLabel, initialColor]
  )

  const t = useTranslate()
  return (
    <ScalingList>
      <FullLabel
        control={
          <SimpleInput
            ref={ref}
            value={initialLabel}
            onChange={handleLabel}
            underline
            aria-labelledby='scenario-properties-label'
          />
        }
        labelPlacement='start'
        label={`${t('Name')}:`}
        labelId='scenario-properties-label'
      />
      <ColorPicker initialColor={initialColor} onChange={handleColor} />
    </ScalingList>
  )
}

interface ColorPickerProps {
  initialColor: string
  onChange: (color: string) => void
}

export const ColorPicker = ({ initialColor, onChange }: ColorPickerProps) => {
  const t = useTranslate()
  const [color, setColor] = useRefreshingState(initialColor)

  const handleColor = useCallback(
    (value) => {
      setColor(value.hex)
    },
    [setColor]
  )

  const handleClear = useCallback(() => {
    setColor('')
  }, [setColor])

  const handleClose = useCallback(() => {
    onChange(color)
  }, [onChange, color])

  return (
    <Popover trigger={<ColorTrigger color={color} />} onClose={handleClose}>
      <SketchPicker color={color} onChangeComplete={handleColor} disableAlpha />
      <ClearIconButton disableRipple aria-label={t('Clear color')} onClick={handleClear} color='inherit'>
        <FixedSizeButton />
      </ClearIconButton>
    </Popover>
  )
}

const Popover = styled(SimplePopover)({
  marginLeft: theme.shape.scenarioProperties.colorPopoverOffset,
})

interface ColorTriggerProps {
  color: string
  onClick?: () => void
}

const ColorTrigger = ({ color, onClick }: ColorTriggerProps) => {
  const t = useTranslate()
  return (
    <FullLabel
      control={<ColorButton size='small' disableRipple style={{ backgroundColor: color }} onClick={onClick} />}
      labelPlacement='start'
      label={`${t('Color')}:`}
      labelId='scenario-properties-color'
    />
  )
}

const ColorButton = styled(Button)({
  minWidth: theme.shape.scenarioProperties.colorButtonDimension,
  width: theme.shape.scenarioProperties.colorButtonDimension,
  height: theme.shape.scenarioProperties.colorButtonDimension,
})
const ClearIconButton = styled(IconButton)({
  position: 'absolute',
  bottom: 10,
  right: 12,
  padding: 0,
  width: 16,
  height: 16,
})

const FixedSizeButton = styled(ClearIcon)({
  width: '100%',
  height: '100%',
})

export default ScenarioProperties
