import { PayloadAction } from '@reduxjs/toolkit'
import { getContourWidth, getHeight } from 'analyses/selectors/ui'
import type { D, ExportPng, ExportSvg } from './ui'

export const setResetUncertaintyTableLayout = (draft: D, { payload }: PayloadAction<() => void>) => {
  draft.callbacks.resetUncertaintyTableLayout = payload
}
export const resetUncertaintyTableLayout = (draft: D) => {
  if (draft.callbacks.resetUncertaintyTableLayout) draft.callbacks.resetUncertaintyTableLayout()
}
export const setResetResultMapLayout = (draft: D, { payload }: PayloadAction<() => void>) => {
  draft.callbacks.resetResultMapLayout = payload
}
export const resetResultMapLayout = (draft: D) => {
  if (draft.callbacks.resetResultMapLayout) draft.callbacks.resetResultMapLayout()
}
export const setResetMainLayout = (draft: D, { payload }: PayloadAction<() => void>) => {
  draft.callbacks.resetMainLayout = payload
}
export const resetMainLayout = (draft: D) => {
  if (draft.callbacks.resetMainLayout) draft.callbacks.resetMainLayout()
}

export const setSvgFunctions = (
  draft: D,
  { payload }: PayloadAction<{ exportPng: ExportPng; exportSvg: ExportSvg; resetZoom: () => void }>
) => {
  draft.callbacks.exportPng = payload.exportPng
  draft.callbacks.exportSvg = payload.exportSvg
  draft.callbacks.resetContourZoom = payload.resetZoom
}

export const setEditScenarioLabel = (draft: D, { payload }: PayloadAction<() => void>) => {
  draft.callbacks.editScenarioLabel = payload
  if (draft.autoFocusToolbar) {
    draft.autoFocusToolbar = false
    payload()
  }
}

export const editScenarioLabel = (draft: D) => {
  if (draft.callbacks.editScenarioLabel) draft.callbacks.editScenarioLabel()
  if (!draft.showToolbar) {
    draft.autoFocusToolbar = true
    draft.showToolbar = true
  }
}

export const exportPng = (draft: D, { payload }: PayloadAction<string>) => {
  const width = getContourWidth(draft)
  const height = getHeight(draft)
  if (draft.callbacks.exportPng) draft.callbacks.exportPng(payload, width, height)
}

export const exportSvg = (draft: D, { payload }: PayloadAction<string>) => {
  if (draft.callbacks.exportSvg) draft.callbacks.exportSvg(payload)
}

export const resetContourZoom = (draft: D) => {
  if (draft.callbacks.resetContourZoom) draft.callbacks.resetContourZoom()
}
