import React from 'react'
import { AppBar, Toolbar, styled } from '@material-ui/core/'
import RevisionDropdown from 'analyses/revision/RevisionDropdown'
import AnalysisBookmarker from 'analyses/revision/Bookmarker'
import theme from 'theme'
import AccountMenu from './AccountMenu'
import AnalysisMenu from './AnalysisMenu'

const Header = () => (
  <TopAppBar position='static'>
    <TopToolbar>
      <TitleContainer>
        <LogoContainer>
          <img src={`${process.env.PUBLIC_URL}/${theme.logo}`} alt='Logo' />
        </LogoContainer>
        <AnalysisMenu />
      </TitleContainer>
      <RevisionsContainer>
        <RevisionDropdown width={theme.shape.revisionWidth} />
        <AnalysisBookmarker />
      </RevisionsContainer>
      <AccountContainer>
        <AccountMenu />
      </AccountContainer>
    </TopToolbar>
  </TopAppBar>
)

const TopAppBar = styled(AppBar)({
  height: theme.shape.topBar.height + 2,
})

const TopToolbar = styled(Toolbar)({
  minHeight: theme.shape.topBar.height,
})

const TitleContainer = styled('div')({
  flexBasis: theme.shape.topBar.flexBasis,
  display: 'flex',
  height: '90%',
  alignItems: 'start',
  whiteSpace: 'nowrap',
})

const LogoContainer = styled('div')({
  height: '100%',
  margin: '0 3em 0 0',
  '&>img': {
    height: '100%',
  },
})

const RevisionsContainer = styled('div')({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
})

const AccountContainer = styled('div')({
  flexBasis: theme.shape.topBar.flexBasis,
  textAlign: 'end',
})

export default Header
