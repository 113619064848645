import { Divider, makeStyles } from '@material-ui/core'
import React, { useCallback } from 'react'
import theme from 'theme'
import { useDragging } from './useDragging'

const useStyles = makeStyles({
  divider: {
    cursor: 'ns-resize',
    height: theme.shape.divider,
    zIndex: theme.zIndex.divider,
  },
})

interface Props {
  onMove: (y: number) => void
}

/**
 * Adds a divider that allows resizing the height.
 * This causes lots of renders so the parent component must have a good performance.
 */
const HorizontalDivider = ({ onMove }: Props) => {
  const classes = useStyles()
  const handleMove = useCallback((_: number, y: number) => onMove(y), [onMove])
  const handleMouseDown = useDragging(handleMove)
  return <Divider onMouseDown={handleMouseDown} className={classes.divider} orientation='horizontal' />
}

export default HorizontalDivider
