import { MenuItem, Divider, styled, Typography, AppBar, List, ListItem } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslate } from 'translation'
import DropdownMenu from 'components/DropdownMenu'
import SimpleInput from 'components/SimpleInput'
import { removeResultMap, setResultMapId, changeResultMapName, addResultMap } from '../reducers'
import {
  useResultMaps,
  useResultMap,
  usePrimaryResultMap,
  useResultMapCount,
  useCanChangeResult,
  useResultMapName,
} from '../hooks'

const Selector = ({ color }: { color: 'textPrimary' | 'textSecondary' }) => {
  const dispatch = useDispatch()
  const resultMap = useResultMap()
  const resultMaps = useResultMaps()
  const canChange = useCanChangeResult()

  const handleSetRevision = useCallback(
    (value: number) => {
      dispatch(setResultMapId(value))
    },
    [dispatch]
  )

  const [isEditable, setEditable] = useState(false)

  const handleNameChange = useCallback(
    (value: string) => {
      if (resultMap) dispatch(changeResultMapName(resultMap.id, value))
      setEditable(false)
    },
    [dispatch, resultMap]
  )

  const renderValue = useCallback(() => <Typography color={color}>{resultMap ? resultMap.name : ''}</Typography>, [
    resultMap,
    color,
  ])

  const name = useResultMapName()

  if (!canChange) return null

  return (
    <div>
      {isEditable ? (
        <SimpleInput value={name} onChange={handleNameChange} />
      ) : (
        <DropdownMenu
          value={resultMap?.id ?? ''}
          onChange={handleSetRevision}
          renderValue={renderValue}
          startTools={canChange && resultMap ? <TopToolbar id={resultMap.id} setEditable={setEditable} /> : undefined}
          endTools={canChange ? <BottomToolbar /> : undefined}
        >
          {resultMaps?.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </DropdownMenu>
      )}
    </div>
  )
}

enum TopOperation {
  Rename = 'Rename',
  Delete = 'Delete',
}

const topOperations = Object.values(TopOperation)

const TopToolbar = ({ id, setEditable }: { id: number; setEditable: (value: boolean) => void }) => {
  const dispatch = useDispatch()
  const handleClick = useCallback(
    (operation: TopOperation) => {
      if (operation === TopOperation.Rename) {
        setEditable(true)
      }
      if (operation === TopOperation.Delete) {
        dispatch(removeResultMap(id))
      }
    },
    [dispatch, setEditable, id]
  )

  return (
    <AppBar position='sticky' elevation={0}>
      <FullWidthList>
        {topOperations.map((item) => (
          <Item key={item} item={item} onClick={handleClick} />
        ))}
      </FullWidthList>
      <Divider />
    </AppBar>
  )
}

const FullWidthList = styled(List)({
  width: '100%',
})

const Item = <T extends TopOperation | BottomOperation>({ item, onClick }: { item: T; onClick: (item: T) => void }) => {
  const t = useTranslate()
  const handleClick = useCallback(() => {
    onClick(item)
  }, [onClick, item])
  return (
    <ListItem key={item} button onClick={handleClick}>
      {t(item)}
    </ListItem>
  )
}

enum BottomOperation {
  OpenDefault = 'Open default',
  AddResultMap = 'Add result map',
}

const bottomOperations = Object.values(BottomOperation)

const BottomToolbar = () => {
  const count = useResultMapCount()
  const t = useTranslate()
  const dispatch = useDispatch()
  const primaryResultMap = usePrimaryResultMap()
  const handleClick = useCallback(
    (operation: BottomOperation) => {
      if (operation === BottomOperation.OpenDefault) {
        if (primaryResultMap) dispatch(setResultMapId(primaryResultMap.id))
      }
      if (operation === BottomOperation.AddResultMap) {
        dispatch(addResultMap(`${t('Resultmap')} ${count + 1}`))
      }
    },
    [dispatch, t, count, primaryResultMap]
  )

  return (
    <AppBar position='sticky' elevation={0}>
      <Divider />
      <FullWidthList>
        {bottomOperations.map((item) => (
          <Item key={item} item={item} onClick={handleClick} />
        ))}
      </FullWidthList>
    </AppBar>
  )
}

export default Selector
