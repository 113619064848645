import { select, zoom, zoomIdentity } from 'd3'
import { noop } from 'lodash-es'
import { RefObject, useState, useEffect, useCallback, useMemo } from 'react'
import { getSVGString, svgString2Png, svgString2Svg } from './svgSaver'

export type DrawingArea = {
  g: d3.Selection<SVGGElement, unknown, null, unknown>
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>
} | null
export type ClickCoordinates = { x: number; y: number; isDouble: boolean } | null

/**
 * Initializes the drawing area with a given dimension and background color.
 */
const useDrawingArea = (contourRef: RefObject<HTMLDivElement>, backgroundColor: string) => {
  const [drawingArea, setDrawingArea] = useState<DrawingArea>(null)

  // Important to use useEffect here to ensure that ref is initialized.
  useEffect(() => {
    if (!contourRef.current) return

    const container = select(contourRef.current)
    container.selectAll('*').remove()
    const svg = container.append('svg')
    svg.attr('width', '100%').attr('height', '100%').attr('tabindex', 0)
    svg.append('rect').attr('width', '100%').attr('height', '100%').attr('fill', backgroundColor)
    const g = svg.append('g')
    setDrawingArea({ svg, g })
  }, [contourRef, setDrawingArea, backgroundColor])
  return drawingArea
}

/**
 * Adds both panning and zoom functionality to a given drawing area with initial scaling.
 * Automatically supports zooming and panning with a mouse.
 * Returns current zoom transformation and a function to reset back to initial zoom.
 */
const usePanAndZoom = (drawingArea: DrawingArea, initialScale: number, width: number, height: number) => {
  const [zoomTransform, setZoomTransform] = useState<d3.ZoomTransform>(zoomIdentity)
  const resetZoom = useMemo(() => {
    if (!drawingArea) return noop
    const { svg, g } = drawingArea
    const zoomer = zoom().on('zoom', (event) => {
      const { transform } = event as { transform: d3.ZoomTransform }
      setZoomTransform(transform)
      g.attr('transform', transform.toString())
    })
    svg.call(zoomer as never)
    const dimension = Math.min(width, height)
    const deltaX = (width - dimension) / 2
    const deltaY = (height - dimension) / 2
    const reset = () => svg.call(zoomer.transform as never, zoomIdentity.translate(deltaX, deltaY).scale(initialScale))
    reset()
    return reset
  }, [drawingArea, initialScale, width, height])
  return { zoomTransform, resetZoom }
}

/**
 * Returns a function which exports the current view of a given drawing area.
 */
const useExport = (drawingArea: DrawingArea) => {
  const exportPng = useCallback(
    (name: string, width: number, height: number) => {
      if (!drawingArea) return
      const node = drawingArea.svg.node()
      if (!node) return
      // Firefox requires absolute dimensions so set them temporarily.
      const previousWidth = node.getAttribute('width')
      const previousHeight = node.getAttribute('height')
      node.setAttribute('width', String(width))
      node.setAttribute('height', String(height))
      const svgString = getSVGString(node)
      const date = new Date()
      const timestamp = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      svgString2Png(`${name}_${timestamp}`, svgString, width, height)
      if (previousWidth) node.setAttribute('width', previousWidth)
      if (previousHeight) node.setAttribute('height', previousHeight)
    },
    [drawingArea]
  )
  const exportSvg = useCallback(
    (name: string) => {
      if (!drawingArea) return
      const node = drawingArea.svg.node()
      if (!node) return
      const svgString = getSVGString(node)
      const date = new Date()
      const timestamp = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      svgString2Svg(`${name}_${timestamp}`, svgString)
    },
    [drawingArea]
  )
  return { exportPng, exportSvg }
}

/**
 * Adds a click listener to a given drawing area an a given zoom transformer.
 * Returns clicked coordinates.
 */
const useClick = (drawingArea: DrawingArea, transform: d3.ZoomTransform) => {
  const [coordinates, setCoordinates] = useState<ClickCoordinates>(null)
  useEffect(() => {
    if (!drawingArea) return
    drawingArea.svg.on('click', (event) => {
      const { offsetX, offsetY } = (event as unknown) as MouseEvent
      const [x, y] = transform.invert([offsetX, offsetY])
      setCoordinates({ x, y, isDouble: false })
    })
    // Zoom must be disabled to enable double click.
    // It might be possible to get both working together but that would probably be confusing to users.
    drawingArea.svg.on('dblclick.zoom', null).on('dblclick', (event) => {
      const { offsetX, offsetY } = (event as unknown) as MouseEvent
      const [x, y] = transform.invert([offsetX, offsetY])
      setCoordinates({ x, y, isDouble: true })
    })
  }, [drawingArea, transform])
  return coordinates
}

/**
 * Adds a enter key listener to call a given callback.
 */
const useEnter = (drawingArea: DrawingArea, callback: () => void) => {
  useEffect(() => {
    if (!drawingArea) return
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    drawingArea.svg.on('keyup', (event: any) => {
      const keyboardEvent = event as KeyboardEvent
      if (keyboardEvent.key === 'Enter') {
        callback()
      }
    })
  }, [drawingArea, callback])
}

export const useD3 = (
  contourRef: RefObject<HTMLDivElement>,
  initialScale: number,
  backgroundColor: string,
  width: number,
  height: number,
  onEnter: () => void
) => {
  const drawingArea = useDrawingArea(contourRef, backgroundColor)
  const { zoomTransform, resetZoom } = usePanAndZoom(drawingArea, initialScale, width, height)
  const { exportPng, exportSvg } = useExport(drawingArea)
  const clickCoordinates = useClick(drawingArea, zoomTransform)
  useEnter(drawingArea, onEnter)

  return { drawingArea, zoomLevel: zoomTransform.k, clickCoordinates, exportSvg, exportPng, resetZoom }
}
