import type { IMarkedScenario } from 'analyses/analysis.model'
import { shallowEqual, useSelector } from 'react-redux'
import type { IRootState } from 'config/state'
import * as selectors from '../selectors'

export const useAnalysisId = () => useSelector(selectors.getId)
export const useCanChangeModel = () => useSelector(selectors.getCanChangeModel)
export const useCanChangeResult = () => useSelector(selectors.getCanChangeResult)
export const useName = () => useSelector(selectors.getName)
export const useResultData = () => useSelector(selectors.resultDataSelector)
export const useContourLevels = () => useSelector(selectors.getContourLevels)
export const useResultMaps = () => useSelector(selectors.getResultsMap)
export const useResultMapCount = () => useSelector(selectors.getResultMapCount)
export const useResultMap = () => useSelector(selectors.getResultMap)
export const useResultMapName = () => useSelector(selectors.getResultMapName)
export const usePrimaryResultMap = () => useSelector(selectors.getPrimaryResultMap)
export const useShowSelectionDiamonds = () => useSelector(selectors.getShowSelectionDiamonds)
export const useMarkedScenarios = () => useSelector(selectors.markedScenariosSelector)
export const useColorScale = () => useSelector(selectors.colorScaleSelector)
export const useScenarios = () => useSelector(selectors.scenariosSelector)
export const useScenariosObject = () => useSelector(selectors.scenariosObjectSelector)
export const useUncertainties = () => useSelector(selectors.getUncertainties)
export const useUncertaintyCount = () => useSelector(selectors.getUncertaintyCount)
export const useUncertainty = (index: number) =>
  useSelector((state: IRootState) => selectors.getUncertainties(state)[index])
export const useConsistencyTable = () => useSelector(selectors.getConsistencyTable)
export const useDescription = () => useSelector(selectors.getDescription)
export const useOnlyBookmarks = () => useSelector(selectors.getOnlyBookmarks)
export const useCurrentVersion = () => useSelector(selectors.getCurrentVersion)
export const useCurrentRevision = () => useSelector(selectors.getCurrentRevision)
export const useRevisions = () => useSelector(selectors.getRevisions)
export const useSelectedScenarios = () => useSelector(selectors.selectedScenariosSelector, shallowEqual)
export const useIsSelectedScenario = (index: number) =>
  useSelector((state: IRootState) => selectors.selectedScenariosSelector(state).includes(index))
export const useKeyScenarios = () => useSelector(selectors.keyScenariosSelector, shallowEqual)
export const useSelectedOutcomes = () => useSelector(selectors.getSelectedOutcomes, shallowEqual)
export const useIncludedOutcomes = () => useSelector(selectors.includedOutcomesSelector, shallowEqual)
export const useOutcomeColors = () => useSelector(selectors.outcomeColorSelector)
export const useScenarioCount = () => useSelector(selectors.scenarioCountSelector)
export const useTotalScenarioCount = () => useSelector(selectors.getTotalScenarioCount)
export const useScenario = (index: number) => useSelector((state: IRootState) => selectors.getScenarios(state)[index])
export const useMarkedScenario = (index: number): IMarkedScenario | undefined =>
  useSelector((state: IRootState) => selectors.markedScenariosSelector(state)[index])
