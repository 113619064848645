// From: http://bl.ocks.org/Rokotyan/0556f8facbaf344507cdc45dc3622177

import { saveAs } from 'file-saver'

const getCSSStyles = (node: SVGSVGElement) => {
  const selectorTextArr = []

  // Add Parent element Id and Classes to the list
  selectorTextArr.push(`#${node.id}`)
  for (let c = 0; c < node.classList.length; c++)
    if (!selectorTextArr.includes(`.${node.classList[c]}`)) selectorTextArr.push(`.${node.classList[c]}`)

  // Add Children element Ids and Classes to the list
  const nodes = node.getElementsByTagName('*')
  for (let i = 0; i < nodes.length; i++) {
    const { id } = nodes[i]
    if (!selectorTextArr.includes(`#${id}`)) selectorTextArr.push(`#${id}`)

    const classes = nodes[i].classList
    for (let c = 0; c < classes.length; c++)
      if (!selectorTextArr.includes(`.${classes[c]}`)) selectorTextArr.push(`.${classes[c]}`)
  }

  // Extract CSS Rules
  let extractedCSSText = ''
  for (let i = 0; i < document.styleSheets.length; i++) {
    const s = document.styleSheets[i]

    try {
      if (s.cssRules) {
        const { cssRules } = s
        for (let r = 0; r < cssRules.length; r++) {
          if (selectorTextArr.includes(((cssRules[r] as unknown) as { selectorText: string }).selectorText))
            extractedCSSText += cssRules[r].cssText
        }
      }
    } catch (e) {
      if (e instanceof Error && e.name !== 'SecurityError') throw e // for Firefox
    }
  }

  return extractedCSSText
}

const appendCSS = (cssText: string, node: SVGSVGElement) => {
  const styleElement = document.createElement('style')
  styleElement.setAttribute('type', 'text/css')
  styleElement.innerHTML = cssText
  const refNode = node.hasChildNodes() ? node.children[0] : null
  node.insertBefore(styleElement, refNode)
}

export const getSVGString = (svgNode: SVGSVGElement) => {
  svgNode.setAttribute('xlink', 'http://www.w3.org/1999/xlink')
  const cssStyleText = getCSSStyles(svgNode)
  appendCSS(cssStyleText, svgNode)

  const serializer = new XMLSerializer()
  let svgString = serializer.serializeToString(svgNode)
  svgString = svgString.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink=') // Fix root xlink without namespace
  svgString = svgString.replace(/NS\d+:href/g, 'xlink:href') // Safari NS namespace fix

  return svgString
}

export const svgString2Png = (filename: string, svgString: string, width: number, height: number) => {
  const imgsrc = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgString)))}` // Convert SVG string to data URL

  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  if (!context) return

  canvas.width = width
  canvas.height = height
  const image = new Image()
  image.onload = () => {
    context.clearRect(0, 0, width, height)
    context.drawImage(image, 0, 0, width, height)

    canvas.toBlob((blob) => {
      if (blob) saveAs(blob, `${filename}.png`)
    })
  }

  image.src = imgsrc
}

export const svgString2Svg = (filename: string, svgString: string) => {
  const imgsrc = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgString)))}` // Convert SVG string to data URL
  saveAs(imgsrc, `${filename}.svg`)
}
