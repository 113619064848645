import { createLogger } from 'redux-logger'
import createSagaMiddleware, { SagaMiddleware } from '@redux-saga/core'
import { loginSagas } from 'views/login/login.sagas'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { IRootState } from './state'
import analysis from 'analyses/reducers/analysis'
import ui from 'analyses/reducers/ui'
import { LoginReducer } from 'views/login/login.reducer'

const reducer = combineReducers<IRootState>({
  login: LoginReducer,
  analysis,
  ui,
})

const initSagas = (sagaMiddleware: SagaMiddleware) => {
  sagaMiddleware.run(loginSagas)
  // TODO - add more sagas here
}

const initialize = (initialState?: IRootState) => {
  const sagaMiddleware = createSagaMiddleware()

  const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => {
      const middlewares = getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      })
      middlewares.push(sagaMiddleware)
      if (process.env.NODE_ENV === `development`) {
        middlewares.push(createLogger())
      }
      return middlewares
    },
    preloadedState: initialState,
    devTools: process.env.NODE_ENV === 'development',
  })
  initSagas(sagaMiddleware)
  return store
}

export default initialize()
