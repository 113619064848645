import { Input, makeStyles } from '@material-ui/core'
import React, {
  ChangeEvent,
  memo,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import theme from 'theme'
import ContextMenu from './ContextMenu'
import { CellFocuser } from './useCellFocuser'

enum Operation {
  Edit = 'Edit',
  Delete = 'Remove',
}

interface Props<T extends string> {
  value: string
  display: string
  focuser: CellFocuser
  onEditing?: (value: boolean) => void
  onChange?: (value: string) => void
  onDelete?: () => void
  setMenuItems?: (defaultItems: Operation[]) => (T | Operation)[]
  onMenuSelect?: (item: T) => void
  startTools?: ReactNode
  fixedHeight?: boolean
}

const useStyles = makeStyles(() => ({
  container: {
    '&:focus': {
      // Mimic selected style so that clicking doesn't show any extra focus style.
      // While still having some kind of support for keyboard navigation.
      outline: 'none',
      backgroundColor: theme.palette.uncertaintyTable.selected_bg,
    },
  },
  overflow: {
    wordBreak: 'break-word',
  },
  preventOverflow: {
    display: '-webkit-box',
    '-webkit-line-clamp': theme.shape.fixedLineCount,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-word',
    // Fixed size. 3 rows * line height.
    height: `${theme.shape.fixedLineCount * 1.43}em`,
  },
  input: {
    fontSize: theme.typography.fontSize,
    width: '100%',
  },
  inputOverflow: {
    // Fixed size. 3 rows * line height.
    height: `${theme.shape.fixedLineCount * 1.43}em`,
  },
}))

/**
 * Input which becomes editable when double clicked, with enter key press or from a context menu.
 * The context menu allows using own menu items with setMenuItems callback.
 */
const FocusableInput = <T extends string>({
  value,
  display,
  focuser,
  onEditing,
  onChange,
  onDelete,
  setMenuItems,
  onMenuSelect,
  startTools,
  fixedHeight,
}: Props<T>) => {
  const [currentValue, setCurrentValue] = useState(value)
  const [isEditable, setEditable] = useState(false)
  const ref = useRef<HTMLInputElement>(null)

  const handleSetEditable = useCallback(
    (editable: boolean) => {
      setEditable(editable)
      if (onEditing) onEditing(editable)
    },
    [onEditing]
  )

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  useEffect(() => {
    if (isEditable) ref.current?.focus()
  }, [isEditable])

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value)
  }, [])

  const handleDoubleClick = useCallback(() => {
    if (onChange) handleSetEditable(true)
  }, [onChange, handleSetEditable])

  const handleBlur = useCallback(() => {
    handleSetEditable(false)
    if (onChange && value !== currentValue) onChange(currentValue)
  }, [onChange, handleSetEditable, value, currentValue])

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const { key } = e
      if (key === 'Enter' && onChange) {
        handleSetEditable(true)
      }
      if (key === 'Delete' && onDelete) {
        onDelete()
      }
    },
    [onChange, onDelete, handleSetEditable]
  )

  const handleInputKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const { key } = e
      if (key === 'Enter') {
        ref.current?.blur()
        focuser.ref.current?.focus()
        e.stopPropagation()
      }
    },
    [focuser]
  )

  const classes = useStyles()
  return (
    <Menu
      setMenuItems={setMenuItems}
      onDelete={onDelete}
      onSelect={onMenuSelect}
      setEditable={onChange ? handleSetEditable : undefined}
    >
      <div
        ref={focuser.ref}
        className={`${classes.container} focusable-input`}
        tabIndex={0}
        role='gridcell'
        onDoubleClick={handleDoubleClick}
        onKeyUp={handleKeyPress}
        onKeyDown={isEditable ? undefined : focuser.handleKeyDown}
      >
        {startTools}
        <div className={fixedHeight ? classes.overflow : classes.preventOverflow}>
          {isEditable ? (
            <Input
              inputRef={ref}
              className={classes.input}
              value={currentValue}
              onBlur={handleBlur}
              onKeyUp={handleInputKeyPress}
              onChange={handleChange}
              disableUnderline
            />
          ) : (
            display
          )}
        </div>
      </div>
    </Menu>
  )
}

interface MenuProps<T extends string> {
  setMenuItems?: (defaultItems: Operation[]) => (T | Operation)[]
  onSelect?: (item: T) => void
  onDelete?: () => void
  setEditable?: (value: boolean) => void
}

/**
 * Context menu that combines default items with given items.
 * Default items are handled internally.
 */
const Menu = <T extends string>({
  children,
  setMenuItems,
  setEditable,
  onSelect,
  onDelete,
}: PropsWithChildren<MenuProps<T>>) => {
  const baseItems = useMemo(
    () =>
      Object.values(Operation).filter((operation) => {
        if (operation === Operation.Edit && !setEditable) return false
        if (operation === Operation.Delete && !onDelete) return false
        return true
      }),
    [setEditable, onDelete]
  )

  const handleMenu = useCallback(
    (operation: Operation | T) => {
      if (baseItems.includes(operation as Operation)) {
        if (operation === Operation.Edit && setEditable) setEditable(true)
        else if (operation === Operation.Delete && onDelete) onDelete()
      } else if (onSelect) onSelect(operation as T)
    },
    [baseItems, onDelete, setEditable, onSelect]
  )

  const items = useMemo(() => (setMenuItems ? setMenuItems(baseItems) : baseItems), [setMenuItems, baseItems])
  return (
    <ContextMenu items={items} onSelect={handleMenu}>
      {children}
    </ContextMenu>
  )
}

const MemoizedFocusableInput = memo(FocusableInput)

export default MemoizedFocusableInput
