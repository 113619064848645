import React, { useCallback } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'

import AppRoutes from 'appRoutes'
import { LoginComponent } from './LoginComponent'
import { useLogin } from './login.hook'

interface MyLocationState {
  from: { pathname: string; search: string }
}

export type ILoginProps = RouteComponentProps<Record<string, string>, Record<string, string>, MyLocationState>

const Login: React.FC<ILoginProps> = (props) => {
  const {
    state: { isAuthenticated, loading },
    actions: { login },
  } = useLogin()

  const handleLogin = useCallback(
    (username: string, password: string) => {
      login({ username, password })
    },
    [login]
  )
  const { location } = props
  const { from } = location.state || {
    from: { pathname: AppRoutes.Home, search: location.search },
  }
  if (isAuthenticated) {
    return <Redirect to={from} />
  }
  return <LoginComponent onSubmit={handleLogin} loading={loading} />
}

export default Login
