import React, { useCallback, useEffect, useRef } from 'react'
import { IMarkedScenario, IResultData } from 'analyses/analysis.model'
import { useDispatch } from 'react-redux'
import { setSvgFunctions } from 'analyses/reducers'
import { Container } from 'components/Common'
import { useContourWidth, useHeight } from 'analyses/hooks/ui'
import { ColorMap, getColorFromMap } from './colormap'
import { useD3 } from './useD3'
import { useScatter } from './useScatter'
import { useContour } from './useContour'

interface Props {
  contour: IResultData
  selectedScenarios: number[]
  markedScenarios: Record<string, IMarkedScenario>
  onSelect: (scenario: number) => void
  onEdit: (index: number) => void
  contourLevels: number
  showSelectionDiamonds: boolean
  colorMap: ColorMap
}

const Contour = ({
  contour,
  selectedScenarios,
  contourLevels,
  markedScenarios,
  colorMap,
  onSelect,
  onEdit,
  showSelectionDiamonds,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const width = useContourWidth()
  const height = useHeight()
  const initialScale = Math.min(width, height) / contour.height_grid.length
  const backgroundColor = getColorFromMap(colorMap, 0)

  const handleEnter = useCallback(() => {
    if (selectedScenarios.length) onEdit(selectedScenarios[0])
  }, [onEdit, selectedScenarios])

  const properties = useD3(ref, initialScale, backgroundColor, width, height, handleEnter)
  const { drawingArea, zoomLevel, clickCoordinates, exportPng, exportSvg, resetZoom } = properties

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setSvgFunctions({ exportPng, exportSvg, resetZoom }))
  }, [dispatch, exportPng, exportSvg, resetZoom])

  useContour(drawingArea, contourLevels, initialScale, 'white', colorMap, contour)

  useScatter(
    drawingArea,
    zoomLevel,
    clickCoordinates,
    contour,
    markedScenarios,
    selectedScenarios,
    showSelectionDiamonds,
    onSelect,
    onEdit
  )

  return <Container ref={ref} />
}

export default Contour
