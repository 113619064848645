import { AccountStorage } from 'shared/util/accountStorage'

const FETCH_URL = process.env.REACT_APP_API_URL
const csrfToken = (): string => {
  function getCookieValue(a: string): string {
    const b = document.cookie.match(`(^|[^;]+)\\s*${a}\\s*=\\s*([^;]+)`)
    return b ? b.pop() || '' : ''
  }

  return getCookieValue('csrftoken')
}

// Add if more literals if needed
export type FetchMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
export type FetchDataType = 'application/json' | 'application/txt'

interface IFetchCall {
  endpoint: string
  accept?: FetchDataType
  contentType?: FetchDataType
  method?: FetchMethod
  body?: Record<string, unknown>
  headers?: Record<string, unknown>
}

const bodyToQuery = (body: Record<string, unknown>) =>
  Object.keys(body)
    .map((key) => `${key}=${body[key]}`)
    .join('&')

export async function fetchRaw<T>({
  endpoint,
  method = 'GET',
  accept = 'application/json',
  contentType = 'application/json',
  body = undefined,
  headers = {},
}: IFetchCall): Promise<T> {
  const url = endpoint.endsWith('/') ? `${FETCH_URL}/${endpoint}` : `${FETCH_URL}/${endpoint}/`

  const requestHeaders = {
    Accept: accept,
    'Content-Type': contentType,
    'X-CSRFToken': csrfToken(),
    ...headers,
  }

  const requestBody = method === 'GET' ? undefined : JSON.stringify(body)

  const requestUrl = method === 'GET' && body ? `${url}?${bodyToQuery(body)}` : url

  const response = await fetch(requestUrl, {
    method,
    body: requestBody,
    headers: requestHeaders,
  })

  if (response.status === 403) {
    // On unauthorized remove authentication and reload to redirect user to login page.
    AccountStorage.setAuthenticated(false)
    window.location.reload()
  }
  if (response.status === 404) {
    throw new Error('Error')
  }
  switch (accept) {
    case 'application/json':
      return response.json()
    case 'application/txt':
    default:
      break
  }

  return response.text() as never
}

export async function commandRaw({
  endpoint,
  method = 'GET',
  accept = 'application/json',
  contentType = 'application/json',
  body = undefined,
  headers = {},
}: IFetchCall): Promise<unknown> {
  const url = endpoint.endsWith('/') ? `${FETCH_URL}/${endpoint}` : `${FETCH_URL}/${endpoint}/`

  const response = await fetch(url, {
    method,
    body: JSON.stringify(body),
    headers: {
      Accept: accept,
      'Content-Type': contentType,
      'X-CSRFToken': csrfToken(),
      ...headers,
    },
  })

  if (response.status === 403) {
    // On unauthorized remove authentication and reload to redirect user to login page.
    AccountStorage.setAuthenticated(false)
    window.location.reload()
  }
  return response
}
