import { makeStyles, Menu, MenuItem, PopoverPosition } from '@material-ui/core'
import React, { PropsWithChildren, useCallback, useState, MouseEvent } from 'react'

interface Props<T extends string> {
  items: T[]
  onSelect: (item: T) => void
}

const useStyles = makeStyles({
  wrapper: {
    cursor: 'context-menu',
    height: '100%',
  },
})

/**
 * Simple context menu that shows a list of operations and calls a callback on select.
 * Generic type T allows using enums for a better type safety.
 */
const ContextMenu = <T extends string>({ items, onSelect, children }: PropsWithChildren<Props<T>>) => {
  const [position, setPosition] = useState<PopoverPosition | undefined>(undefined)

  const handleClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    // Required if menus are nested.
    event.stopPropagation()
    setPosition((prev) => {
      // When the context menu is open it captures mouse all over the screen.
      // So a right click would reopen the current menu instead of being context sensitive.
      // It's better to close the menu instead of exposing this weird behavior to the user.
      if (prev) return undefined
      return {
        left: event.clientX - 2,
        top: event.clientY - 4,
      }
    })
  }, [])

  const handleClose = useCallback(() => {
    setPosition(undefined)
  }, [])

  const handleSelect = useCallback(
    (item: T) => {
      handleClose()
      onSelect(item)
    },
    [onSelect, handleClose]
  )

  const classes = useStyles()
  if (items.length === 0) return <>{children}</>

  return (
    <div onContextMenu={handleClick} className={classes.wrapper}>
      {children}
      <Menu
        keepMounted
        open={!!position}
        onClose={handleClose}
        anchorReference='anchorPosition'
        anchorPosition={position}
      >
        {items.map((item) => (
          <MenuItem key={item} onClick={() => handleSelect(item)}>
            {item}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default ContextMenu
