import { use } from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'
import common from 'en_common.json'
import scenario from 'en_scenario.json'
import strategy from 'en_strategy.json'
import React, { createContext, PropsWithChildren, useCallback, useContext } from 'react'

export type TranslationKey = keyof typeof common | (keyof typeof scenario & keyof typeof strategy)

export const useTranslate = () => useContext(TranslationContext)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TranslateFunction = (key: TranslationKey, options?: any) => TranslationKey
const TranslationContext = createContext<TranslateFunction>(
  (key, options) => (key + (options ? `_${JSON.stringify(options)}` : '')) as TranslationKey
)

const application = process.env.REACT_APP_APPLICATION === 'scenario' ? scenario : strategy
const resources = {
  en: {
    translation: { ...common, ...application },
  },
}

/**
 * Makes the translate function globally accessible so that useTranslate doesn't have to create new instances.
 */
export const TranslationProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { t } = useTranslation()
  const translate = useCallback<TranslateFunction>((key, options) => t(key, options), [t])
  return <TranslationContext.Provider value={translate}>{children}</TranslationContext.Provider>
}

const i18n = use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    ns: 'translation',
  })

export default i18n
