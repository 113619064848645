export type ColorPart = {
  index: number
  value: number
}

export type ColorMap = {
  red: ColorPart[]
  green: ColorPart[]
  blue: ColorPart[]
}

// More colorsmaps can be acquired from https://github.com/matplotlib/matplotlib/blob/master/lib/matplotlib/_cm.py
export const getGrayScale = (min: number, max: number) => ({
  red: [
    { index: 0, value: max },
    { index: 1, value: min },
  ],
  green: [
    { index: 0, value: max },
    { index: 1, value: min },
  ],
  blue: [
    { index: 0, value: max },
    { index: 1, value: min },
  ],
})
