/* eslint-disable no-console */
import { IAccount, Role } from 'shared/model'
import { fetchRaw } from './fetchWrapper'

type IUser = {
  id: number
  username: string
  is_superuser: boolean
  first_name: string
  last_name: string
  email: string
  can_add: boolean
}

const login = async (username: string, password: string): Promise<IAccount | undefined> => {
  try {
    const user = await fetchRaw<IUser>({
      endpoint: 'user/login/',
      method: 'POST',
      body: { username, password },
    })

    if (!user) {
      console.error(`Could not login for username: ${username}`)
      return undefined
    }

    return {
      userName: user.username,
      firstNames: user.first_name,
      lastName: user.last_name,
      roles: convertRoles(user),
      token: '',
      can_add: user.can_add,
    }
  } catch (ex) {
    console.error(`Caught exception while trying to login username: ${username}`, ex)
    return undefined
  }
}

const logout = async (): Promise<string | undefined> => {
  try {
    return await fetchRaw({
      endpoint: 'user/logout/',
      method: 'POST',
    })
  } catch (ex) {
    console.error('Caught exception while trying to logout', ex)
    return undefined
  }
}

const authObject = {
  login,
  logout,
}

export default authObject;

const convertRoles = ({ is_superuser }: IUser) => (is_superuser ? [Role.Admin] : [Role.User])
