import { styled } from '@material-ui/core'
import React, { useCallback, useState, useEffect, ReactNode, useMemo } from 'react'

import ReactGridLayout, { Layout } from 'react-grid-layout'
import { useDispatch } from 'react-redux'
import theme from 'theme'

interface Props {
  columns: number
  height: number
  width: number
  // Initial left column amount, also used for resting.
  initial: number
  // Whether the left side is hidden. Layout is stored.
  hideLeft?: boolean
  // The action to set current value as percent.
  setValueAction?: (value: number) => void
  // The action to set reset callback.
  setResetAction?: (value: () => void) => void
  // Left and right side components.
  children: [ReactNode, ReactNode]
}

export type SetLayout = (layout: Layout[] | ((prev: Layout[]) => Layout[])) => void

/**
 * Adds support for hiding the left side. Restored the previous layout when shown.
 */
const useHideLeft = (initialLayout: Layout[], columns: number, hideLeft: boolean | undefined, setLayout: SetLayout) => {
  const [leftWidth, setLeftWidth] = useState(initialLayout[0].w)

  // Two useEffects are needed to break dependency between setToolbarWidth and toolbarWidth.
  useEffect(() => {
    if (!hideLeft) {
      setLayout((prev) => [
        { ...prev[0], w: leftWidth },
        { ...prev[1], x: leftWidth, w: columns - leftWidth },
      ])
    }
  }, [hideLeft, setLayout, columns, leftWidth])

  useEffect(() => {
    if (hideLeft) {
      setLayout((prev) => {
        setLeftWidth(prev[0].w)
        return [
          { ...prev[0], w: 0 },
          { ...prev[1], x: 0, w: columns },
        ]
      })
    }
  }, [hideLeft, setLayout, columns])
  return setLeftWidth
}

/**
 * Creates and stores the reset callback to Redux.
 */
export const useResetting = (
  initialLayout: Layout[],
  setLayout: SetLayout,
  callback?: (value: () => void) => void,
  setLeftWidth?: (value: number) => void
) => {
  const dispatch = useDispatch()
  const handleReset = useCallback(() => {
    setLayout(initialLayout)
    if (setLeftWidth) setLeftWidth(initialLayout[0].w)
  }, [setLayout, setLeftWidth, initialLayout])
  useEffect(() => {
    if (callback) dispatch(callback(handleReset))
  }, [callback, dispatch, handleReset])
}

/**
 * Handles layout state and updates it to Redux on change.
 */
const useLayoutState = (initialLayout: Layout[], columns: number, callback?: (value: number) => void) => {
  const dispatch = useDispatch()
  const [currentLayout, setCurrentLayout] = useState(initialLayout)
  const handleLayoutChange = useCallback(
    (layout: Layout[] | ((prev: Layout[]) => Layout[])) => {
      setCurrentLayout((prev) => {
        if (Array.isArray(layout)) {
          if (callback) dispatch(callback(layout[0].w / columns))
          return layout
        }
        const newLayout = layout(prev)
        if (callback) dispatch(callback(newLayout[0].w / columns))
        return newLayout
      })
    },
    [dispatch, callback, columns]
  )
  return [currentLayout, handleLayoutChange] as const
}

/**
 * Keeps the total width fixed when resizing columns.
 */
const useResizing = (columns: number) => {
  const handleResize = useCallback(
    (layout: Layout[], _2, { w, i }: Layout) => {
      // eslint-disable-next-line no-param-reassign
      if (i === 'left') layout[1].w = columns - w
      // eslint-disable-next-line no-param-reassign
      if (i === 'right') layout[0].w = columns - w
    },
    [columns]
  )

  return handleResize
}

const useInitialLayout = (initial: number, columns: number) =>
  useMemo(
    () =>
      [
        {
          i: 'left',
          x: 0,
          y: 0,
          w: initial,
          h: 1,
          minW: 0,
          maxW: columns - 1,
          resizeHandles: ['e'],
        },
        {
          i: 'right',
          x: initial,
          y: 0,
          w: columns - initial,
          h: 1,
          minW: 1,
          maxW: columns,
          isResizable: false,
          resizeHandles: [],
        },
      ] as Layout[],
    [initial, columns]
  )

/**
 * A specialized react-grid-layout that has a left and a right side on a single fixed size column.
 * Supports storing the value to Redux, storing the reset callback to Redux and hiding the left side.
 */
const RowLayout = ({ columns, width, height, initial, setValueAction, setResetAction, hideLeft, children }: Props) => {
  const initialLayout = useInitialLayout(initial, columns)
  const [layout, setLayout] = useLayoutState(initialLayout, columns, setValueAction)
  const setLeftWidth = useHideLeft(initialLayout, columns, hideLeft, setLayout)
  const handleResize = useResizing(columns)
  useResetting(initialLayout, setLayout, setResetAction, setLeftWidth)
  return (
    <GridLayout
      cols={columns}
      rowHeight={height}
      maxRows={1}
      width={width}
      compactType='horizontal'
      layout={layout}
      onLayoutChange={setLayout}
      onResize={handleResize}
      isDraggable={false}
      containerPadding={[0, 0]}
      margin={[theme.shape.divider, 0]}
    >
      <div key='left'>{children[0]}</div>
      <div key='right'>{children[1]}</div>
    </GridLayout>
  )
}

const GridLayout = styled(ReactGridLayout)({
  '&>.react-grid-item.cssTransforms': {
    transitionProperty: 'none',
  },
  '&>.react-grid-item>.react-resizable-handle': {
    top: 0,
    bottom: 0,
    transform: 'initial',
    cursor: 'e-resize',
    height: 'initial',
    marginRight: -theme.shape.divider,
    marginTop: 0,
    padding: 0,
    width: theme.shape.divider,
    backgroundColor: theme.palette.background.default,
    backgroundImage: 'none',
    '&:after': {
      content: 'none',
    },
  },
  '&>.react-grid-item.react-grid-placeholder': {
    backgroundColor: 'unset',
  },
})

export const GridLayoutWideHandles = styled(ReactGridLayout)({
  '&>.react-grid-item.cssTransforms': {
    transitionProperty: 'none',
  },
  '&>.react-grid-item>.react-resizable-handle': {
    top: 0,
    bottom: 0,
    transform: 'initial',
    cursor: 'e-resize',
    height: 'initial',
    marginRight: -theme.shape.wideDivider,
    marginTop: 0,
    padding: 0,
    width: theme.shape.wideDivider,
    backgroundColor: theme.palette.background.default,
    backgroundImage: 'none',
    '&:after': {
      content: 'none',
    },
  },
  '&>.react-grid-item.react-grid-placeholder': {
    backgroundColor: 'unset',
  },
})

export default RowLayout
