import { createTheme } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'

declare module '@material-ui/core/styles/createTypography' {
  interface Typography {
    contour: {
      fontSize: number
      fontWeight: number
    }
  }
  interface TypographyOptions {
    contour: {
      fontSize: number
      fontWeight: number
    }
  }
}
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    hover: string
    selected: string
    consistencyTable: {
      stronglyConsistent: string
      consistent: string
      slightlyConsistent: string
      independent: string
      slightlyInconsistent: string
      inconsistent: string
      stronglyInconsistent: string
      exclusive: string
    }
    uncertaintyTable: {
      selected: string
      included: string
      selected_bg: string
      included_bg: string
    }
    contour: {
      defaultColor: string
      defaultSelectColor: string
      scatterLineColor: string
      selectionOpacity: number
    }
    groups: string[]
    toolbar: {
      header: string
      list: string
    }
  }

  interface TypeBackground {
    table: string
  }

  interface PaletteOptions {
    hover: string
    selected: string
    consistencyTable: {
      stronglyConsistent: string
      consistent: string
      slightlyConsistent: string
      independent: string
      slightlyInconsistent: string
      inconsistent: string
      stronglyInconsistent: string
      exclusive: string
    }
    uncertaintyTable: {
      selected: string
      included: string
      selected_bg: string
      included_bg: string
    }
    contour: {
      defaultColor: string
      defaultSelectColor: string
      scatterLineColor: string
      selectionOpacity: number
    }
    groups: string[]
    toolbar: {
      header: string
      list: string
    }
  }
}

declare module '@material-ui/core/styles/shape' {
  interface Shape {
    navigation: {
      height: number
      indicatorHeight: number
      toolBarPadding: number
    }
    topBar: {
      height: number
      // Centers the revision dropdown with higherscreen widths.
      flexBasis: number
    }
    consistencyTable: {
      cellDimension: number
      cellPadding: number
      headerPadding: number
    }
    scenarioList: {
      itemHeight: number
      itemPadding: number
      headerHeight: number
      borderWidth: number
    }
    scenarioProperties: {
      colorPopoverOffset: number
      colorButtonDimension: number
    }
    login: {
      marginTop: number
      padding: number
      logoWidth: number
    }
    uncertaintyTable: {
      maxScale: number
      columnsPerItem: number
    }
    opener: {
      chipPadding: number
      gridPadding: number
    }
    contour: {
      diamondSize: number
      scatterBaseSize: number
      scatterScalingSize: number
      selectionPrecision: number
      textOffset: number
    }
    modalSpacing: number
    divider: number
    wideDivider: number
    cardPadding: number
    revisionWidth: number
    toolbarItemWidth: number
    headPadding: number
    outcomeListPadding: number
    fixedLineCount: number
    listItemPadding: number
  }
}

declare module '@material-ui/core/styles' {
  interface Theme {
    logo: string
  }
  interface ThemeOptions {
    logo?: string
  }
}

declare module '@material-ui/core/styles/zIndex' {
  interface ZIndex {
    divider: number
  }
}

const { spacing, typography } = createTheme()

const orange = '#ffa914'
const lightorange = '#fef6e5'
const yellow = '##ffe180'
const lightyellow = '#f6f7dd'
const blue = '#1a66ff'
const lightblue = '#4885ff'
const verylightblue = '#a3c2ff'
const veryverylightblue = '#d1e0ff'
const grey = '#ece9e2'
const grey2 = '#e2ddd3'
const lightgrey = '#f1eee9'
const toolbargrey = '#faf9f7'
const borderlightgrey = '#f7f5f2'
const borderdarkgrey = '#777572'

const consistencyRed = '#e50018'
const consistencyOrange = '#ff884e'
const consistencyYellow = '#ffe180'
const consistencyLightYellow = '#f0f294'
const consistencyLightGreen = '#d4f27f'
const consistencyGreen = '#82d44d'
const consistencyDarkGreen = '#009d45'
const consistencyGrey = '#f8f8f8'

const colors =
  process.env.REACT_APP_APPLICATION === 'scenario'
    ? {
        secondary: orange,
        selected_bg: lightorange,
        selected: orange,
        included_bg: lightyellow,
        included: yellow,
        divider: borderlightgrey,
      }
    : {
        secondary: blue,
        selected_bg: verylightblue,
        selected: blue,
        included_bg: veryverylightblue,
        included: lightblue,
        divider: borderdarkgrey,
      }

const logo = process.env.REACT_APP_APPLICATION === 'scenario' ? 'logo_scenario.png' : 'logo_strategy.png'

const theme = createTheme({
  logo,
  zIndex: {
    divider: 100,
  },
  shape: {
    divider: 4,
    wideDivider: 6,
    cardPadding: spacing(1),
    consistencyTable: {
      cellDimension: spacing(4.5),
      cellPadding: spacing(0.5),
      headerPadding: spacing(1),
    },
    topBar: {
      height: spacing(6),
      flexBasis: 500,
    },
    navigation: {
      height: spacing(6),
      indicatorHeight: spacing(0.5),
      toolBarPadding: spacing(3),
    },
    scenarioList: {
      itemHeight: spacing(6),
      itemPadding: spacing(2),
      headerHeight: spacing(6),
      borderWidth: spacing(1),
    },
    scenarioProperties: {
      colorPopoverOffset: spacing(3),
      colorButtonDimension: spacing(4),
    },
    login: {
      marginTop: spacing(10),
      padding: spacing(2),
      logoWidth: 150,
    },
    uncertaintyTable: {
      maxScale: 3,
      columnsPerItem: 4,
    },
    opener: {
      chipPadding: spacing(1),
      gridPadding: spacing(1),
    },
    contour: {
      diamondSize: 7.5,
      scatterBaseSize: 1.25,
      scatterScalingSize: 8.75,
      selectionPrecision: 20,
      textOffset: 0.5,
    },
    outcomeListPadding: spacing(0.5),
    headPadding: spacing(1),
    revisionWidth: 300,
    toolbarItemWidth: 200,
    fixedLineCount: 5,
    modalSpacing: spacing(16),
    listItemPadding: spacing(2),
  },
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: grey2,
      table: grey,
    },
    text: {
      primary: '#000000',
      secondary: colors.secondary,
    },
    toolbar: {
      header: lightgrey,
      list: toolbargrey,
    },
    consistencyTable: {
      consistent: consistencyGreen,
      exclusive: consistencyGrey,
      inconsistent: consistencyOrange,
      independent: consistencyLightYellow,
      slightlyConsistent: consistencyLightGreen,
      slightlyInconsistent: consistencyYellow,
      stronglyConsistent: consistencyDarkGreen,
      stronglyInconsistent: consistencyRed,
    },
    uncertaintyTable: {
      selected: colors.selected,
      selected_bg: colors.selected_bg,
      included: colors.included,
      included_bg: colors.included_bg,
    },
    contour: {
      defaultColor: 'rgba(255, 204, 0, 0.7)',
      defaultSelectColor: 'rgba(255, 204, 0, 0.4)',
      scatterLineColor: 'rgb(127, 127, 127)',
      selectionOpacity: 0.4,
    },
    groups: [colors.secondary, '#000000', '#AAAAAA'],
    divider: colors.divider,
    hover: 'rgba(0, 0, 0, 0.1)',
    selected: 'rgba(0, 0, 0, 0.2)',
  },
  typography: {
    fontSize: 12,
    subtitle1: {
      fontweight: typography.fontWeightBold,
    },
    caption: {
      fontSize: 12,
    },
    contour: {
      fontSize: 16,
      fontWeight: 1000,
    },
  },
})
export default theme
