/* eslint-disable import/no-extraneous-dependencies */
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import { render } from 'react-dom'
import 'fontsource-roboto'
import './index.css'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { TranslationProvider } from 'translation'
import AppRoutes from 'appRoutes'
import App from './App'
import * as serviceWorker from './serviceWorker'
import ErrorBoundary from './shared/error/ErrorBoundary'

import store from './config/store'
import theme from './theme'

const rootEl = document.getElementById('root')

const renderApp = () => {
  render(
    <TranslationProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary>
          <Provider store={store}>
            <BrowserRouter basename={AppRoutes.Base}>
              <App />
            </BrowserRouter>
          </Provider>
        </ErrorBoundary>
      </ThemeProvider>
    </TranslationProvider>,
    rootEl
  )
}
renderApp()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
