import type { IRootState } from 'config/state'
import { defaultArray, defaultObject, defaultRevision } from '../analysis.model'

export const getRevisions = (state: IRootState) => state.analysis?.revisions ?? defaultArray
export const getCurrentVersion = (state: IRootState) => state.analysis?.current_version ?? 0
export const getCurrentRevision = (state: IRootState) => {
  const revisions = getRevisions(state)
  const version = getCurrentVersion(state)
  return revisions.find((item) => item.version === version) ?? defaultRevision
}
export const getConsistencyTable = (state: IRootState) => state.analysis?.consistency_table ?? defaultObject
export const getUncertainties = (state: IRootState) => state.analysis?.futures ?? defaultArray
export const getUncertaintyCount = (state: IRootState) => getUncertainties(state).length
export const getDescription = (state: IRootState) => state.analysis?.description ?? ''
export const getName = (state: IRootState) => state.analysis?.name ?? ''
export const getCanChangeModel = (state: IRootState) => state.analysis?.permissions?.change ?? false
export const getCanChangeResult = (state: IRootState) => state.analysis?.permissions?.changeresult ?? false
export const getId = (state: IRootState) => state.analysis?.id
