import React, { PropsWithChildren, ReactNode, useCallback, useState, Children } from 'react'
import { makeStyles, styled } from '@material-ui/core/styles'
import { AppBar, Tab, Toolbar } from '@material-ui/core'
import TabContext, { useTabContext } from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import theme from 'theme'

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
  bar: {
    height: theme.shape.navigation.height,
  },
  filler: {
    flexGrow: 1,
  },
})

const useHeaderStyles = makeStyles({
  root: {
    height: theme.shape.navigation.height,
    paddingRight: theme.shape.navigation.toolBarPadding,
  },
  indicator: {
    height: theme.shape.navigation.indicatorHeight,
  },
})

const useTabStyles = makeStyles({
  root: {
    ...theme.typography.h6,
    fontweight: theme.typography.fontWeightBold,
    height: theme.shape.navigation.height,
    color: theme.palette.text.primary,
    opacity: 1,
    maxWidth: 'unset',
  },
  selected: {
    color: theme.palette.text.secondary,
  },
})

interface Props {
  labels: string[]
  children: ReactNode[] | ReactNode
  startTools?: (selected: string) => ReactNode[] | ReactNode
  endTools?: (selected: string) => ReactNode[] | ReactNode
}

/**
 * Tabs for showing different components.
 * Components are kept mounted to avoid rerendering them.
 */
const SimpleTabs = ({ labels, children, startTools, endTools }: Props) => {
  const classes = useStyles()
  const headerClasses = useHeaderStyles()
  const tabClasses = useTabStyles()
  const [value, setValue] = useState('0')

  const handleChange = useCallback((_, newValue: string) => {
    setValue(newValue)
  }, [])

  const safeValue = labels.length < Number(value) ? '0' : value

  return (
    <div className={classes.root}>
      <TabContext value={safeValue}>
        <AppBar position='relative' className={classes.bar}>
          <NavigationToolbar disableGutters>
            <TabList classes={headerClasses} onChange={handleChange}>
              {labels.map((item, index) => (
                <Tab classes={tabClasses} label={item} key={item} value={String(index)} />
              ))}
            </TabList>
            {startTools && startTools(value)}
            <div className={classes.filler} />
            {endTools && endTools(value)}
          </NavigationToolbar>
        </AppBar>
        {Children.map(children, (item, index) => (
          <MountedTabPanel key={labels[index]} value={String(index)}>
            {item}
          </MountedTabPanel>
        ))}
      </TabContext>
    </div>
  )
}

const MountedTabPanel = ({ value: id, children }: PropsWithChildren<{ value: string }>) => {
  const context = useTabContext()

  if (context === null) {
    throw new TypeError('No TabContext provided')
  }
  const { value: tabId } = context

  return (
    <MountedPanel
      style={{
        visibility: id === tabId ? 'visible' : 'hidden',
      }}
    >
      {children}
    </MountedPanel>
  )
}

const MountedPanel = styled('div')({
  width: '100%',
  left: 0,
  height: `calc(100% - ${theme.shape.navigation.height}px)`,
  overflowX: 'auto',
  position: 'absolute',
  padding: 0,
})

const NavigationToolbar = styled(Toolbar)({
  minHeight: theme.shape.navigation.height,
})

export default SimpleTabs
