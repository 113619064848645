import { defaultArray } from 'analyses/analysis.model'
import type { UiState } from 'analyses/reducers'
import type { IRootState } from 'config/state'
import theme from 'theme'

export const getSelectedOutcomes = (state: IRootState) => state.ui.selectedOutcomes ?? defaultArray
export const getOnlyKeyScenarios = (state: IRootState) => state.ui.onlyKeyScenarios ?? false
export const getOnlyMarkedScenarios = (state: IRootState) => state.ui.onlyMarkedScenarios ?? false
export const getOnlyBookmarks = (state: IRootState) => state.ui.onlyBookmarks ?? false

export const getWidth = (state: UiState) => state.dimensions.width

export const getHeightWithNavigation = (state: UiState) => state.dimensions.height

export const getHeight = (state: UiState) => state.dimensions.height - theme.shape.navigation.height

export const getLeftSideWidth = (state: UiState) =>
  (state.dimensions.width - theme.shape.divider) * state.dimensions.layout

export const getRightSideWidth = (state: UiState) =>
  (state.dimensions.width - theme.shape.divider) * (1.0 - state.dimensions.layout)

// Result map secretly takes the entire screen size (to keep toolbar size static when main layout changes).
// This means the toolbar amount must be scaled accordingly.
export const getContourWidth = (state: UiState) =>
  (getLeftSideWidth(state) - theme.shape.divider) * (1.0 - state.dimensions.toolbar / state.dimensions.layout)
