/* eslint-disable no-param-reassign */
// It's ok to reassign drafts.
import { Draft, PayloadAction } from '@reduxjs/toolkit'
import { defaultResultMap, IRevision } from 'analyses/analysis.model'
import { OnPending } from 'analyses/utils'
import type { AnalysisState } from '..'
import { addRevision } from './revision'

type D = Draft<AnalysisState>

const findResultMap = (draft: D, id: number) => draft?.result?.result_maps.find((item) => item.id === id)
const findResultMapIndex = (draft: D, id: number) =>
  draft?.result?.result_maps.findIndex((item) => item.id === id) ?? -1

export const addResultMap = (draft: D, action: PayloadAction<IRevision, string, { arg: [string] }>) => {
  const {
    payload,
    meta: { arg },
  } = action
  const [name] = arg
  if (draft && draft.result) {
    if (!draft.result.result_maps) draft.result.result_maps = []
    draft.result.result_maps.push({
      ...defaultResultMap,
      id: Number(payload.data.id),
      primary: draft.result.result_maps.length === 0,
      name,
    })
  }
  addRevision(draft, action)
}

export const removeResultMap = (draft: D, { meta: { arg } }: OnPending<[number]>) => {
  const [id] = arg
  const index = findResultMapIndex(draft, id)
  if (index > -1) {
    draft?.result?.result_maps.splice(index, 1)
  }
}

export const changeResultMapName = (draft: D, { meta: { arg } }: OnPending<[number, string]>) => {
  const [id, name] = arg
  const resultMap = findResultMap(draft, id)
  if (resultMap) {
    resultMap.name = name
  }
}

export const setPrimaryResultMap = (draft: D, { meta: { arg } }: OnPending<[number]>) => {
  const [id] = arg
  if (draft && draft.result) {
    draft.result.result_maps.forEach((item) => {
      item.primary = item.id === id
    })
  }
}

export const setShownScenarios = (draft: D, { meta: { arg } }: OnPending<[number, number]>) => {
  const [id, amount] = arg
  const resultMap = findResultMap(draft, id)
  if (resultMap) {
    resultMap.shown_scenarios = amount
  }
}

export const setShowSelectionDiamonds = (draft: D, { meta: { arg } }: OnPending<[number, boolean]>) => {
  const [id, value] = arg
  const resultMap = findResultMap(draft, id)
  if (resultMap) {
    resultMap.show_selection_diamonds = value
  }
}

export const setContourLevels = (draft: D, { meta: { arg } }: OnPending<[number, number]>) => {
  const [id, amount] = arg
  const resultMap = findResultMap(draft, id)
  if (resultMap) {
    resultMap.contour_levels = amount
  }
}

export const setColorScale = (draft: D, { meta: { arg } }: OnPending<[number, number, number]>) => {
  const [id, min, max] = arg
  const resultMap = findResultMap(draft, id)
  if (resultMap) {
    resultMap.color_scale_min = min
    resultMap.color_scale_max = max
  }
}

export const markScenario = (draft: D, { meta: { arg } }: OnPending<[number, number, string, string]>) => {
  const [id, index, color, label] = arg
  const resultMap = findResultMap(draft, id)
  if (resultMap) {
    if (!resultMap.scenarios) resultMap.scenarios = []
    const scenario = resultMap.scenarios.find((item) => item.index === index)
    if (scenario) {
      scenario.color = color
      scenario.label = label
    } else {
      resultMap.scenarios.push({ index, color, label })
    }
  }
}
