import { ActionCreators } from 'immer-reducer'
import { useDispatch } from 'react-redux'
import { useMemo } from 'react'
import type { IRootState } from 'config/state'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RetType<T extends ActionCreators<any>> = {
  [K in keyof T]: (...properties: Parameters<T[K]>) => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useActions = <T extends ActionCreators<any>>(actions: T): RetType<T> => {
  const dispatch = useDispatch()
  return useMemo(
    () =>
      Object.entries(actions).reduce((acc, [key, action]) => {
        const actionKey = key as keyof T
        return {
          ...acc,
          [actionKey]: (...params: Parameters<typeof action>) => {
            dispatch(action(...params))
          },
        }
      }, {} as RetType<T>),
    [actions, dispatch]
  )
}

/**
 * Helper to get actual type from thunk api getState.
 */
export const getRootState = (getState: () => unknown) => getState() as IRootState

// On production build functions don't have a name so just use numbeirng.
let typeCounter = 0
// eslint-disable-next-line @typescript-eslint/ban-types
export const getActionName = (func: Function) =>
  // eslint-disable-next-line no-plusplus
  process.env.NODE_ENV === 'production' ? `action${typeCounter++}` : func.name
