import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import { ContainerFullWidth } from './Common'

interface Props {
  id: string
  label: string
  onChange: (value1: number, value2: number) => void
  min: number
  max: number
  value1: number
  value2: number
}

/**
 * A rangeslider to select a range.
 * onChange only triggers when a different value is selected.
 */
const DoubleInputSlider = ({ id, value1, value2, label, onChange, min, max }: Props) => {
  const [currentValue1, setCurrentValue1] = useState(value1)
  const [currentValue2, setCurrentValue2] = useState(value2)

  useEffect(() => {
    setCurrentValue1(value1)
  }, [value1])

  useEffect(() => {
    setCurrentValue2(value2)
  }, [value2])

  const handleSliderChange = useCallback((_, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setCurrentValue1(newValue[0])
      setCurrentValue2(newValue[1])
    }
  }, [])

  const handleSliderCommit = useCallback(
    (_, newValue: number | number[]) => {
      if (Array.isArray(newValue)) {
        if (newValue[0] !== value1 || newValue[1] !== value2) onChange(newValue[0], newValue[1])
      }
    },
    [value1, value2, onChange]
  )

  const value = useMemo(() => [currentValue1, currentValue2], [currentValue1, currentValue2])

  return (
    <ContainerFullWidth>
      <Typography id={id} gutterBottom className='label' component='span'>
        {label}
      </Typography>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs>
          <Slider
            color='secondary'
            value={value}
            min={min}
            max={max}
            onChange={handleSliderChange}
            onChangeCommitted={handleSliderCommit}
            aria-labelledby={id}
          />
        </Grid>
      </Grid>
    </ContainerFullWidth>
  )
}

export default DoubleInputSlider
