import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { IconButton } from '@material-ui/core'
import IconBookmark from '@material-ui/icons/Bookmark'
import IconBookmarkOutlined from '@material-ui/icons/BookmarkBorderOutlined'
import SimpleInput from 'components/SimpleInput'
import { useTranslate } from 'translation'
import { useCanChangeModel, useCurrentRevision } from 'analyses/hooks'
import { bookmarkRevision } from '../reducers'

/**
 * Input for bookmarking a revision with a description.
 */
const AnalysisBookmarker = () => {
  const dispatch = useDispatch()
  const { version, description } = useCurrentRevision()
  const canChange = useCanChangeModel()

  const [isEditable, setEditable] = useState(false)

  const handleEditBookmark = useCallback(() => {
    setEditable(true)
  }, [])

  const t = useTranslate()

  const handleChange = useCallback(
    (value: string) => {
      setEditable(false)
      dispatch(bookmarkRevision(version, value))
    },
    [dispatch, version]
  )

  const isBookmarked = !!description
  if (!canChange) return null

  return (
    <div>
      <IconButton
        aria-label={isBookmarked ? t('Remove bookmark revision') : t('Add bookmark revision')}
        onClick={handleEditBookmark}
        color='secondary'
      >
        {isBookmarked || isEditable ? <IconBookmark /> : <IconBookmarkOutlined />}
      </IconButton>

      {isEditable ? <SimpleInput autoFocus value={description} onChange={handleChange} /> : description}
    </div>
  )
}

export default AnalysisBookmarker
