/* eslint-disable no-param-reassign */
// It's ok to reassign drafts.
import { Draft, PayloadAction } from '@reduxjs/toolkit'
import { IRevision, IUncertainty, IOutcome } from 'analyses/analysis.model'
import { OnPending } from 'analyses/utils'
import type { AnalysisState } from '..'
import { addRevision } from './revision'

type D = Draft<AnalysisState>

const findUncertaintyIndex = (draft: D, id: number) => draft?.futures.findIndex((item) => item.id === id) ?? -1
const findUncertainty = (draft: D, id: number) => draft?.futures.find((item) => item.id === id)
const findOutcome = (draft: D, id: number) => {
  const uncertainty = draft?.futures.find((item) => item.outcomes.find((outcome) => outcome.id === id))
  return uncertainty?.outcomes.find((item) => item.id === id)
}

const findOutcomeIndex = (draft: D, id: number) => {
  const uncertaintyIndex = draft?.futures.findIndex((item) => item.outcomes.find((outcome) => outcome.id === id)) ?? -1
  if (uncertaintyIndex > -1) {
    const outcomes = draft?.futures[uncertaintyIndex].outcomes
    const outcomeIndex = outcomes?.findIndex((item) => item.id === id) ?? -1
    return [uncertaintyIndex, outcomeIndex]
  }
  return [uncertaintyIndex, -1]
}

export const changeUncertaintyName = (draft: D, { meta: { arg } }: OnPending<[number, string]>) => {
  const [id, name] = arg
  const uncertainty = findUncertainty(draft, id)
  if (uncertainty) uncertainty.name = name
}

export const changeUncertaintyOrder = (draft: D, { meta: { arg } }: OnPending<[number[]]>) => {
  const [order] = arg
  if (draft) {
    draft.futures = order.map((id) => findUncertainty(draft, id) as IUncertainty)
  }
}

export const removeUncertainty = (draft: D, { meta: { arg } }: OnPending<[number]>) => {
  const [id] = arg
  const index = findUncertaintyIndex(draft, id)
  if (index > -1) {
    draft?.futures.splice(index, 1)
  }
}

export const addUncertainty = (draft: D, action: PayloadAction<IRevision, string, { arg: [string] }>) => {
  const {
    payload,
    meta: { arg },
  } = action
  const [name] = arg
  if (draft) {
    draft.futures.push({
      name,
      id: Number(payload.data.id),
      outcomes: [],
    })
  }
  addRevision(draft, action)
}

export const addOutcome = (draft: D, action: PayloadAction<IRevision, string, { arg: [number, string] }>) => {
  const {
    payload,
    meta: { arg },
  } = action
  const [id, name] = arg
  const uncertainty = findUncertainty(draft, id)
  if (uncertainty) {
    uncertainty.outcomes.push({
      name,
      id: Number(payload.data.id),
    })
  }
  addRevision(draft, action)
}

export const changeOutcomeOrder = (draft: D, { meta: { arg } }: OnPending<[number, number[]]>) => {
  const [analysisId, order] = arg
  if (draft) {
    const uncertainty = findUncertainty(draft, analysisId)
    if (uncertainty) {
      uncertainty.outcomes = order.map((id) => findOutcome(draft, id) as IOutcome)
    }
  }
}

export const removeOutcome = (draft: D, { meta: { arg } }: OnPending<[number]>) => {
  const [id] = arg
  const [uncertaintyIndex, outcomeIndex] = findOutcomeIndex(draft, id)
  if (uncertaintyIndex > -1 && outcomeIndex > -1) {
    draft?.futures[uncertaintyIndex].outcomes.splice(outcomeIndex, 1)
  }
}

export const changeOutcomeName = (draft: D, { meta: { arg } }: OnPending<[number, string]>) => {
  const [id, name] = arg
  const outcome = findOutcome(draft, id)
  if (outcome) outcome.name = name
}

export const setConsistency = (draft: D, { meta: { arg } }: OnPending<[number, number, string]>) => {
  const [from, to, value] = arg
  if (draft) {
    if (!draft.consistency_table[from]) draft.consistency_table[from] = {}
    draft.consistency_table[from][to] = value
    if (!draft.consistency_table[to]) draft.consistency_table[to] = {}
    draft.consistency_table[to][from] = value
  }
}

export const changeAnalysisName = (draft: D, { meta: { arg } }: OnPending<[string]>) => {
  const [name] = arg
  if (draft) draft.name = name
}

export const clearAnalysisState = () => null
