import React from 'react'
import { styled } from '@material-ui/core'
import theme from 'theme'
import Header from './header/Header'

const ViewContainer = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  flexDirection: 'column',
  margin: '0px auto',
})

const MainContainer = styled('main')({
  height: `calc(100% - ${theme.shape.topBar.height}px)`,
})

const Layout: React.FC = ({ children }) => (
  <ViewContainer>
    <Header />
    <MainContainer>{children}</MainContainer>
  </ViewContainer>
)

export default Layout
