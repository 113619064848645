import { IconButton, styled, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import React, { useMemo, useCallback, useState, useEffect, memo } from 'react'
import { Layout } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import AddIcon from '@material-ui/icons/Add'
import { useDispatch } from 'react-redux'
import { IUncertainty } from 'analyses/analysis.model'
import FocusableInput from 'components/FocusableInput'
import ContextMenu from 'components/ContextMenu'
import { CellFocuser, useCellFocuser } from 'components/useCellFocuser'
import { useDebouncedDispatch } from 'shared/util/hooks'
import theme from 'theme'
import { useTranslate } from 'translation'
import { ContainerFullHeight, FixedTable, HeaderCell } from 'components/Common'
import { GridLayoutWideHandles, SetLayout, useResetting } from 'components/RowLayout'
import { formatUncertainty } from 'analyses/utils'
import { useAnalysisId, useCanChangeModel, useUncertainties } from 'analyses/hooks'
import {
  addOutcome,
  changeUncertaintyName,
  changeUncertaintyOrder,
  removeUncertainty,
  setSelectedOutcomes,
  setResetUncertaintyTableLayout,
} from '../reducers'

import { useHeight, useRightSideWidth, useUncertaintyTableFixedHeight } from '../hooks/ui'
import OutcomeList from './OutcomeList'

const emptyArray = [] as never[]

enum UncertaintyOperation {
  AddOutcome = 'Add Outcome',
  ResetLayout = 'Reset grid layout',
}

const uncertaintyOperations = Object.values(UncertaintyOperation)

const UncertaintyColumn = ({
  uncertainty,
  uncertaintyIndex,
  focusers,
  resetLayout,
}: {
  uncertainty: IUncertainty
  uncertaintyIndex: number
  focusers: CellFocuser[]
  resetLayout: () => void
}) => {
  const t = useTranslate()
  const dispatch = useDispatch()
  const canChange = useCanChangeModel()
  const handleMenu = useCallback(
    (operation: UncertaintyOperation) => {
      if (operation === UncertaintyOperation.AddOutcome) dispatch(addOutcome(uncertainty.id, t('Outcome')))
      if (operation === UncertaintyOperation.ResetLayout) resetLayout()
    },
    [dispatch, resetLayout, t, uncertainty.id]
  )

  return (
    <ContextMenu items={canChange ? uncertaintyOperations : emptyArray} onSelect={handleMenu}>
      <div>
        <UncertaintyHeader
          uncertainty={uncertainty}
          uncertaintyIndex={uncertaintyIndex}
          labelFocuser={focusers[0]}
          addOutcomeFocuser={focusers[1]}
        />
        <OutcomeList uncertaintyIndex={uncertaintyIndex} focusers={focusers} />
      </div>
    </ContextMenu>
  )
}

interface UncertaintyHeaderProps {
  labelFocuser: CellFocuser
  addOutcomeFocuser: CellFocuser
  uncertainty: IUncertainty
  uncertaintyIndex: number
}

const UncertaintyHeader = ({
  labelFocuser,
  addOutcomeFocuser,
  uncertainty,
  uncertaintyIndex,
}: UncertaintyHeaderProps) => {
  const dispatch = useDispatch()
  const t = useTranslate()
  const canChange = useCanChangeModel()
  const uncertaintyTableFixedHeight = useUncertaintyTableFixedHeight()
  const handleChangeUncertaintyName = useCallback(
    (name) => {
      dispatch(changeUncertaintyName(uncertainty.id, name))
    },
    [dispatch, uncertainty.id]
  )

  const [editing, setEditing] = useState(false)

  const handleDeleteUncertainty = useCallback(() => {
    dispatch(removeUncertainty(uncertainty.id))
  }, [dispatch, uncertainty.id])

  const handleAddOutcome = useCallback(() => {
    dispatch(addOutcome(uncertainty.id, t('Outcome')))
  }, [dispatch, t, uncertainty.id])

  return (
    <FixedTable aria-label={t('Uncertainties')} className='MuiPaper-elevation2 uncertaintytable-header'>
      <TableHead>
        <TableRow className={canChange && !editing ? 'dragColumn' : ''}>
          <HeaderCell>
            <FocusableInput
              onEditing={setEditing}
              focuser={labelFocuser}
              value={uncertainty.name}
              onChange={canChange ? handleChangeUncertaintyName : undefined}
              display={formatUncertainty(uncertainty, uncertaintyIndex)}
              onDelete={canChange ? handleDeleteUncertainty : undefined}
              fixedHeight={uncertaintyTableFixedHeight}
            />
          </HeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {canChange && (
          <TableRow>
            <Cell>
              <IconButton
                ref={addOutcomeFocuser.ref as never}
                onKeyDown={addOutcomeFocuser.handleKeyDown}
                aria-label={t('Add outcome')}
                onClick={handleAddOutcome}
                size='small'
                color='secondary'
              >
                <AddIcon />
              </IconButton>
            </Cell>
          </TableRow>
        )}
      </TableBody>
    </FixedTable>
  )
}

const Cell = styled(TableCell)({
  backgroundColor: theme.palette.background.paper,
  padding: 0,
  textAlign: 'center',
})

const { maxScale, columnsPerItem } = theme.shape.uncertaintyTable
const maxW = maxScale * columnsPerItem

const UncertaintyTable = () => {
  // Half of the last items resizer would go over the limit.
  const width = maxScale * (useRightSideWidth() - theme.shape.wideDivider / 2)
  const height = useHeight()
  const uncertainties = useUncertainties()
  const canChange = useCanChangeModel()
  const id = useAnalysisId()
  const defaultLayout = useMemo(
    () =>
      uncertainties.map((item, index) => ({
        i: String(item.id),
        x: index * columnsPerItem,
        y: 0,
        w: columnsPerItem,
        h: 1,
        minW: 1,
        maxW,
      })),
    [uncertainties]
  )

  const [currentLayout, setLayout] = useState<Layout[]>(defaultLayout)

  // Manual deps to reset layout when things change too much.
  useEffect(() => {
    setLayout(defaultLayout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uncertainties.length, id])

  const handleResetLayout = useCallback(() => {
    setLayout(defaultLayout)
  }, [defaultLayout])

  // Debouncing must be used because handleLayoutChange sometimes triggers twice (which would result in two api calls).
  const debouncedChangeUncertaintyOrder = useDebouncedDispatch(changeUncertaintyOrder, 100)

  const handleLayoutChange = useCallback(
    (layout: Layout[]) => {
      setLayout(() => {
        const ordered = layout.sort((a, b) => a.x - b.x)
        const isChange = uncertainties.some((item, index) => Number(ordered[index].i) !== item.id)
        if (isChange) {
          debouncedChangeUncertaintyOrder(ordered.map((item) => Number(item.i)))
          return ordered
        }
        return layout
      })
    },
    [debouncedChangeUncertaintyOrder, uncertainties]
  ) as SetLayout

  useResetting(defaultLayout, handleLayoutChange, setResetUncertaintyTableLayout)
  const rows = uncertainties.length ? Math.max(...uncertainties.map((item) => item.outcomes.length)) : 0
  // +2 for uncertainty header row (label and add new outcome).
  const focusers = useCellFocuser(uncertainties.length, rows + (canChange ? 2 : 1))
  const dispatch = useDispatch()
  const handleClick = useCallback(() => {
    dispatch(setSelectedOutcomes([]))
  }, [dispatch])
  return (
    <ContainerFullHeight onClick={handleClick}>
      <GridLayoutWideHandles
        autoSize
        rowHeight={height}
        cols={currentLayout.length * maxW}
        width={width}
        maxRows={1}
        compactType='horizontal'
        draggableHandle='.dragColumn'
        resizeHandles={['ne']}
        layout={currentLayout}
        onLayoutChange={handleLayoutChange}
        containerPadding={[0, 0]}
        margin={[theme.shape.wideDivider, 0]}
      >
        {currentLayout.map((item, index) => {
          // Index can't be directly used because the order of uncertainties doesn't always match the order of the layout (debouncing).
          const uncertaintyIndex = uncertainties.findIndex((uncertainty) => uncertainty.id === Number(item.i))
          // Focusers may get briefly out of sync when removing uncertainties.
          if (uncertaintyIndex === -1 || !focusers[index]) return <div key={item.i} data-grid={item} />
          return (
            <div key={item.i} data-grid={item}>
              <UncertaintyColumn
                focusers={focusers[uncertaintyIndex]}
                uncertainty={uncertainties[uncertaintyIndex]}
                uncertaintyIndex={uncertaintyIndex}
                resetLayout={handleResetLayout}
              />
            </div>
          )
        })}
      </GridLayoutWideHandles>
    </ContainerFullHeight>
  )
}

export default memo(UncertaintyTable)
