import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { IAccount } from 'shared/model'
import { AccountStorage } from 'shared/util/accountStorage'

const initialState = {
  loading: false,
  isAuthenticated: AccountStorage.isAuthenticated(),
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  account: AccountStorage.tryToGet(),
  errorMessage: '', // Errors returned from server side
  redirectMessage: null,
}

export type LoginState = Readonly<typeof initialState>

class LoginImmerReducer extends ImmerReducer<LoginState> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  login(_: { username: string; password: string }) {
    this.draftState.loading = true
    this.draftState.loginError = false
  }

  loginFailed() {
    this.draftState.loading = false
    this.draftState.isAuthenticated = false
    this.draftState.loginError = true
  }

  loginSuccess(account: IAccount) {
    this.draftState.loading = false
    this.draftState.isAuthenticated = true
    this.draftState.account = account
  }

  logout() {
    this.draftState.isAuthenticated = false
    this.draftState.account = undefined
  }
}

export const LoginReducer = createReducerFunction(LoginImmerReducer, initialState)

export const LoginActions = createActionCreators(LoginImmerReducer)
