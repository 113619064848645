import { Button, Divider, Menu, MenuItem, Typography } from '@material-ui/core'
import React, { useCallback, MouseEvent, useState } from 'react'
import { useDispatch } from 'react-redux'
import SimpleInput from 'components/SimpleInput'
import { changeAnalysisName } from 'analyses/reducers/analysis'
import { useTranslate } from 'translation'
import { openModal } from 'analyses/reducers/ui'
import { useCanChangeModel, useName, useCreateAnalysis, useId, useCloneAnalysis } from 'analyses/hooks'
import { useAccount } from 'views/login/login.hook'
import { VerticallyCenteredDiv } from 'components/Common'

const AnalysisMenu = () => {
  const dispatch = useDispatch()
  const t = useTranslate()
  const canChange = useCanChangeModel()
  const account = useAccount()

  const id = useId()
  const name = useName()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const [isEditable, setEditable] = useState(false)

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])
  const createAnalysis = useCreateAnalysis()
  const handleCreate = useCallback(() => {
    handleClose()
    createAnalysis()
  }, [handleClose, createAnalysis])

  const cloneAnalysis = useCloneAnalysis(id ?? 0, name)
  const handleClone = useCallback(() => {
    handleClose()
    cloneAnalysis()
  }, [handleClose, cloneAnalysis])

  const handleNameChange = useCallback(
    (value: string) => {
      dispatch(changeAnalysisName(value))
      setEditable(false)
    },
    [dispatch]
  )

  const handleSetEditable = useCallback(() => {
    setEditable(true)
    handleClose()
  }, [handleClose])

  const handleOpen = useCallback(() => {
    dispatch(openModal(true))
    handleClose()
  }, [handleClose, dispatch])

  return (
    <VerticallyCenteredDiv>
      {isEditable ? (
        <SimpleInput value={name} onChange={handleNameChange} />
      ) : (
        <Button aria-controls='menu-appbar' aria-haspopup='true' onClick={handleMenu} color='inherit'>
          <Typography variant='h6' color='textSecondary'>
            {name}
          </Typography>
        </Button>
      )}

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={handleClose}
        getContentAnchorEl={undefined}
      >
        {canChange && [
          <MenuItem key='name' onClick={handleSetEditable} disabled={id === undefined}>
            {t('Edit name')}
          </MenuItem>,
          <MenuItem key='clone' onClick={handleClone} disabled={id === undefined}>
            {t('Clone')}
          </MenuItem>,
          <Divider key='divider' />,
        ]}
        {account?.can_add && <MenuItem onClick={handleCreate}>{t('New')}</MenuItem>}
        <MenuItem onClick={handleOpen}>{t('Open')}</MenuItem>
      </Menu>
    </VerticallyCenteredDiv>
  )
}

export default AnalysisMenu
