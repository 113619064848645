import React, { ReactElement, useCallback, useState } from 'react'
import { Tooltip as TooltipMUI, withStyles } from '@material-ui/core'
import theme from 'theme'

const LightTooltip = withStyles({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    fontweight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(32),
    border: '1px solid #dadde9',
  },
  arrow: {
    fontSize: 30,
    color: theme.palette.background.paper,
    '&:before': {
      border: '1px solid #E6E8ED',
    },
  },
})(TooltipMUI)

interface Props {
  children: ReactElement
  leftName: string
  topName: string
  forceOpen?: boolean
}

/**
 * Tooltip to show the selected outcome names.
 */
const Tooltip = ({ children, leftName, topName, forceOpen }: Props) => {
  const [open, setOpen] = useState(false)

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  return (
    <LightTooltip
      id={`tooltip-${leftName}-${topName}`}
      open={open || forceOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      title={<RenderToolTip leftName={leftName} topName={topName} />}
      placement='top'
      arrow
    >
      {children}
    </LightTooltip>
  )
}

interface RenderProps {
  leftName: string
  topName: string
}
const RenderToolTip = ({ leftName, topName }: RenderProps) => (
  <>
    {leftName}
    <br />
    {topName}
  </>
)

export default Tooltip
