import { FormControlLabel, FormControlLabelProps, List, ListItem, styled, withStyles } from '@material-ui/core'
import React, { Children, PropsWithChildren } from 'react'
import theme from 'theme'

interface Props {
  size?: 'full' | 'small'
}

/**
 * A list that compressed horizontally when there is enough vertical space.
 */
const ScalingList = ({ children, size }: PropsWithChildren<Props>) => (
  <FlexList>
    {Children.map(children, (child) =>
      size === 'small' ? (
        <FlexItemSmall button={false as never}>{child}</FlexItemSmall>
      ) : (
        <FlexItem button={false as never}>{child}</FlexItem>
      )
    )}
  </FlexList>
)
const FlexList = styled(List)({
  display: 'flex',
  flexWrap: 'wrap',
  padding: 0,
})

const FlexItem = styled(ListItem)({
  flexBasis: theme.shape.toolbarItemWidth,
  flexGrow: 1,
  borderBottom: `solid 1px ${theme.palette.divider}`,
})

const FlexItemSmall = styled(ListItem)({
  flexBasis: theme.shape.toolbarItemWidth / 3.0,
  flexGrow: 1,
  borderBottom: `solid 1px ${theme.palette.divider}`,
})

/**
 * A label that stretches to take the full width.
 * Intended to be used with ScalingList.
 */
export const FullLabel = ({
  nonInteractive,
  labelId,
  ...rest
}: FormControlLabelProps & { nonInteractive?: boolean; labelId: string }) => {
  if (nonInteractive) {
    return (
      <Flex style={rest.style} className={rest.className}>
        <FullNonInteractiveLabel id={labelId} className='MuiTypography-body1'>
          {rest.label}
        </FullNonInteractiveLabel>
        {rest.control}
      </Flex>
    )
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <FullInteractiveLabel {...rest} />
}

const FullInteractiveLabel = withStyles({
  root: {
    width: '100%',
    margin: 0,
  },
  label: {
    marginRight: theme.shape.listItemPadding,
    flexGrow: 1,
  },
})(FormControlLabel)

const Flex = styled('div')({
  display: 'flex',
})
const FullNonInteractiveLabel = styled('span')({
  flexGrow: 1,
  margin: 'auto',
})

export default ScalingList
