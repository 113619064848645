/* eslint-disable no-param-reassign */
// It's ok to reassign drafts.
import { Draft, PayloadAction } from '@reduxjs/toolkit'
import { IAnalysis, IRevision } from 'analyses/analysis.model'
import { OnPending } from 'analyses/utils'
import type { AnalysisState } from '../analysis'

type D = Draft<AnalysisState>

const findRevision = (draft: D, version: number) => draft?.revisions.find((item) => item.version === version)

export const setAnalysis = (draft: D, { payload }: PayloadAction<IAnalysis>) => {
  if (draft && !payload.revisions) {
    return { ...payload, revisions: draft.revisions }
  }
  return payload
}

export const setRevisions = (draft: D, { payload }: PayloadAction<IRevision[]>) => {
  if (draft) {
    draft.revisions = payload
  }
}

export const addRevision = (draft: D, { payload }: PayloadAction<IRevision>) => {
  if (draft) {
    draft.current_version = payload.version
    if (!draft.revisions) draft.revisions = []
    draft.revisions = draft.revisions.filter((item) => item.version <= payload.parent)
    draft.revisions.unshift(payload)
    // New revision might invalidate the result.
    if (draft.result?.version !== payload.result_version) draft.result = undefined
  }
}

export const revertToRevision = (draft: D, { meta: { arg } }: OnPending<[number]>) => {
  const [version] = arg
  if (draft) draft.current_version = version
}

export const editRevisionDescription = (draft: D, { payload }: PayloadAction<IRevision>) => {
  const { version, description } = payload
  const revision = findRevision(draft, version)
  if (revision) revision.description = description
}
