import React, { memo, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import Contour from 'graphics/Contour'
import { styled } from '@material-ui/core'
import theme from 'theme'
import RowLayout from 'components/RowLayout'
import { getGrayScale } from 'graphics/colors'
import RunResult from 'analyses/result_map/RunResult'
import { setSelectedOutcomes, editScenarioLabel, setToolbarLayout } from '../reducers'
import Toolbar from './toolbar/Toolbar'
import {
  useHeight,
  useWidth,
  useShowToolbar,
  useCanChangeResult,
  useShowSelectionDiamonds,
  useColorScale,
  useContourLevels,
  useMarkedScenarios,
  useScenarios,
  useResultData,
  useSelectedScenarios,
} from '../hooks'

const Layout = ({ columns }: { columns: number }) => {
  const dispatch = useDispatch()
  const scenarios = useScenarios()
  const selectedScenarios = useSelectedScenarios()
  const markedScenarios = useMarkedScenarios()
  const data = useResultData()
  const showToolbar = useShowToolbar()
  const canChange = useCanChangeResult()
  const showSelectionDiamonds = useShowSelectionDiamonds()
  const width = useWidth()
  const height = useHeight()
  const contourLevels = useContourLevels()

  const handleSelect = useCallback(
    (index: number) => {
      dispatch(setSelectedOutcomes(scenarios[index].outcome_ids))
    },
    [dispatch, scenarios]
  )

  const handleEditing = useCallback(() => {
    dispatch(editScenarioLabel())
  }, [dispatch])

  const colorScale = useColorScale()
  const colorMap = useMemo(() => getGrayScale(colorScale.min / 10.0, colorScale.max / 10.0), [colorScale])
  if (!data) return <RunResult />
  return (
    <Container>
      <RowLayout
        columns={columns}
        height={height}
        initial={2}
        width={width}
        hideLeft={!showToolbar || !canChange}
        setValueAction={setToolbarLayout}
      >
        <Toolbar id='contour-toolbar' />
        <Contour
          contour={data}
          contourLevels={contourLevels}
          selectedScenarios={selectedScenarios}
          markedScenarios={markedScenarios}
          onSelect={handleSelect}
          onEdit={handleEditing}
          showSelectionDiamonds={showSelectionDiamonds}
          colorMap={colorMap}
        />
      </RowLayout>
    </Container>
  )
}

const Container = styled('div')({
  backgroundColor: theme.palette.background.paper,
  height: '100%',
  overflow: 'hidden',
})

export default memo(Layout)
