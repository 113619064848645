import { Button, Menu, MenuItem, Typography } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { LoginActions } from 'views/login/login.reducer'
import { useTranslate } from 'translation'
import { useAccount } from 'views/login/login.hook'

const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const account = useAccount()

  const handleMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  const dispatch = useDispatch()
  const t = useTranslate()

  const handleLogout = useCallback(() => {
    dispatch(LoginActions.logout())
    setAnchorEl(null)
  }, [setAnchorEl, dispatch])

  return (
    <div>
      <Button
        color='secondary'
        endIcon={<AccountCircle />}
        onClick={handleMenu}
        aria-label={t('My account')}
        aria-controls='menu-appbar'
        aria-haspopup='true'
      >
        <Typography variant='caption' color='secondary'>
          {account?.userName ?? ''}
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={handleClose}
        getContentAnchorEl={undefined}
      >
        <MenuItem onClick={handleLogout}>{t('Logout')}</MenuItem>
      </Menu>
    </div>
  )
}

export default AccountMenu
