import React, { useCallback, useState, useEffect } from 'react'
import { CellFocuser } from 'components/useCellFocuser'
import theme from 'theme'
import Tooltip from './Tooltip'
import { CellContent, FixedCell } from './Cells'

interface Props {
  value: string
  leftName: string
  topName: string
  onChange?: (value: string) => void
  onSelect: () => void
  focuser: CellFocuser
}

const colors = {
  '-3': theme.palette.consistencyTable.stronglyInconsistent,
  '-2': theme.palette.consistencyTable.inconsistent,
  '-1': theme.palette.consistencyTable.slightlyInconsistent,
  '0': theme.palette.consistencyTable.independent,
  '1': theme.palette.consistencyTable.slightlyConsistent,
  '2': theme.palette.consistencyTable.consistent,
  '3': theme.palette.consistencyTable.stronglyConsistent,
  x: theme.palette.consistencyTable.exclusive,
} as Record<string, string>

const NanGuard = (value: number) => (Number.isNaN(value) ? 0 : value)
const LimitGuard = (value: number) => Math.max(Math.min(value, 3), -3)

const useCurrentValue = (value: string, onChange?: (value: string) => void) => {
  const [currentValue, setCurrentValue] = useState(value)
  const handleSetValue = useCallback(
    (newValue: string | ((prev: string) => string)) => {
      if (onChange) setCurrentValue(newValue)
    },
    [onChange]
  )
  return [currentValue, handleSetValue] as const
}

/**
 * Input-like component to change consistency values and proper styling.
 */
const Editor = ({ value, leftName, topName, onChange, onSelect, focuser }: Props) => {
  const [currentValue, setCurrentValue] = useCurrentValue(value, onChange)
  const [focused, setFocused] = useState(false)
  useEffect(() => {
    setCurrentValue(value)
  }, [value, setCurrentValue])

  const handleFocus = useCallback(() => {
    setFocused(true)
    onSelect()
  }, [onSelect])

  const handleUpdate = useCallback(() => {
    if (currentValue === '-') setCurrentValue(value)
    else if (value !== currentValue && onChange) onChange(currentValue)
  }, [onChange, setCurrentValue, value, currentValue])

  const handleBlur = useCallback(() => {
    setFocused(false)
    handleUpdate()
  }, [handleUpdate])

  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const { key, ctrlKey } = e
      if (!Number.isNaN(Number(key))) {
        const number = Number(key)
        setCurrentValue((prev) => String(LimitGuard(prev === '-' ? -number : number)))
      }
      if (key === '-' || key === 'x') {
        setCurrentValue(key)
      }
      if (key === 'Backspace') {
        setCurrentValue('0')
      }
      if (key === 'Enter') {
        handleUpdate()
      }
      if (ctrlKey && key === 'ArrowDown') {
        setCurrentValue((prev) => String(LimitGuard(NanGuard(Number(prev) - 1))))
      }
      if (ctrlKey && key === 'ArrowUp') {
        setCurrentValue((prev) => String(LimitGuard(NanGuard(Number(prev) + 1))))
      }
    },
    [setCurrentValue, handleUpdate]
  )

  const color = colors[currentValue] ?? theme.palette.consistencyTable.independent

  return (
    <Tooltip leftName={leftName} topName={topName} forceOpen={focused}>
      <FixedCell>
        <CellContent
          ref={focuser.ref}
          tabIndex={0}
          role='textbox'
          aria-haspopup='true'
          aria-controls={`tooltip-${leftName}-${topName}`}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyUp={handleKeyUp}
          onKeyDown={focuser.handleKeyDown}
          style={{ backgroundColor: color }}
        >
          {currentValue}
        </CellContent>
      </FixedCell>
    </Tooltip>
  )
}

export default Editor
