import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get, getRevisions } from 'analyses/reducers/analysis'
import * as service from './services'

export type UiState = Readonly<ReturnType<typeof service.clearUIState>>

const name = 'ui'

// Sub actions are needed to update state before the api call.
const setAnalysisIdSub = createAsyncThunk(`${name}/setAnalysisIdSub`, async (value: number) => value)

const setAnalysisId = createAsyncThunk(`${name}/setAnalysisId`, async (value: number, { dispatch }) => {
  await dispatch(setAnalysisIdSub(value))
  const result = await dispatch(get())
  if (!result.payload) {
    throw new Error('Error')
  }
})

const setOnlyBookmarksSub = createAsyncThunk(`${name}/setOnlyBookmarksSub`, async (value: boolean) => value)

const setOnlyBookmarks = createAsyncThunk(`${name}/setOnlyBookmarks`, async (value: boolean, { dispatch }) => {
  await dispatch(setOnlyBookmarksSub(value))
  await dispatch(getRevisions())
})

const slice = createSlice({
  name,
  initialState: service.clearUIState(),
  reducers: {
    clearUIState: service.clearUIState,
    setSelectedOutcomes: service.setSelectedOutcomes,
    addSelectedOutcome: service.addSelectedOutcome,
    removeSelectedOutcome: service.removeSelectedOutcome,
    openModal: service.openModal,
    toggleShowToolbar: service.toggleShowToolbar,
    setResultMapId: service.setResultMapId,
    toggleOnlyKeyScenarios: service.toggleOnlyKeyScenarios,
    toggleUncertaintyTableFixedHeight: service.toggleUncertaintyTableFixedHeight,
    toggleOnlyMarkedScenarios: service.toggleOnlyMarkedScenarios,
    setSvgFunctions: service.setSvgFunctions,
    setEditScenarioLabel: service.setEditScenarioLabel,
    editScenarioLabel: service.editScenarioLabel,
    exportPng: service.exportPng,
    exportSvg: service.exportSvg,
    resetContourZoom: service.resetContourZoom,
    setShowLoading: service.setShowLoading,
    setDimension: service.setDimension,
    setLayout: service.setLayout,
    setToolbarLayout: service.setToolbarLayout,
    setResetUncertaintyTableLayout: service.setResetUncertaintyTableLayout,
    resetUncertaintyTableLayout: service.resetUncertaintyTableLayout,
    setResetResultMapLayout: service.setResetResultMapLayout,
    resetResultMapLayout: service.resetResultMapLayout,
    setResetMainLayout: service.setResetMainLayout,
    resetMainLayout: service.resetMainLayout,
  },
  extraReducers: (builder) => {
    builder.addCase(setOnlyBookmarksSub.fulfilled, service.handleSetOnlyBookmarks)
    builder.addCase(setAnalysisIdSub.fulfilled, service.handleSetAnalysisId)
    builder.addCase(setAnalysisId.rejected, service.rejectSetAnalysisId)
  },
})

export const {
  setSelectedOutcomes,
  addSelectedOutcome,
  removeSelectedOutcome,
  openModal,
  setResultMapId,
  toggleShowToolbar,
  toggleOnlyKeyScenarios,
  setSvgFunctions,
  setEditScenarioLabel,
  exportPng,
  exportSvg,
  editScenarioLabel,
  resetContourZoom,
  setShowLoading,
  toggleOnlyMarkedScenarios,
  setDimension,
  setLayout,
  setToolbarLayout,
  setResetUncertaintyTableLayout,
  resetUncertaintyTableLayout,
  setResetMainLayout,
  resetMainLayout,
  resetResultMapLayout,
  setResetResultMapLayout,
  clearUIState,
  toggleUncertaintyTableFixedHeight,
} = slice.actions

export default slice.reducer
export { setOnlyBookmarks, setAnalysisId }
