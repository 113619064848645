/* eslint-disable no-console */
import { ApiAnalysis, IAnalysis, IAnalysisOverview, IRevision } from 'analyses/analysis.model'
import { commandRaw, fetchRaw } from '../shared/api/fetchWrapper'

const endPoint = process.env.REACT_APP_APPLICATION === 'scenario' ? 'scenario_analyses' : 'strategy_analyses'

export const getAll = async (): Promise<IAnalysisOverview[] | undefined> => {
  try {
    const analyses = await fetchRaw<IAnalysisOverview[]>({
      endpoint: endPoint,
      method: 'GET',
    })
    return analyses
  } catch (ex) {
    console.error('Caught exception while trying to fetch analyses.', ex)
    return undefined
  }
}

export const createAnalysis = async (name: string): Promise<IAnalysisOverview | undefined> => {
  try {
    const analysis = await fetchRaw<IAnalysisOverview>({
      endpoint: endPoint,
      method: 'POST',
      body: { name },
    })
    return analysis
  } catch (ex) {
    console.error('Caught exception while trying to create analysis.', ex)
    return undefined
  }
}

// Api returns an array which is difficult to use efficiently.
const mapConsistencies = (analysis: ApiAnalysis): Record<number, Record<number, string>> => {
  const map = {} as Record<number, Record<number, string>>
  analysis?.consistency_table?.forEach((item) => {
    if (!map[item.id_from]) map[item.id_from] = {}
    if (!map[item.id_to]) map[item.id_to] = {}

    map[item.id_from][item.id_to] = item.consistency
    map[item.id_to][item.id_from] = item.consistency
  })
  return map
}

const convertAnalysis = (analysis: ApiAnalysis) => ({
  ...analysis,
  consistency_table: mapConsistencies(analysis),
})

export const get = async (id: number, only_bookmarks: boolean): Promise<IAnalysis | undefined> => {
  try {
    const analysis = await fetchRaw<ApiAnalysis>({
      endpoint: `${endPoint}/${id}`,
      method: 'GET',
      body: { only_bookmarks },
    })
    return convertAnalysis(analysis)
  } catch (ex) {
    console.error(`Caught exception while trying to fetch analysis ${id}.`, ex)
    return undefined
  }
}

export const getRevisions = async (id: number, only_bookmarks: boolean): Promise<IRevision[] | undefined> => {
  try {
    const analysis = await fetchRaw<IAnalysis>({
      endpoint: `${endPoint}/${id}`,
      method: 'GET',
      body: { only_bookmarks },
    })
    return analysis.revisions
  } catch (ex) {
    console.error(`Caught exception while trying to fetch analysis ${id}.`, ex)
    return undefined
  }
}

interface RunAnalysisResponse {
  task_id: string
}

export const runAnalysis = async (id: number): Promise<string | undefined> => {
  try {
    const response = await fetchRaw<RunAnalysisResponse>({
      endpoint: `${endPoint}/${id}/${Command.RunAnalysis}`,
      method: 'PATCH',
    })
    return response.task_id
  } catch (ex) {
    console.error(`Caught exception while trying to run analysis for analysis ${id}.`, ex)
    return undefined
  }
}

type ProgressState = 'PENDING' | 'PROGRESS' | 'SUCCESS' | 'ERROR'

interface GetAnalysisProgressResponse {
  state: ProgressState
  details?: { current: number; total: number }
}

export const getAnalysisProgress = async (id: number, taskId: string): Promise<GetAnalysisProgressResponse> => {
  try {
    const response = await fetchRaw<GetAnalysisProgressResponse>({
      endpoint: `${endPoint}/${id}/${Command.GetAnalysisProgress}`,
      method: 'PATCH',
      body: { task_id: taskId },
    })
    return response
  } catch (ex) {
    console.error(`Caught exception while trying to get analysis progress for analysis ${id}.`, ex)
    return { state: 'ERROR' }
  }
}

export const doCommand = async (
  id: number,
  command: Command,
  body: Record<string, string | number>
): Promise<unknown | undefined> => {
  try {
    return await commandRaw({
      endpoint: `${endPoint}/${id}/${command}`,
      method: 'PATCH',
      body,
    })
  } catch (ex) {
    console.error(`Caught exception while trying to execute command ${command} for analysis ${id}.`, ex)
    return undefined
  }
}

export const fetchCommand = async <T>(
  id: number,
  command: Command,
  body: Record<string, string | number | boolean | number[] | string[]>
): Promise<T | undefined> => {
  try {
    return await fetchRaw<T>({
      endpoint: `${endPoint}/${id}/${command}`,
      method: 'PATCH',
      body,
    })
  } catch (ex) {
    console.error(`Caught exception while trying to execute command ${command} for analysis ${id}.`, ex)
    return undefined
  }
}

enum Command {
  ChangeOutcomeName = 'change_outcome_name',
  ChangeUncertaintyName = 'change_uncertainty_name',
  ChangeOutcomeOrder = 'change_outcome_order',
  ChangeUncertaintyOrder = 'change_uncertainty_order',
  AddOutcome = 'add_outcome',
  AddUncertainty = 'add_uncertainty',
  RemoveOutcome = 'remove_outcome',
  RemoveUncertainty = 'remove_uncertainty',
  SetConsistency = 'set_consistency',
  RunAnalysis = 'run_analysis',
  GetAnalysisProgress = 'get_analysis_progress',
  RevertToRevision = 'revert_to_revision',
  BookmarkRevision = 'bookmark_revision',
  ChangeAnalysisName = 'change_analysis_name',
  CloneAnalysis = 'clone',
  AddResultMap = 'add_result_map',
  RemoveResultMap = 'remove_result_map',
  ChangeResultMapName = 'change_result_map_name',
  SetPrimaryResultMap = 'set_primary_result_map',
  SetShownScenarios = 'set_shown_scenarios',
  MarkScenario = 'mark_scenario',
  SetContourLevels = 'set_contour_levels',
  SetColorScale = 'set_color_scale',
  SetShowSelectionDiamonds = 'set_show_selection_diamonds',
}

export const changeOutcomeName = async (analysisId: number, outcomeId: number, name: string) =>
  fetchCommand<IRevision>(analysisId, Command.ChangeOutcomeName, { id: outcomeId, name })

export const changeUncertaintyName = async (analysisId: number, uncertaintyId: number, name: string) =>
  fetchCommand<IRevision>(analysisId, Command.ChangeUncertaintyName, { id: uncertaintyId, name })

export const changeOutcomeOrder = async (analysisId: number, uncertaintyId: number, order: number[]) =>
  fetchCommand<IRevision>(analysisId, Command.ChangeOutcomeOrder, { uncertainty_id: uncertaintyId, order })

export const changeUncertaintyOrder = async (analysisId: number, order: number[]) =>
  fetchCommand<IRevision>(analysisId, Command.ChangeUncertaintyOrder, { order })

export const addOutcome = async (analysisId: number, uncertaintyId: number, name: string) =>
  fetchCommand<IRevision>(analysisId, Command.AddOutcome, { uncertainty_id: uncertaintyId, name })

export const addUncertainty = async (analysisId: number, name: string) =>
  fetchCommand<IRevision>(analysisId, Command.AddUncertainty, { name })

export const removeOutcome = async (analysisId: number, outcomeId: number) =>
  fetchCommand<IRevision>(analysisId, Command.RemoveOutcome, { id: outcomeId })

export const removeUncertainty = async (analysisId: number, uncertaintyId: number) =>
  fetchCommand<IRevision>(analysisId, Command.RemoveUncertainty, { id: uncertaintyId })

export const revertToRevision = async (analysisId: number, version: number) => {
  const analysis = await fetchCommand<ApiAnalysis>(analysisId, Command.RevertToRevision, { version })
  return analysis && convertAnalysis(analysis)
}

export const changeAnalysisName = async (analysisId: number, name: string) =>
  fetchCommand<IRevision>(analysisId, Command.ChangeAnalysisName, { name })

export const addResultMap = async (analysisId: number, name: string) =>
  fetchCommand<IRevision>(analysisId, Command.AddResultMap, { name })

export const removeResultMap = async (analysisId: number, id: number) =>
  fetchCommand<IRevision>(analysisId, Command.RemoveResultMap, { id })

export const changeResultMapName = async (analysisId: number, id: number, name: string) =>
  fetchCommand<IRevision>(analysisId, Command.ChangeResultMapName, { id, name })

export const setPrimaryResultMap = async (analysisId: number, id: number) =>
  fetchCommand<IRevision>(analysisId, Command.SetPrimaryResultMap, { id })

export const setShownScenarios = async (analysisId: number, id: number, amount: number) =>
  fetchCommand<IRevision>(analysisId, Command.SetShownScenarios, { id, amount })

export const setContourLevels = async (analysisId: number, id: number, amount: number) =>
  fetchCommand<IRevision>(analysisId, Command.SetContourLevels, { id, amount })

export const setColorScale = async (analysisId: number, id: number, min: number, max: number) =>
  fetchCommand<IRevision>(analysisId, Command.SetColorScale, { id, min, max })

export const setShowSelectionDiamonds = async (analysisId: number, id: number, value: boolean) =>
  fetchCommand<IRevision>(analysisId, Command.SetShowSelectionDiamonds, { id, value })

export const cloneAnalysis = async (analysisId: number, name: string) =>
  fetchCommand<{ clone_id: number }>(analysisId, Command.CloneAnalysis, { new_name: name })

export const bookmarkRevision = async (analysisId: number, version: number, description: string) =>
  fetchCommand<IRevision>(analysisId, Command.BookmarkRevision, { version, description })

export const setConsistency = async (
  analysisId: number,
  fromOutcomeId: number,
  toOutcomeId: number,
  consistency: string
) =>
  fetchCommand<IRevision>(analysisId, Command.SetConsistency, {
    from_id: fromOutcomeId,
    to_id: toOutcomeId,
    consistency,
  })

export const markScenario = async (analysisId: number, id: number, index: number, color: string, label: string) =>
  fetchCommand<IRevision>(analysisId, Command.MarkScenario, { id, index, color, label })
