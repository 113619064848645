import { Switch } from '@material-ui/core'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import InputSlider from 'components/InputSlider'
import ScalingList, { FullLabel } from 'components/ScalingList'
import { useTranslate } from 'translation'
import DoubleInputSlider from 'components/DoubleInputSlider'
import { IResultMap } from 'analyses/analysis.model'
import {
  setShownScenarios,
  setContourLevels,
  setPrimaryResultMap,
  setColorScale,
  setShowSelectionDiamonds,
} from '../../reducers'
import { useResultMap, useTotalScenarioCount } from '../../hooks'

/**
 * Wrapper to handle null check.
 */
const ResultMapPropertiesWrapper = () => {
  const resultMap = useResultMap()
  if (!resultMap) return null
  return <ResultMapProperties resultMap={resultMap} />
}

/**
 * Toolbar section for changing result map properties like shown scenarios, contour color and is it default.
 */
const ResultMapProperties = ({ resultMap }: { resultMap: IResultMap }) => {
  const dispatch = useDispatch()
  const t = useTranslate()
  const count = useTotalScenarioCount()

  const handleChangeShownScenarios = useCallback(
    (value: number) => {
      dispatch(setShownScenarios(resultMap.id, value))
    },
    [dispatch, resultMap.id]
  )
  const handleChangeContourLevels = useCallback(
    (value: number) => {
      dispatch(setContourLevels(resultMap.id, value))
    },
    [dispatch, resultMap.id]
  )
  const handleChangeColorScale = useCallback(
    (min: number, max: number) => {
      dispatch(setColorScale(resultMap.id, min, max))
    },
    [dispatch, resultMap.id]
  )
  const handleSetPrimary = useCallback(
    (_, checked: boolean) => {
      if (checked) dispatch(setPrimaryResultMap(resultMap.id))
    },
    [dispatch, resultMap.id]
  )
  const handleSetShowSelectedDiamonds = useCallback(() => {
    dispatch(setShowSelectionDiamonds(resultMap.id, !resultMap.show_selection_diamonds))
  }, [dispatch, resultMap.id, resultMap.show_selection_diamonds])

  return (
    <ScalingList>
      <FullLabel
        control={<Switch checked={resultMap.primary} onChange={handleSetPrimary} color='secondary' />}
        labelPlacement='start'
        label={`${t('Primary')}:`}
        labelId='result-map-primary'
      />
      <InputSlider
        id='shown_scenarios'
        label={`${t('Shown scenarios')}:`}
        onChange={handleChangeShownScenarios}
        value={resultMap.shown_scenarios}
        min={0}
        max={count}
      />
      <InputSlider
        id='contour_levels'
        label={`${t('Contour levels')}:`}
        onChange={handleChangeContourLevels}
        value={resultMap.contour_levels}
        min={0}
        max={20}
      />
      <DoubleInputSlider
        id='contour_colors'
        label={`${t('Contour colors')}:`}
        onChange={handleChangeColorScale}
        value1={resultMap.color_scale_min}
        value2={resultMap.color_scale_max}
        min={0}
        max={10}
      />
      <FullLabel
        control={
          <Switch
            checked={resultMap.show_selection_diamonds}
            onChange={handleSetShowSelectedDiamonds}
            color='secondary'
            aria-labelledby='result-map-show-diamond'
          />
        }
        labelPlacement='start'
        label={`${t('Selection diamond')}:`}
        labelId='result-map-show-diamond'
      />
    </ScalingList>
  )
}

export default ResultMapPropertiesWrapper
