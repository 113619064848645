import React, { memo, useCallback, useMemo } from 'react'
import { Divider, IconButton, ListItem, styled, Typography } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { CellFocuser, useCellFocuser } from 'components/useCellFocuser'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import { useTranslate } from 'translation'
import { useComponentRect } from 'shared/util/hooks'
import { FullLabel } from 'components/ScalingList'
import IconMarkedOutlined from '@material-ui/icons/LocalOfferOutlined'
import IconMarked from '@material-ui/icons/LocalOffer'
import IconKeyOutlined from '@material-ui/icons/VpnKeyOutlined'
import IconKey from '@material-ui/icons/VpnKey'
import theme from 'theme'
import { Container, FlexGrow, TypographyWithEllipsis } from 'components/Common'
import {
  setSelectedOutcomes,
  toggleOnlyKeyScenarios,
  editScenarioLabel,
  toggleOnlyMarkedScenarios,
} from '../../reducers'
import {
  useEnter,
  useOnlyKeyScenarios,
  useOnlyMarkedScenarios,
  useShowToolbar,
  useKeyScenarios,
  useMarkedScenarios,
  useScenarios,
  useIsSelectedScenario,
} from '../../hooks'
import { IMarkedScenario, IScenario } from '../../analysis.model'

const ScenarioList = () => {
  const scenarios = useScenarios()
  const { ref, rect } = useComponentRect<HTMLDivElement>()
  const keyScenarios = useKeyScenarios()
  const markedScenarios = useMarkedScenarios()
  const dispatch = useDispatch()
  const t = useTranslate()

  const handleSelect = useCallback(
    (index: number) => {
      dispatch(setSelectedOutcomes(scenarios[index].outcome_ids))
    },
    [dispatch, scenarios]
  )

  const visible = useShowToolbar()
  const handleEdit = useCallback(() => {
    dispatch(editScenarioLabel())
  }, [dispatch])

  const { itemHeight, headerHeight } = theme.shape.scenarioList

  const listHeight = Math.min((rect?.height ?? 0) - headerHeight, itemHeight * scenarios.length)

  const itemCount = scenarios.length
  const focuser = useCellFocuser(1, itemCount)

  const renderRow = useCallback(
    (props: ListChildComponentProps) => {
      const { index, style } = props

      const item = scenarios[index]
      return (
        <MemoizedItem
          style={style}
          focuser={focuser[0][index]}
          key={String(item.id)}
          onSelect={handleSelect}
          onEdit={handleEdit}
          item={item}
          index={index}
          keyScenario={keyScenarios.includes(item.id)}
          marking={markedScenarios[item.id]}
        />
      )
    },
    // List height is needed to render properly.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listHeight, handleEdit, handleSelect, scenarios, focuser, keyScenarios, markedScenarios]
  )

  if (!visible) return null

  return (
    <Container ref={ref}>
      <Header
        className='scenariolist-header'
        nonInteractive
        control={<RenderFilter />}
        label={`${t('Filter')}:`}
        labelId='scenario-list-filter'
      />
      <Divider />
      <FixedSizeList
        height={listHeight}
        width='100%'
        itemSize={itemHeight}
        itemCount={itemCount}
        // Must be styled with style so that the component works correctly.
        style={{
          backgroundColor: theme.palette.toolbar.list,
          overflowY: 'scroll',
        }}
      >
        {renderRow}
      </FixedSizeList>
    </Container>
  )
}

const Header = styled(FullLabel)({
  height: theme.shape.scenarioList.headerHeight,
  marginLeft: theme.shape.listItemPadding,
})

interface ItemProps {
  onSelect: (index: number) => void
  onEdit: (index: number) => void
  index: number
  item: IScenario
  keyScenario: boolean
  marking: IMarkedScenario | undefined
  focuser: CellFocuser
  style: React.CSSProperties
}

const format = new Intl.NumberFormat(undefined, { maximumSignificantDigits: 2 })

const Item = ({ onSelect, onEdit, item, index, marking, focuser, style, keyScenario }: ItemProps) => {
  // This must be here instead of parent component to prevent renderRow updating every selection which removes focus.
  const selected = useIsSelectedScenario(index)

  const handleFocus = useCallback(() => {
    onSelect(index)
  }, [onSelect, index])
  const handleDoubleClick = useCallback(() => {
    onSelect(index)
    onEdit(index)
  }, [onSelect, onEdit, index])
  const handleEnter = useEnter(handleDoubleClick)
  const t = useTranslate()

  const styleWithColor = useMemo(() => ({ ...style, borderLeftColor: marking?.color ?? undefined }), [style, marking])
  return (
    <StyledListItem
      style={styleWithColor}
      selected={selected}
      onDoubleClick={handleDoubleClick}
      onKeyDown={focuser.handleKeyDown}
      onKeyUp={handleEnter}
      onFocus={handleFocus}
      ref={focuser.ref}
      button
    >
      <TypographyWithEllipsis variant='body1'>
        {marking?.label || `${t('Scenario')} ${item.id + 1}`}
      </TypographyWithEllipsis>
      <FlexGrow />
      {keyScenario && <IconKey color='secondary' />}
      <ItemNumber variant='h6'>{format.format(item.consistency)}</ItemNumber>
    </StyledListItem>
  )
}

const MemoizedItem = memo(Item)

const ItemNumber = styled(Typography)({
  flexBasis: 50,
  textAlign: 'right',
})

const StyledListItem = styled(ListItem)({
  display: 'flex',
  width: '100%',
  height: 50,
  paddingLeft: theme.shape.scenarioList.itemPadding,
  borderLeft: `solid ${theme.shape.scenarioList.borderWidth}px rgba(0, 0, 0, 0)`,
  '&:hover': {
    backgroundColor: theme.palette.hover,
  },
  borderBottom: `solid 1px ${theme.palette.divider}`,
})

const RenderFilter = () => {
  const onlyMarkedScenarios = useOnlyMarkedScenarios()
  const onlyKeyScenarios = useOnlyKeyScenarios()
  const dispatch = useDispatch()

  const handleChangeOnlyMarkedScenarios = useCallback(() => {
    dispatch(toggleOnlyMarkedScenarios())
  }, [dispatch])

  const handleChangeOnlyKeyScenarios = useCallback(() => {
    dispatch(toggleOnlyKeyScenarios())
  }, [dispatch])

  return (
    <>
      <IconButton onClick={handleChangeOnlyMarkedScenarios} color='secondary'>
        {onlyMarkedScenarios ? <IconMarked /> : <IconMarkedOutlined />}
      </IconButton>
      <IconButton style={{ paddingLeft: 0 }} onClick={handleChangeOnlyKeyScenarios} color='secondary'>
        {onlyKeyScenarios ? <IconKey /> : <IconKeyOutlined />}
      </IconButton>
    </>
  )
}

export default ScenarioList
