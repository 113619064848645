enum StorageType {
  SESSION,
  LOCAL,
}

const StoragePrefix = 'monad-' // TODO - how about version?

/**
 * Get either localStorage or sessionStorage
 * @param type storage type
 */
export const getStorage = (type: StorageType) => {
  if (type === StorageType.SESSION) {
    return window.sessionStorage
  }
  return window.localStorage
}
/**
 * Set an item into storage
 * @param type storage type
 * @param key key to set (NOTE - that we set tool specific prefix to this)
 * @param value value to set
 */
const setItem = (type: StorageType) => (key: string, value: unknown) => {
  getStorage(type).setItem(StoragePrefix + key, JSON.stringify(value))
}
/**
 * Get an item from storage
 * @param type storage type
 * @param key key to get (NOTE - that we set tool specific prefix to this)
 * @param defaultVal value to return if key doesnt exist
 */
const getItem = (type: StorageType) => (key: string, defaultVal?: unknown) => {
  const val = getStorage(type).getItem(StoragePrefix + key)
  if (!val || val === 'undefined') return defaultVal
  try {
    return JSON.parse(val)
  } catch (e) {
    return val
  }
}
/**
 * Remove item from storage
 * @param type storage type
 * @param key key to remove
 */
const removeItem = (type: StorageType) => (key: string) => {
  getStorage(type).removeItem(key)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GetItemType = (key: string, defaultVal?: unknown) => any
export type SetItemType = (key: string, value: unknown) => void
export type RemoveItemType = (key: string) => void

export interface IStorageAPI {
  get: GetItemType
  set: SetItemType
  remove: RemoveItemType
}

export interface IStorageService {
  session: IStorageAPI
  local: IStorageAPI
}

const Storage: IStorageService = {
  session: {
    get: getItem(StorageType.SESSION),
    set: setItem(StorageType.SESSION),
    remove: removeItem(StorageType.SESSION),
  },
  local: {
    get: getItem(StorageType.LOCAL),
    set: setItem(StorageType.LOCAL),
    remove: removeItem(StorageType.LOCAL),
  },
}

export { Storage }
