import React, { cloneElement, PropsWithChildren, ReactElement, useCallback, useState } from 'react'
import Popover from '@material-ui/core/Popover'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

interface Props {
  trigger: ReactElement
  style?: CSSProperties
  onClose: () => void
}

/**
 * Displays content on a popover.
 */
const SimplePopover = ({ trigger, style, onClose, children }: PropsWithChildren<Props>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
    onClose()
  }, [onClose])

  return (
    <>
      {cloneElement(trigger, { onClick: handleClick })}
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        style={style}
      >
        {children}
      </Popover>
    </>
  )
}

export default SimplePopover
