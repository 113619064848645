import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import type { IRootState } from 'config/state'
import { useTranslate } from 'translation'
import { cloneAnalysis, createAnalysis } from '../analysis.api'
import { addSelectedOutcome, removeSelectedOutcome, setSelectedOutcomes, setShowLoading } from '../reducers'
import {
  getCanChangeResult,
  getContourWidth,
  getHeight,
  getLeftSideWidth,
  getResultMap,
  getRightSideWidth,
  getHeightWithNavigation,
  getWidth,
  getOnlyMarkedScenarios,
  getOnlyKeyScenarios,
} from '../selectors'

export const useOpenAnalysis = (callback?: () => void) => {
  const history = useHistory()
  return useCallback(
    (id: number) => {
      history.push(`/analysis/${String(id)}`)
      if (callback) callback()
    },
    [history, callback]
  )
}

export const useCreateAnalysis = () => {
  const openAnalysis = useOpenAnalysis()
  const dispatch = useDispatch()
  const t = useTranslate()
  return useCallback(() => {
    dispatch(setShowLoading(true))
    createAnalysis(t('Empty Analysis')).then((result) => {
      dispatch(setShowLoading(false))
      if (!result) return
      openAnalysis(result.id)
    })
  }, [openAnalysis, t, dispatch])
}

export const useCloneAnalysis = (id: number, name: string) => {
  const t = useTranslate()
  const dispatch = useDispatch()
  const openAnalysis = useOpenAnalysis()
  return useCallback(() => {
    dispatch(setShowLoading(true))
    cloneAnalysis(Number(id), `${t('Copy of')} ${name}`).then((result) => {
      dispatch(setShowLoading(false))
      if (!result) return
      openAnalysis(result.clone_id)
    })
  }, [dispatch, openAnalysis, t, name, id])
}

export const useId = () => {
  const id = Number(useParams<{ id: string }>().id)
  if (Number.isNaN(id)) return undefined
  return id
}

export const useOnlyMarkedScenarios = () => useSelector(getOnlyMarkedScenarios)
export const useOnlyKeyScenarios = () => useSelector(getOnlyKeyScenarios)
export const useModalOpen = () => useSelector((state: IRootState) => state.ui.modalOpen)
export const useShowLoading = () => useSelector((state: IRootState) => state.ui.showLoading)
export const useShowToolbar = () =>
  useSelector((state: IRootState) => state.ui.showToolbar && getCanChangeResult(state) && getResultMap(state))
export const useUncertaintyTableFixedHeight = () =>
  useSelector((state: IRootState) => state.ui.uncertaintyTableFixedHeight)

export const useSelectByOutcome = () => {
  const dispatch = useDispatch()
  // Logic implemented at reducer to prevent the function reference changing all the time.
  const selectByOutcome = useCallback(
    (id: number, selected: boolean, included: boolean) => {
      if (selected) {
        dispatch(removeSelectedOutcome(id))
      } else if (included) {
        dispatch(addSelectedOutcome(id))
      } else {
        dispatch(setSelectedOutcomes([id]))
      }
    },
    [dispatch]
  )

  return selectByOutcome
}

export const useEnter = (callback: () => void) =>
  useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Enter') {
        callback()
        event.stopPropagation()
      }
    },
    [callback]
  )

export const useLeftSideWidth = () => useSelector((state: IRootState) => getLeftSideWidth(state.ui))

export const useRightSideWidth = () => useSelector((state: IRootState) => getRightSideWidth(state.ui))

export const useWidth = () => useSelector((state: IRootState) => getWidth(state.ui))
export const useHeightWithNavigation = () => useSelector((state: IRootState) => getHeightWithNavigation(state.ui))
export const useHeight = () => useSelector((state: IRootState) => getHeight(state.ui))

export const useContourWidth = () => useSelector((state: IRootState) => getContourWidth(state.ui))
