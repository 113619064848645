export type Operation =
  | 'add_outcome'
  | 'add_uncertainty'
  | 'change_outcome_name'
  | 'change_uncertainty_name'
  | 'change_outcome_order'
  | 'change_uncertainty_order'
  | 'archive_outcome'
  | 'restore_outcome'
  | 'archive_uncertainty'
  | 'restore_uncertainty'
  | 'set_consistency'
  | 'change_analysis_name'
  | 'add_result_map'
  | 'archive_result_map'
  | 'restore_result_map'
  | 'change_result_map_name'
  | 'set_primary_result_map'
  | 'set_shown_scenarios'
  | 'set_contour_levels'
  | 'set_color_scale'
  | 'mark_scenario'

interface Permissions {
  change?: boolean
  changeresult?: boolean
  delete?: boolean
  view?: boolean
}

export type IAnalysisOverview = {
  id: number
  name: string
  description: string
  current_version: number
  groups: string[]
  revisions: IRevision[]
  permissions: Permissions
}

export interface IScenario {
  id: number
  consistency: number
  outcome_ids: number[]
  x: number
  y: number
}

export interface IMarkedScenario {
  index: number
  color: string
  label: string
}

export interface IResultMap {
  id: number
  scenarios: IMarkedScenario[]
  primary: boolean
  name: string
  shown_scenarios: number
  show_selection_diamonds: boolean
  contour_levels: number
  color_scale_min: number
  color_scale_max: number
}

export interface IResultData {
  height_grid: number[][]
  limit_x: [number, number]
  limit_y: [number, number]
  scenarios: IScenario[]
  key_scenarios: number[]
}

export interface IResult {
  data: IResultData
  result_maps: IResultMap[]
  version: number
}

export interface IRevision {
  changed_by: string
  changed_at: string
  version: number
  parent: number
  operation: Operation
  description: string
  data: Record<string, unknown>
  result_version: number
}

export interface IOutcome {
  id: number
  name: string
}

export interface IUncertainty {
  id: number
  name: string
  outcomes: IOutcome[]
}

export type IConsistencyTable = Record<number, Record<number, string>>

export interface IAnalysis extends IAnalysisOverview {
  consistency_table: IConsistencyTable
  futures: IUncertainty[]
  result: IResult | undefined
  revisions: IRevision[]
}

export interface ApiAnalysis {
  id: number
  name: string
  description: string
  consistency_table: {
    consistency: string
    id_from: number
    id_to: number
  }[]
  futures: IUncertainty[]
  result: IResult | undefined
  current_version: number
  revisions: IRevision[]
  groups: string[]
  permissions: Permissions
}

export const defaultObject = {}
export const defaultArray = [] as never[]
export const defaultRevision = {
  id: -1,
  description: '',
  version: 0,
  parent: 0,
  changed_at: '',
  changed_by: '',
  data: {},
  rollback_data: {},
  operation: '' as Operation,
  result_version: 0,
} as IRevision

export const defaultResultMap = {
  color_scale_max: 8,
  color_scale_min: 5,
  contour_levels: 15,
  id: -1,
  name: '',
  primary: false,
  scenarios: defaultArray,
  show_selection_diamonds: false,
  shown_scenarios: 250,
} as IResultMap
