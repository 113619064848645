/* eslint-disable @typescript-eslint/no-explicit-any */
import { Draft, PayloadAction } from '@reduxjs/toolkit'
import AppRoutes from 'appRoutes'
import type { UiState } from '../ui'

export type ExportPng = (name: string, width: number, height: number) => void
export type ExportSvg = (name: string) => void
export type D = Draft<UiState>

export const initialUiState = {
  analysisId: undefined as number | undefined,
  onlyBookmarks: false,
  onlyKeyScenarios: false,
  onlyMarkedScenarios: false,
  resultMapId: undefined as number | undefined,
  selectedOutcomes: [] as number[],
  showToolbar: true,
  // Editing a scenario should automatically focus the label input.
  // This is tricky because the toolbar may not be open.
  // Use a temporary variable to detect this situation.
  autoFocusToolbar: false,
  modalOpen: false,
  showLoading: false,
  uncertaintyTableFixedHeight: true,
  // Responsiveness requires special handling because some features need pixels.
  // For example grid layout, virtual scenarios list and exporting png.
  // Easier to handle everything here and also helps performance by separating data and renders.
  // For example now resizing doesn't update exportPng (and possibly render things) because there is no dependency.
  dimensions: {
    width: 0,
    height: 0,
    layout: 0.5,
    toolbar: 0,
  },
  // Callbacks are stored to decouple components which don't have a clear parent-child relationship.
  // This removes lots of low value "delivery" code and makes refactoring easier.
  callbacks: {
    editScenarioLabel: undefined as undefined | (() => void),
    exportPng: undefined as undefined | ExportPng,
    exportSvg: undefined as undefined | ExportSvg,
    resetContourZoom: undefined as undefined | (() => void),
    resetMainLayout: undefined as undefined | (() => void),
    resetUncertaintyTableLayout: undefined as undefined | (() => void),
    resetResultMapLayout: undefined as undefined | (() => void),
  },
}

export const clearUIState = () => initialUiState

export const setSelectedOutcomes = (draft: D, { payload }: PayloadAction<number[]>) => {
  draft.selectedOutcomes = payload
}

export const addSelectedOutcome = (draft: D, { payload }: PayloadAction<number>) => {
  draft.selectedOutcomes.push(payload)
}

export const removeSelectedOutcome = (draft: D, { payload }: PayloadAction<number>) => {
  draft.selectedOutcomes = draft.selectedOutcomes.filter((id) => id !== payload)
}

export const openModal = (draft: D, { payload }: PayloadAction<boolean>) => {
  draft.modalOpen = payload
}

export const toggleShowToolbar = (draft: D) => {
  draft.showToolbar = !draft.showToolbar
}

export const setResultMapId = (draft: D, { payload }: PayloadAction<number>) => {
  draft.resultMapId = payload
}

export const toggleOnlyKeyScenarios = (draft: D) => {
  draft.onlyKeyScenarios = !draft.onlyKeyScenarios
}

export const toggleUncertaintyTableFixedHeight = (draft: D) => {
  draft.uncertaintyTableFixedHeight = !draft.uncertaintyTableFixedHeight
}

export const toggleOnlyMarkedScenarios = (draft: D) => {
  draft.onlyMarkedScenarios = !draft.onlyMarkedScenarios
}

export const setShowLoading = (draft: D, { payload }: PayloadAction<boolean>) => {
  draft.showLoading = payload
}

export const setDimension = (draft: D, { payload }: PayloadAction<DOMRect | undefined>) => {
  draft.dimensions.width = payload?.width ?? 0
  draft.dimensions.height = payload?.height ?? 0
}

export const setLayout = (draft: D, { payload }: PayloadAction<number>) => {
  draft.dimensions.layout = payload
}

export const setToolbarLayout = (draft: D, { payload }: PayloadAction<number>) => {
  draft.dimensions.toolbar = payload
}

export const handleSetOnlyBookmarks = (draft: D, { payload }: PayloadAction<boolean>) => {
  draft.onlyBookmarks = payload
}

export const rejectSetAnalysisId = (draft: D) => {
  draft.analysisId = undefined
  draft.selectedOutcomes = []
  draft.resultMapId = undefined
  window.location.pathname = AppRoutes.Base
}

export const handleSetAnalysisId = (draft: D, { payload }: PayloadAction<number>) => {
  draft.analysisId = payload
  draft.selectedOutcomes = []
  draft.resultMapId = undefined
}
