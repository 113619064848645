import { Divider, makeStyles } from '@material-ui/core'
import React, { useCallback } from 'react'
import theme from 'theme'
import { useDragging } from './useDragging'

const useStyles = makeStyles({
  divider: {
    cursor: 'ew-resize',
    width: theme.shape.divider,
    zIndex: theme.zIndex.divider,
  },
})

interface Props {
  onMove: (y: number) => void
}

/**
 * Adds a divider that allows resizing the width.
 * This causes lots of renders so the parent component must have a good performance.
 */
const VerticalDivider = ({ onMove }: Props) => {
  const classes = useStyles()
  const handleMove = useCallback((x: number) => onMove(x), [onMove])
  const handleMouseDown = useDragging(handleMove)
  return <Divider onMouseDown={handleMouseDown} className={classes.divider} flexItem orientation='vertical' />
}

export default VerticalDivider
