import { findLast, find, range } from 'lodash-es'
import { useMemo } from 'react'

type Elem = HTMLDivElement | null
type Ref = React.MutableRefObject<Elem>

/**
 * Helps adding a keyboard navigation to a table-like UI.
 *
 * Returns a 2D list of cell focusers [column, row].
 * Each cell focuser has a keydown handler and a ref for the cell.
 *
 * @param columns Amount of columns.
 * @param rows Amount of rows.
 */
export const useCellFocuser = (columns: number, rows: number, withoutTimeout = false) => {
  const refs = useMemo<Ref[][]>(
    () => new Array(columns).fill(null).map(() => new Array(rows).fill(null).map(() => ({ current: null }))),
    [columns, rows]
  )

  const focusers = useMemo(() => {
    const focus = (ref: Ref | undefined) => {
      if (ref) {
        // Using timeout triggers onFocus events but makes testing more tricky.
        if (withoutTimeout) {
          ref.current?.focus()
        } else {
          setTimeout(() => ref.current?.focus(), 0)
        }
      }
    }

    const focusLeftCell = (column: number, row: number) => {
      const ref = findLast(refs, (item) => item[row].current, column - 1) as Ref[] | undefined
      if (ref) focus(ref[row])
      // findLast seems to overflow automatically.
    }

    const focusRightCell = (column: number, row: number) => {
      const ref = find(refs, (item) => item[row].current, column + 1) as Ref[] | undefined
      if (ref) focus(ref[row])
      else focus(refs[0][row])
    }

    const focusUpCell = (column: number, row: number) => {
      const ref = findLast(refs[column], (item) => item.current, row - 1) as Ref | undefined
      focus(ref)
      // findLast seems to overflow automatically.
    }

    const focusDownCell = (column: number, row: number) => {
      const ref = find(refs[column], (item) => item.current, row + 1) as Ref | undefined
      if (ref) focus(ref)
      else focus(refs[column][0])
    }

    const createFocuser = (column: number, row: number) => ({
      handleKeyDown: ({ key, ctrlKey }: React.KeyboardEvent<HTMLElement>) => {
        if (key === 'ArrowDown' && !ctrlKey) focusDownCell(column, row)
        if (key === 'ArrowLeft' && !ctrlKey) focusLeftCell(column, row)
        if (key === 'ArrowRight' && !ctrlKey) focusRightCell(column, row)
        if (key === 'ArrowUp' && !ctrlKey) focusUpCell(column, row)
      },
      ref: refs[column][row],
    })

    return range(columns).map((column) => range(rows).map((row) => createFocuser(column, row)))
  }, [columns, rows, withoutTimeout, refs])

  return focusers
}

export type Focuser = ReturnType<typeof useCellFocuser>
export type CellFocuser = Focuser[0][0]
