import { Divider } from '@material-ui/core'
import React from 'react'
import { CenteredDiv } from 'components/Common'
import { useTranslate } from 'translation'
import { useScenarioCount, useTotalScenarioCount } from '../../hooks'

const ScenarioCount = () => {
  const total = useTotalScenarioCount()
  const count = useScenarioCount()
  const t = useTranslate()
  return (
    <>
      <Divider />
      <CenteredDiv>
        {t('Showing scenarios', {
          visible: count.toLocaleString(),
          total: total.toLocaleString(),
        })}
      </CenteredDiv>
    </>
  )
}

export default ScenarioCount
