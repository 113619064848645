import { Typography } from '@material-ui/core'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { CenteredButton } from 'components/Common'
import ScalingList from 'components/ScalingList'
import { useTranslate } from 'translation'
import { exportPng, exportSvg } from '../../reducers'
import { useName, useResultMapName } from '../../hooks'

/**
 * Toolbar section for exporting contour image.
 * Actual logic is handled inside the contour code.
 */
const ExportTools = () => {
  const dispatch = useDispatch()
  const analysisName = useName()
  const resultName = useResultMapName()
  const name = `${analysisName}_${resultName}`
  const t = useTranslate()

  const handleExportPng = useCallback(() => {
    dispatch(exportPng(name))
  }, [dispatch, name])

  const handleExportSvg = useCallback(() => {
    dispatch(exportSvg(name))
  }, [dispatch, name])

  return (
    <ScalingList size='small'>
      <CenteredButton onClick={handleExportPng} color='secondary' aria-label={`${t('Export')} PNG`}>
        <Typography variant='h5'>PNG</Typography>
      </CenteredButton>
      <CenteredButton onClick={handleExportSvg} color='secondary' aria-label={`${t('Export')} SVG`}>
        <Typography variant='h5'>SVG</Typography>
      </CenteredButton>
    </ScalingList>
  )
}

export default ExportTools
