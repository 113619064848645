import React, { forwardRef, memo, useCallback, useMemo, useRef, useState } from 'react'
import { makeStyles, styled, Theme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import VerticalDivider from 'components/VerticalDivider'
import HorizontalDivider from 'components/HorizontalDivider'
import { flatten, sum } from 'lodash-es'
import { IOutcome, IUncertainty } from 'analyses/analysis.model'
import { useDispatch } from 'react-redux'
import { CellFocuser, useCellFocuser } from 'components/useCellFocuser'
import { useScrollPosition } from 'shared/util/hooks'
import theme from 'theme'
import { useTranslate } from 'translation'
import { formatOutcome, formatUncertainty } from 'analyses/utils'
import { setConsistency, setSelectedOutcomes } from '../reducers'
import { useConsistencyTable, useUncertainties, useCanChangeModel } from '../hooks'
import Editor from './Editor'
import { Cell, LeftCell, LeftHeaderCell, TopCell, TopHeaderCell } from './Cells'

type OutcomeWithIndex = IOutcome & { index: number; uncertaintyIndex: number }

const useStyles = makeStyles<Theme, { width: number; height: number }>(() => ({
  left: {
    width: ({ width }) => width,
    minWidth: theme.shape.consistencyTable.cellDimension,
  },
  horizontalMiddle: {
    width: ({ width }) =>
      `calc(100% - ${theme.shape.divider}px - ${width}px - ${theme.shape.consistencyTable.cellDimension}px)`,
  },
  right: {
    width: theme.shape.consistencyTable.cellDimension,
  },
  container: {
    display: 'flex',
    overflow: 'hidden',
    '&>div': {
      overflow: 'hidden',
    },
  },
  top: {
    height: ({ height }) => height,
    minHeight: 2 * theme.shape.consistencyTable.cellDimension,
  },
  verticalMiddle: {
    height: ({ height }) =>
      `calc(100% - ${theme.shape.divider}px - ${height}px - ${theme.shape.consistencyTable.cellDimension}px)`,
  },
  bottom: {
    height: theme.shape.consistencyTable.cellDimension,
  },
  overflow: {
    overflow: 'auto!important',
  },
}))

const useTableStyles = makeStyles({
  verticalHeader: {
    // Makes the table size fixed instead of stretching based on available space.
    height: 'initial',
    tableLayout: 'fixed',
    borderCollapse: 'initial',
  },
  horizontalHeader: {
    // Makes the table size fixed instead of stretching based on available space.
    width: 'initial',
    tableLayout: 'fixed',
    borderCollapse: 'initial',
  },
  consistencyTable: {
    // Makes the table size fixed instead of stretching based on available space.
    width: 'initial',
    height: 'initial',
    tableLayout: 'fixed',
    borderCollapse: 'initial',
  },
})

const useLeftUncertainties = () => {
  const uncertainties = useUncertainties()
  return useMemo(() => uncertainties.slice(0, -1), [uncertainties])
}

const useTopUncertainties = () => {
  const uncertainties = useUncertainties()
  return useMemo(() => uncertainties.slice(1).reverse(), [uncertainties])
}

/**
 * Gets outcomes of a given uncertainties with a space between different uncertainties.
 * Also adds outcome index and uncertainty index to the outcome to simplify other code.
 */
const useOutcomesWithSpaceAndIndex = (uncertainties: IUncertainty[]): (OutcomeWithIndex | null)[] =>
  useMemo(
    () =>
      flatten(
        uncertainties.map((item, uncertaintyIndex) => [
          ...item.outcomes.map((outcome, index) => ({
            ...outcome,
            uncertaintyIndex: uncertainties.length - uncertaintyIndex,
            index,
          })),
          null,
        ])
      ),
    [uncertainties]
  )

/**
 * Top header as a separate component to support resizing and custom scrolling.
 */
const TopHeader = ({ height, scroll }: { height: number; scroll: number }) => {
  const uncertainties = useTopUncertainties()
  const outcomes = useOutcomesWithSpaceAndIndex(uncertainties)
  const classes = useTableStyles()
  const t = useTranslate()

  const cappedHeight = Math.max(theme.shape.consistencyTable.cellDimension, height)

  return (
    <Table
      aria-label={t('Consistency top header')}
      id='consistencytable-topheader'
      className={classes.horizontalHeader}
      style={{ marginLeft: scroll }}
    >
      <TableHead>
        <TableRow>
          {uncertainties.map((uncertainty, uncertaintyIndex) => (
            <TopHeaderCell key={uncertainty.id} colSpan={uncertainty.outcomes.length + 1}>
              {formatUncertainty(uncertainty, uncertainties.length - uncertaintyIndex)}
            </TopHeaderCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          {outcomes.map((outcome, index) =>
            outcome ? (
              <TopCell key={getKey(outcome, index)} height={cappedHeight}>
                {formatOutcomeWithIndex(outcome)}
              </TopCell>
            ) : (
              <TopCell key={getKey(outcome, index)} height={cappedHeight} />
            )
          )}
        </TableRow>
      </TableBody>
    </Table>
  )
}

/**
 * Left header as a separate component to support resizing and custom scrolling.
 */
const LeftHeader = ({ scroll }: { scroll: number }) => {
  const uncertainties = useLeftUncertainties()
  const classes = useTableStyles()
  const t = useTranslate()

  return (
    <div style={{ marginTop: scroll }} id='consistencytable-leftheader'>
      {uncertainties.map((uncertainty, uncertaintyIndex) => (
        <Table key={uncertainty.id} className={classes.verticalHeader} aria-label={t('Consistency left header')}>
          <TableHead>
            <TableRow>
              <LeftHeaderCell>{formatUncertainty(uncertainty, uncertaintyIndex)}</LeftHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uncertainty.outcomes.map((outcome, outcomeIndex) => (
              <TableRow key={outcome.id}>
                <LeftCell>{formatOutcome(outcome, outcomeIndex, uncertaintyIndex)}</LeftCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ))}
    </div>
  )
}

const formatOutcomeWithIndex = (outcome?: OutcomeWithIndex) =>
  outcome ? formatOutcome(outcome, outcome.index, outcome.uncertaintyIndex) : ''
const getKey = (outcome: IOutcome | null, index: number) => outcome?.id ?? `_${String(index)}`

/**
 * Table of consistency editors which allow changing the values.
 */
// eslint-disable-next-line react/display-name
const Consistencies = forwardRef<HTMLTableElement, unknown>((_, ref) => {
  const topUncertainties = useTopUncertainties()
  const leftUncertainties = useLeftUncertainties()

  const outcomes = useOutcomesWithSpaceAndIndex(topUncertainties)
  const rows = sum(leftUncertainties.map((item) => item.outcomes.length))
  const focusers = useCellFocuser(outcomes.length, rows)
  let row = -1
  const classes = useTableStyles()
  const consistencies = useConsistencyTable()
  const canChange = useCanChangeModel()

  const dispatch = useDispatch()
  const t = useTranslate()

  const handleChange = useCallback(
    (leftId, topId, value: string) => {
      dispatch(setConsistency(leftId, topId, value))
    },
    [dispatch]
  )

  const handleSelect = useCallback(
    (leftId, topId) => {
      dispatch(setSelectedOutcomes([leftId, topId]))
    },
    [dispatch]
  )
  return (
    <Table
      ref={ref}
      aria-label={t('Consistency table')}
      id='consistencytable-consistencytable'
      className={classes.consistencyTable}
    >
      <TableBody>
        {leftUncertainties.map((uncertainty, uncertaintyIndex) => (
          <React.Fragment key={uncertainty.id}>
            <TableRow>
              {outcomes.map((topOutcome, index) => (
                <Cell key={getKey(topOutcome, index)} />
              ))}
            </TableRow>
            {uncertainty.outcomes.map((leftOutcome, leftOutcomeIndex) => {
              row += 1
              return (
                <TableRow key={leftOutcome.id}>
                  {outcomes.map((topOutcome, column) => {
                    const key = `${getKey(topOutcome, column)}_${getKey(leftOutcome, 0)}`
                    if (!topOutcome || topOutcome.uncertaintyIndex <= uncertaintyIndex) return <Cell key={key} />
                    const leftName = formatOutcome(leftOutcome, leftOutcomeIndex, uncertaintyIndex)
                    const topName = formatOutcomeWithIndex(topOutcome)
                    const value = consistencies[leftOutcome.id]?.[topOutcome.id] || '0'
                    return (
                      <MemoizedEditor
                        key={key}
                        topName={topName}
                        leftName={leftName}
                        topId={topOutcome.id}
                        leftId={leftOutcome.id}
                        focuser={focusers[column][row]}
                        consistency={value}
                        onChange={canChange ? handleChange : undefined}
                        onSelect={handleSelect}
                      />
                    )
                  })}
                </TableRow>
              )
            })}
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  )
})

interface EditorProps {
  topId: number
  leftId: number
  topName: string
  leftName: string
  focuser: CellFocuser
  consistency: string
  onSelect: (left: number, right: number) => void
  onChange?: (left: number, right: number, value: string) => void
}

/**
 * Wraps the editor for memoization. This probably could be merged with Editor component because nothing else probably won't use it.
 */
const EditorWrapper = ({ topName, leftName, topId, leftId, focuser, consistency, onSelect, onChange }: EditorProps) => {
  const handleChange = useCallback(
    (value: string) => {
      if (onChange) onChange(leftId, topId, value)
    },
    [onChange, leftId, topId]
  )
  const handleSelect = useCallback(() => {
    onSelect(leftId, topId)
  }, [onSelect, leftId, topId])

  return (
    <Editor
      value={consistency}
      leftName={leftName}
      topName={topName}
      onSelect={handleSelect}
      onChange={onChange && handleChange}
      focuser={focuser}
    />
  )
}

/**
 * Calculates total consistency for outcomes of given uncertainties.
 * The sum is the actual total amount. All pairs are considered, not just the ones shown on the UI.
 * So the numbers won't match what on the UI.
 */
const useSums = (uncertainties: IUncertainty[]) => {
  const consistencies = useConsistencyTable()
  const outcomes = useOutcomesWithSpaceAndIndex(uncertainties)
  const sums = useMemo(
    () =>
      outcomes.map((outcome) =>
        outcome
          ? {
              outcome,
              sum: sum(
                Object.values(consistencies[outcome.id] ?? {}).map((value) =>
                  Number.isNaN(Number(value)) ? 0 : Number(value)
                )
              ),
            }
          : null
      ),
    [consistencies, outcomes]
  )
  return sums
}

/**
 * Calculates the total consistency for every outcome row.
 */
const SumRow = ({ scroll }: { scroll: number }) => {
  const uncertainties = useTopUncertainties()
  const sums = useSums(uncertainties)
  const classes = useTableStyles()
  const t = useTranslate()

  return (
    <Table
      aria-label={t('Total consistency')}
      id='consistencytable-sumrow'
      className={classes.horizontalHeader}
      style={{ marginLeft: scroll }}
    >
      <TableBody>
        <TableRow>
          {sums.map((value, index) => (
            <Cell key={String(index)} aria-label={formatOutcomeWithIndex(value?.outcome)}>
              {value?.sum ?? ''}
            </Cell>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  )
}

/**
 * Calculates the total consistency for every outcome column.
 */
const SumColumn = ({ scroll }: { scroll: number }) => {
  const uncertainties = useLeftUncertainties()
  const sums = useSums(uncertainties)
  const classes = useTableStyles()
  const t = useTranslate()

  return (
    <Table
      aria-label={t('Total consistency')}
      id='consistencytable-sumcolumn'
      className={classes.verticalHeader}
      style={{ marginTop: scroll }}
    >
      <TableBody>
        <TableRow>
          <Cell />
        </TableRow>
        {sums.map((value, index) => (
          <TableRow key={String(index)} aria-label={formatOutcomeWithIndex(value?.outcome)}>
            <Cell>{value?.sum ?? ''}</Cell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

/**
 * Table-like element to change consistency values. Containts top/left headers and bottom/right sum footers. Headers can be resized.
 */
const ConsistencyTable = () => {
  const [width, setWidth] = useState(250)
  const [height, setHeight] = useState(250)
  const classes = useStyles({ width, height })
  const parent = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLTableElement>(null)
  const scroll = useScrollPosition(ref, parent, 0)

  return (
    <Container>
      <div className={`${classes.container} ${classes.top}`}>
        <div className={classes.left} />
        <VerticalDivider onMove={setWidth} />
        <TableContainer className={classes.horizontalMiddle}>
          <MemoizedTopHeader height={height} scroll={scroll.x} />
        </TableContainer>
      </div>
      <HorizontalDivider onMove={setHeight} />
      <div className={`${classes.container} ${classes.verticalMiddle}`}>
        <TableContainer className={classes.left}>
          <MemoizedLeftHeader scroll={scroll.y} />
        </TableContainer>
        <VerticalDivider onMove={setWidth} />
        <TableContainer ref={parent} className={`${classes.horizontalMiddle} ${classes.overflow}`}>
          <MemoizedConsistencies ref={ref} />
        </TableContainer>
        <TableContainer className={classes.right}>
          <MemoizedSumColumn scroll={scroll.y} />
        </TableContainer>
      </div>
      <div className={`${classes.container}`}>
        <div className={classes.left} />
        <VerticalDivider onMove={setWidth} />
        <TableContainer className={`${classes.horizontalMiddle} ${classes.bottom}`}>
          <MemoizedSumRow scroll={scroll.x} />
        </TableContainer>
      </div>
    </Container>
  )
}

const Container = styled('div')({
  backgroundColor: theme.palette.background.paper,
  height: '100%',
})

// Scrolling causes lots of renders.
const MemoizedSumColumn = memo(SumColumn)
const MemoizedSumRow = memo(SumRow)
const MemoizedConsistencies = memo(Consistencies)
const MemoizedLeftHeader = memo(LeftHeader)
const MemoizedTopHeader = memo(TopHeader)
// Massive amount of editors on the table.
const MemoizedEditor = memo(EditorWrapper)

export default memo(ConsistencyTable)
