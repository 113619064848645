import { Input, InputProps } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'shared/util/hooks'

interface Props extends Omit<InputProps, 'onChange'> {
  value: string
  onChange: (value: string) => void
  delay: number
}

/**
 * An input that calls onChange after a delay or when losing focus.
 * This improves performance because onChange is not triggered after each keystroke.
 */
const DebouncedInput = ({ value, onChange, delay, ...rest }: Props) => {
  const [currentValue, setCurrentValue] = useState(value)
  const [debouncedOnChange, clear] = useDebouncedCallback(onChange, delay)

  useEffect(() => {
    setCurrentValue(value)
  }, [setCurrentValue, value])

  const handleChange = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentValue(target.value)
      debouncedOnChange(target.value)
    },
    [setCurrentValue, debouncedOnChange]
  )

  const handleBlur = useCallback(() => {
    clear()
    onChange(currentValue)
  }, [currentValue, clear, onChange])

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Input onChange={handleChange} value={currentValue} onBlur={handleBlur} disableUnderline {...rest} />
}

export default DebouncedInput
