/**
 * Contains styled cells for consistency table.
 */
/* eslint-disable react/jsx-props-no-spreading */
import React, { PropsWithChildren } from 'react'
import { styled, TableCell, TableCellProps } from '@material-ui/core'
import theme from 'theme'
import { HideOverflowCell, HideOverflowHeaderCell, VerticalHideOverflow } from 'components/Common'
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'

const fixedHeight = {
  height: theme.shape.consistencyTable.cellDimension,
  maxHeight: theme.shape.consistencyTable.cellDimension,
  minHeight: theme.shape.consistencyTable.cellDimension,
  padding: theme.shape.consistencyTable.headerPadding,
} as CreateCSSProperties

const fixedWidth = {
  width: theme.shape.consistencyTable.cellDimension,
  maxWidth: theme.shape.consistencyTable.cellDimension,
  minWidth: theme.shape.consistencyTable.cellDimension,
  padding: theme.shape.consistencyTable.headerPadding,
} as CreateCSSProperties

export const LeftCell = styled(HideOverflowCell)(fixedHeight)

export const LeftHeaderCell = styled(HideOverflowHeaderCell)(fixedHeight)

type TopProps = TableCellProps & { height?: number }

const StyledTopCell = styled(TableCell)(fixedWidth)

export const TopCell = (props: TopProps) => {
  const { height, children } = props
  const cellHeight = (height ?? 0) - theme.shape.consistencyTable.cellDimension
  return (
    <StyledTopCell style={{ height: cellHeight, minHeight: cellHeight, maxHeight: cellHeight }} {...props}>
      <VerticalHideOverflow>{children}</VerticalHideOverflow>
    </StyledTopCell>
  )
}

const StyledTopHeaderCell = styled(HideOverflowHeaderCell)(fixedHeight)

export const TopHeaderCell = (props: TopProps) => {
  const { colSpan, children } = props
  const width = (colSpan ?? 1) * theme.shape.consistencyTable.cellDimension
  return (
    <StyledTopHeaderCell style={{ width, maxWidth: width, minWidth: width }} {...props}>
      {children}
    </StyledTopHeaderCell>
  )
}

export const FixedCell = styled(TableCell)({
  maxHeight: theme.shape.consistencyTable.cellDimension,
  minHeight: theme.shape.consistencyTable.cellDimension,
  height: theme.shape.consistencyTable.cellDimension,
  maxWidth: theme.shape.consistencyTable.cellDimension,
  minWidth: theme.shape.consistencyTable.cellDimension,
  width: theme.shape.consistencyTable.cellDimension,
  padding: theme.shape.consistencyTable.cellPadding,
  textAlign: 'center',
  margin: 'auto',
})

export const CellContent = styled('div')({
  width: '100%',
  height: '100%',
  borderRadius: 6,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const Cell = ({ children }: PropsWithChildren<unknown>) => (
  <FixedCell>
    <CellContent>{children}</CellContent>
  </FixedCell>
)
