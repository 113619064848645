import { defaultResultMap, IMarkedScenario } from 'analyses/analysis.model'
import type { IRootState } from 'config/state'
import { createSelector } from 'reselect'

export const getResultsMap = (state: IRootState) => state.analysis?.result?.result_maps
export const getResultMapCount = (state: IRootState) => getResultsMap(state)?.length ?? 0
export const getPrimaryResultMap = (state: IRootState) => {
  const resultMaps = getResultsMap(state)
  return resultMaps?.find((item) => item.primary)
}
export const getResultMap = (state: IRootState) => {
  const resultMaps = getResultsMap(state)
  return (
    resultMaps?.find((item) => item.id === state.ui?.resultMapId) ??
    resultMaps?.find((item) => item.primary) ??
    (resultMaps && resultMaps[0])
  )
}
export const getResultMapName = (state: IRootState) => getResultMap(state)?.name ?? defaultResultMap.name
export const getShownScenarios = (state: IRootState) =>
  getResultMap(state)?.shown_scenarios ?? defaultResultMap.shown_scenarios
export const getContourLevels = (state: IRootState) =>
  getResultMap(state)?.contour_levels ?? defaultResultMap.contour_levels
export const getShowSelectionDiamonds = (state: IRootState) =>
  getResultMap(state)?.show_selection_diamonds ?? defaultResultMap.show_selection_diamonds

export const markedScenariosSelector = createSelector(getResultMap, (resultMap) => {
  const result = {} as Record<number, IMarkedScenario>
  resultMap?.scenarios?.forEach((item) => {
    result[item.index] = item
  })
  return result
})

export const colorScaleSelector = createSelector(getResultMap, (resultMap) => ({
  min: resultMap?.color_scale_min ?? defaultResultMap.color_scale_min,
  max: resultMap?.color_scale_max ?? defaultResultMap.color_scale_max,
}))
