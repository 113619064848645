import { Select } from '@material-ui/core'
import React, { PropsWithChildren, ReactNode, useCallback, useState } from 'react'

interface Props {
  width?: number
  onChange: (value: number) => void
  startTools?: ReactNode[] | ReactNode
  endTools?: ReactNode[] | ReactNode
  renderValue: () => ReactNode
  value: string | number
  disabled?: boolean
}

/**
 * A select dropdown tjat allows putting fixed content at start and end.
 */
const DropdownMenu = ({
  value,
  width,
  onChange,
  renderValue,
  startTools,
  endTools,
  children,
  disabled,
}: PropsWithChildren<Props>) => {
  const [open, setOpen] = useState(false)

  const handleChange = useCallback(
    ({ target: { value: item } }: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      if (item === undefined) {
        setOpen(true)
        return
      }
      onChange(Number(item))
    },
    [onChange]
  )

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])
  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  return (
    <Select
      value={value}
      onChange={handleChange}
      renderValue={renderValue}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      style={{ width }}
      MenuProps={{
        MenuListProps: {
          disablePadding: true,
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      disableUnderline
      disabled={disabled}
    >
      {startTools && startTools}
      {children}
      {endTools && endTools}
    </Select>
  )
}

export default DropdownMenu
