import {
  Button,
  Card,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  styled,
  InputAdornment,
  Grid,
  Chip,
  Typography,
} from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { IAnalysisOverview } from 'analyses/analysis.model'
import { useTranslate } from 'translation'
import SearchIcon from '@material-ui/icons/Search'
import DebouncedInput from 'components/DebouncedInput'
import theme from 'theme'
import { TreeView, TreeItem } from '@material-ui/lab'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { AlignEnd, DialogWithFulLheight, FlexGrow, Flex, Progress, VerticalFlex } from 'components/Common'
import { LoginActions } from 'views/login/login.reducer'
import { useCanAdd } from 'views/login/login.hook'
import { getAll } from './analysis.api'
import { useCreateAnalysis, useOpenAnalysis, useId, useModalOpen } from './hooks/ui'
import { openModal } from './reducers'
import RevisionDropdown from './revision/RevisionDropdown'

const useAnalyses = (search: string, isOpen: boolean) => {
  const [analyses, setAnalyses] = useState<IAnalysisOverview[] | undefined>(undefined)

  useEffect(() => {
    if (isOpen) getAll().then((value) => setAnalyses(value ?? []))
  }, [isOpen])

  return useMemo(() => {
    const lower = search.toLowerCase()
    return analyses?.filter(
      (item) =>
        item.name.toLowerCase().includes(lower) || item.groups.some((group) => group.toLowerCase().includes(lower))
    )
  }, [analyses, search])
}

const useSelected = () => {
  const id = useId()
  const [selected, setSelected] = useState<number | undefined>(id)
  useEffect(() => {
    setSelected(id)
  }, [id])

  return [selected, setSelected] as const
}

const useHandles = () => {
  const [selected, setSelected] = useSelected()
  const dispatch = useDispatch()

  const handleClose = useCallback(() => {
    dispatch(openModal(false))
  }, [dispatch])

  const handleLogout = useCallback(() => {
    dispatch(LoginActions.logout())
  }, [dispatch])

  const openAnalysis = useOpenAnalysis(handleClose)
  const createAnalysis = useCreateAnalysis()

  const handleCreate = useCallback(() => {
    handleClose()
    createAnalysis()
  }, [handleClose, createAnalysis])

  const handleOpen = useCallback(() => {
    if (selected !== undefined) openAnalysis(selected)
  }, [openAnalysis, selected])

  const handleSelect = useCallback(
    (id: number) => {
      setSelected(id)
    },
    [setSelected]
  )
  return { selected, handleClose, handleOpen, handleLogout, handleSelect, handleCreate }
}

/**
 * A modal dialog for opening an analysis.
 */
const OpenDialog = ({ disablePortal }: { disablePortal?: boolean }) => {
  const id = useId()
  const canAdd = useCanAdd()
  const isOpen = useModalOpen() || id === undefined
  const t = useTranslate()
  const [searchString, setSearchString] = useState<string>('')
  const { selected, handleClose, handleLogout, handleOpen, handleSelect, handleCreate } = useHandles()

  const analyses = useAnalyses(searchString, isOpen)
  const grouped = useMemo(() => {
    if (!analyses) return undefined
    const result = {} as Record<string, IAnalysisOverview[]>
    analyses.forEach((item) => {
      item.groups.forEach((group) => {
        if (!result[group]) result[group] = []
        result[group].push(item)
      })
    })
    return result
  }, [analyses])

  const keys = useMemo(() => (grouped ? Object.keys(grouped) : []), [grouped])
  const analysis = analyses?.find((item) => item.id === selected)

  return (
    <DialogWithFulLheight
      aria-labelledby='analysis-opener'
      open={isOpen}
      onClose={handleClose}
      maxWidth='lg'
      fullWidth
      disablePortal={disablePortal}
    >
      <DialogTitle>
        <Flex>
          <FlexGrow variant='h5' color='secondary'>
            <span id='analysis-opener'>{t('Open Analysis')}</span>
          </FlexGrow>
          <AlignEnd>
            <SearchField
              delay={250}
              placeholder={t('Search')}
              value={searchString}
              color='secondary'
              onChange={setSearchString}
              startAdornment={
                <InputAdornment position='start'>
                  <SearchIcon color='secondary' style={{ marginLeft: 5 }} />
                </InputAdornment>
              }
            />
          </AlignEnd>
        </Flex>
      </DialogTitle>
      <FlexContent>
        <Divider />
        {grouped === undefined ? (
          <Progress />
        ) : (
          <ContainedTreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultChecked
            defaultExpanded={[keys[0]]}
          >
            {keys.map((key) => {
              const value = grouped[key]
              return (
                <TreeItem
                  key={key}
                  nodeId={key}
                  label={
                    <Typography className='opendialog-group' variant='h6'>
                      {key}
                    </Typography>
                  }
                >
                  <List>
                    {value.map((item) => (
                      <RenderItem
                        item={item}
                        onSelect={handleSelect}
                        onOpen={handleOpen}
                        key={item.id}
                        isActive={item.id === selected}
                      />
                    ))}
                  </List>
                </TreeItem>
              )
            })}
          </ContainedTreeView>
        )}
        <Divider />
        <PaddedGrid container>
          <Grid item xs={2}>
            <Button
              color='secondary'
              variant='outlined'
              onClick={handleCreate}
              style={{ visibility: canAdd ? 'unset' : 'hidden' }}
            >
              <span id='opendialog-create'>{t('Create new')}</span>
            </Button>
          </Grid>
          <GridRight item xs={6}>
            <RevisionDropdown width={theme.shape.revisionWidth} analysis={analysis} />
          </GridRight>
          <GridRight item xs={2}>
            <Button color='secondary' variant='outlined' onClick={handleOpen}>
              <span id='opendialog-open'>{t('Open')}</span>
            </Button>
          </GridRight>
          <GridRight item xs={2}>
            <Button color='secondary' variant='outlined' onClick={id === undefined ? handleLogout : handleClose}>
              <span id='opendialog-cancel'>{t(id === undefined ? 'Logout' : 'Cancel')}</span>
            </Button>
          </GridRight>
        </PaddedGrid>
      </FlexContent>
    </DialogWithFulLheight>
  )
}

const GridRight = styled(Grid)({
  textAlign: 'end',
})

const PaddedGrid = styled(Grid)({
  padding: theme.shape.opener.gridPadding,
})

interface ItemProps {
  item: IAnalysisOverview
  onSelect: (id: number) => void
  onOpen: () => void
  isActive: boolean
}

const RenderItem = ({ item, onSelect, onOpen, isActive }: ItemProps) => {
  const handleSelect = useCallback(() => {
    onSelect(item.id)
  }, [onSelect, item.id])
  const handleOpen = useCallback(() => {
    onOpen()
  }, [onOpen])

  return (
    <ListItem onFocus={handleSelect} onDoubleClick={handleOpen} selected={isActive} tabIndex={0} button disableRipple>
      <TransparentCard elevation={0}>
        <VerticalFlex>
          <FlexGrow className='opendialog-item' variant='subtitle2'>
            {item.name}
          </FlexGrow>
          {item.groups.map((group) => (
            <ColoredChip key={group} label={group} variant='outlined' size='small' />
          ))}
        </VerticalFlex>
      </TransparentCard>
    </ListItem>
  )
}

const TransparentCard = styled(Card)({
  backgroundColor: 'transparent',
  width: '100%',
})

const ContainedTreeView = styled(TreeView)({
  overflowY: 'auto',
  flexGrow: 1,
})

const ColoredChip = styled(Chip)({
  marginLeft: theme.shape.opener.chipPadding,
  color: theme.palette.secondary.main,
  borderColor: theme.palette.secondary.main,
})

const SearchField = styled(DebouncedInput)({
  borderRadius: 50,
  border: `solid ${theme.palette.secondary.main}`,
})

const FlexContent = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
})
export default OpenDialog
