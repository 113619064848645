/* eslint-disable react/no-unused-prop-types */
import { makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import theme from 'theme'

interface Props {
  radius: number
  color: string
  index: number
}

const useStyles = makeStyles<Theme, Props>(() => ({
  circle: {
    backgroundColor: (props) => props.color,
    width: (props) => props.radius * 2,
    height: (props) => props.radius * 2,
    borderRadius: '50%',
    position: 'absolute',
    right: (props) => props.index * (5 + props.radius * 2) + theme.shape.cardPadding,
    top: 5,
  },
}))

/**
 * Draws a colored circle.
 * Circles are placed on a row (location depends on index).
 */
const Circle = (props: Props) => {
  const classes = useStyles(props)
  return <div className={classes.circle} />
}

export default Circle
