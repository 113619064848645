// According to docs, useSelector causes a render if the return value changes.
// So chaining them may cause unnecessary renders. For this reason separate functions are used.
// Reselect library is additionally used to combine useSelector and useMemo.

import { defaultArray, IScenario } from 'analyses/analysis.model'
import { uniq } from 'lodash-es'
import { createSelector } from 'reselect'
import { getUncertainties } from './analysis'
import { getKeyScenarios, getResultData, getScenarios } from './result'
import { getShownScenarios, markedScenariosSelector } from './resultmap'
import { getOnlyKeyScenarios, getOnlyMarkedScenarios, getSelectedOutcomes } from './ui'

export const keyScenariosSelector = createSelector(getKeyScenarios, getShownScenarios, (keyScenarios, shown) =>
  keyScenarios.filter((item) => item < shown)
)

export const shownScenariosSelector = createSelector(getScenarios, getShownScenarios, (scenarios, shown) =>
  scenarios.slice(0, shown).map((item, index) => ({ ...item, id: index }))
)

export const scenariosSelector = createSelector(
  shownScenariosSelector,
  keyScenariosSelector,
  markedScenariosSelector,
  getOnlyKeyScenarios,
  getOnlyMarkedScenarios,
  (scenarios, keyScenarios, markedScenarios, onlyKey, onlyMarked) => {
    if (!onlyKey && !onlyMarked) return scenarios
    if (onlyKey && !onlyMarked) return keyScenarios.map((index) => scenarios[index])
    if (!onlyKey && onlyMarked)
      return Object.keys(markedScenarios)
        .map(Number)
        .filter((index) => index < scenarios.length)
        .map((index) => scenarios[index])
    return Object.keys(markedScenarios)
      .map(Number)
      .filter((index) => keyScenarios.includes(index))
      .map((index) => scenarios[index])
  }
)

export const scenariosObjectSelector = createSelector(scenariosSelector, (scenarios) => {
  const result = {} as Record<number, IScenario>
  scenarios.forEach((item) => {
    result[item.id] = item
  })
  return result
})

export const scenarioCountSelector = createSelector(scenariosSelector, (scenarios) => scenarios.length)

// This is supposed to make the search a bit faster.
// Instead of having to iterate up to 10000 scenarios there is probably only up to 2500.
// This can be further refined if needed.
export const outcomeIdToScenariosSelector = createSelector(scenariosSelector, (scenarios) => {
  const result = {} as Record<number, IScenario[]>
  scenarios.forEach((item) => {
    item.outcome_ids.forEach((id) => {
      if (!result[id]) result[id] = []
      result[id].push(item)
    })
  })
  return result
})

export const baseSelectedSelector = createSelector(
  outcomeIdToScenariosSelector,
  getSelectedOutcomes,
  (scenarios, selected) =>
    selected.length === 0 || !scenarios[selected[0]]
      ? defaultArray
      : scenarios[selected[0]].filter((item) => selected.every((id) => item.outcome_ids.includes(id)))
)

export const selectedScenariosSelector = createSelector(baseSelectedSelector, (scenarios) =>
  scenarios.map((item) => item.id)
)

export const includedOutcomesSelector = createSelector(
  baseSelectedSelector,
  getSelectedOutcomes,
  getUncertainties,
  (scenarios, selected, uncertainties) => {
    const selectedUncertainties = uncertainties.filter((item) =>
      item.outcomes.some((outcome) => selected.includes(outcome.id))
    )
    const excludedOutcomes = selectedUncertainties
      .flatMap((item) => item.outcomes)
      .map((item) => item.id)
      .sort()
    const included =
      selected.length === 0
        ? defaultArray
        : uniq(scenarios.flatMap((item) => item.outcome_ids))
            .sort()
            .filter((item) => !excludedOutcomes.includes(item))
    return included
  }
)

export const resultDataSelector = createSelector(
  scenariosSelector,
  getResultData,
  (scenarios, data) =>
    data && {
      ...data,
      scenarios,
    }
)

export const outcomeColorSelector = createSelector(
  scenariosObjectSelector,
  markedScenariosSelector,
  (scenarios, marked) => {
    const result = {} as Record<string, string[]>
    Object.values(marked).forEach((marking) => {
      if (!marking.color) return
      const scenario = scenarios[marking.index]
      if (!scenario) return
      scenario.outcome_ids.forEach((id) => {
        if (!result[id]) result[id] = []
        result[id].push(marking.color)
      })
    })
    return result
  }
)
