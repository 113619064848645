import { rgb } from 'd3'
import { clamp } from 'lodash-es'
import { ColorMap, ColorPart } from './colors'

export * from './colors'

const getValueSub = (values: ColorPart[], index: number) => {
  for (let i = 0; i < values.length; ++i) {
    if (index === values[i].index) {
      return values[i].value
    }
    if (index < values[i].index) {
      const from = values[i - 1].value
      const to = values[i].value
      const delta = (index - values[i - 1].index) / (values[i].index - values[i - 1].index)
      return from * (1 - delta) + to * delta
    }
  }
  return 0
}

const getValue = (values: ColorPart[], index: number) => Math.floor(255 * getValueSub(values, index))

/**
 * Returns a color from a given color map at a given index.
 * @param colorMap Color map definition.
 * @param index Value from 0 to 1.
 */
export const getColorFromMap = (colorMap: ColorMap, index: number) => {
  const clamped = clamp(index, 0, 1)
  const r = getValue(colorMap.red, clamped)
  const g = getValue(colorMap.green, clamped)
  const b = getValue(colorMap.blue, clamped)
  return rgb(r, g, b, 1).formatRgb()
}

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}
