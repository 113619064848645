import { Button, Container, FormControl, Paper, TextField, Typography } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import { useTranslate } from 'translation'
import React, { useCallback, useState } from 'react'
import theme from 'theme'

const LoginPaper = styled(Paper)({
  marginTop: theme.shape.login.marginTop,
  padding: theme.shape.login.padding,
})

const LogoContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const Logo = styled('img')({
  margin: 'auto',
  maxWidth: theme.shape.login.logoWidth,
})

const LoginButton = styled(Button)({
  marginTop: theme.shape.login.padding,
})

export interface ILoginComponentProps {
  onSubmit: (userName: string, password: string) => void
  loading: boolean
}

export const LoginComponent: React.FC<ILoginComponentProps> = ({ onSubmit }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const t = useTranslate()

  const handleLogin = useCallback(() => {
    // validate
    if (!username || !password) return

    onSubmit(username, password)
  }, [onSubmit, username, password])

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleLogin()
      }
    },
    [handleLogin]
  )

  return (
    <Container component='main' maxWidth='xs'>
      <LoginPaper>
        <LogoContainer>
          <Logo src={`${process.env.PUBLIC_URL}/${theme.logo}`} alt='Logo' />
        </LogoContainer>
        <FormControl fullWidth>
          <TextField
            type='text'
            label={t('Username')}
            placeholder={t('Username')}
            autoComplete='username'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            variant='outlined'
            margin='normal'
            color='secondary'
            required
            fullWidth
            autoFocus
          />
          <TextField
            type='password'
            label={t('Password')}
            placeholder={t('Password')}
            autoComplete='current-password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={handleKeyPress}
            variant='outlined'
            color='secondary'
            margin='normal'
            required
            fullWidth
          />
          <LoginButton
            id='login'
            disabled={!(username && password)} // NOTE that this isn't always be false when browser autofills
            onClick={handleLogin}
            fullWidth
            variant='contained'
            color='secondary'
            disableElevation
          >
            <Typography>{t('Login')}</Typography>
          </LoginButton>
        </FormControl>
      </LoginPaper>
    </Container>
  )
}
