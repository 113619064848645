import loginApi from 'shared/api/login'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { AccountStorage } from 'shared/util/accountStorage'
import { clearAnalysisState, clearUIState } from 'analyses/reducers'
import { LoginActions } from './login.reducer'
import { IAccount } from 'shared/model'

export function* loginSaga(action: ReturnType<typeof LoginActions.login>) {
  try {
    yield put(clearAnalysisState())
    yield put(clearUIState())
    const result: IAccount = yield call(loginApi.login, action.payload.username, action.payload.password)
    if (result) {
      yield call(AccountStorage.setAuthenticated, true)
      yield call(AccountStorage.set, result)
      yield put(LoginActions.loginSuccess(result))
    } else {
      yield call(AccountStorage.setAuthenticated, false)
      yield put(LoginActions.loginFailed())
    }
  } catch (e) {
    yield call(AccountStorage.setAuthenticated, false)
    yield put(LoginActions.loginFailed())
  }
}
export function* logoutSaga() {
  yield call(loginApi.logout)
  yield put(clearAnalysisState())
  yield put(clearUIState())
  yield call(AccountStorage.setAuthenticated, false)
  yield call(AccountStorage.remove)
}

export function* loginSagas() {
  yield all([takeEvery(LoginActions.login.type, loginSaga), takeEvery(LoginActions.logout.type, logoutSaga)])
}
