/* eslint-disable react/jsx-props-no-spreading */
import { Card, CardContent, Divider, makeStyles, styled } from '@material-ui/core'
import React, { useMemo, useCallback, memo, Component } from 'react'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { useDispatch } from 'react-redux'
import { IOutcome } from 'analyses/analysis.model'
import FocusableInput from 'components/FocusableInput'
import { CellFocuser } from 'components/useCellFocuser'
import Circle from 'components/Circle'
import DraggableList from 'react-draggable-list'
import theme from 'theme'
import { formatOutcomeIndex } from 'analyses/utils'
import { changeOutcomeName, changeOutcomeOrder, removeOutcome } from '../reducers'
import {
  useSelectByOutcome,
  useUncertaintyTableFixedHeight,
  useSelectedOutcomes,
  useIncludedOutcomes,
  useUncertainty,
  useOutcomeColors,
  useCanChangeModel,
} from '../hooks'

const useTableStyles = makeStyles({
  selected: {
    backgroundColor: theme.palette.uncertaintyTable.selected_bg,
    border: `solid 1px ${theme.palette.uncertaintyTable.selected}`,
  },
  included: {
    backgroundColor: theme.palette.uncertaintyTable.included_bg,
    border: `solid 1px ${theme.palette.uncertaintyTable?.included}`,
  },
})

interface Props {
  focusers: CellFocuser[]
  uncertaintyIndex: number
}

const OutcomeList = ({ uncertaintyIndex, focusers }: Props) => {
  const uncertainty = useUncertainty(uncertaintyIndex)
  const canChange = useCanChangeModel()

  const dispatch = useDispatch()
  const selectedOutcomes = useSelectedOutcomes()
  const includedOutcomes = useIncludedOutcomes()

  const handleChangeOutcomeName = useCallback(
    (id, name) => {
      dispatch(changeOutcomeName(id, name))
    },
    [dispatch]
  )

  const handleDeleteOutcome = useCallback(
    (id: number) => {
      dispatch(removeOutcome(id))
    },
    [dispatch]
  )

  // This only triggers when the order changes so no checks are needed.
  const handleMoveEnd = useCallback(
    (list: readonly ItemProps[]) => {
      const order = list.map((item) => item.outcome.id)
      dispatch(changeOutcomeOrder(uncertainty.id, order))
    },
    [dispatch, uncertainty.id]
  )
  const uncertaintyTableFixedHeight = useUncertaintyTableFixedHeight()

  const colors = useOutcomeColors()
  const selectByOutcome = useSelectByOutcome()

  const defaultList = useMemo(
    () =>
      uncertainty.outcomes.map(
        (item, index) =>
          ({
            focuser: focusers[index + (canChange ? 2 : 1)],
            outcome: item,
            outcomeIndex: index,
            selected: selectedOutcomes.includes(item.id),
            included: includedOutcomes.includes(item.id),
            colors: colors[item.id],
            onChangeName: handleChangeOutcomeName,
            onDelete: handleDeleteOutcome,
            onSelect: selectByOutcome,
            uncertaintyIndex,
            key: item.id,
            canChange,
            fixedHeight: uncertaintyTableFixedHeight,
          } as ItemProps)
      ),
    [
      uncertainty.outcomes,
      focusers,
      selectedOutcomes,
      includedOutcomes,
      colors,
      handleChangeOutcomeName,
      handleDeleteOutcome,
      selectByOutcome,
      uncertaintyIndex,
      canChange,
      uncertaintyTableFixedHeight,
    ]
  )

  return (
    <OutcomeListContainer className='uncertaintytable-outcomelist'>
      <DraggableList itemKey='key' template={Template} list={defaultList} onMoveEnd={handleMoveEnd} />
    </OutcomeListContainer>
  )
}

export const OutcomeListContainer = styled('div')({
  height: '100%',
  padding: theme.shape.outcomeListPadding,
  paddingTop: 2 * theme.shape.outcomeListPadding,
})

interface TemplateProps {
  item: ItemProps
  // eslint-disable-next-line @typescript-eslint/ban-types
  dragHandleProps: object
}

interface TemplateState {
  editing: boolean
}
// eslint-disable-next-line react/prefer-stateless-function
class Template extends Component<TemplateProps, TemplateState> {
  constructor(props: TemplateProps) {
    super(props)
    this.state = { editing: false }
  }

  setEditing = (editing: boolean) => {
    this.setState({ editing })
  }

  render() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { item, dragHandleProps } = this.props
    const { editing } = this.state
    const handleProps = item.canChange && !editing ? dragHandleProps : {}
    return (
      <div key={String(item.outcome.id)} {...handleProps}>
        <MemoizedItem {...item} onEditing={this.setEditing} />
      </div>
    )
  }
}

interface ItemProps {
  outcome: IOutcome
  focuser: CellFocuser
  outcomeIndex: number
  uncertaintyIndex: number
  onEditing?: (value: boolean) => void
  onChangeName: (id: number, name: string) => void
  onDelete: (id: number) => void
  onSelect: (id: number, selected: boolean, included: boolean) => void
  selected: boolean
  included: boolean
  colors: string[] | undefined
  canChange: boolean
  fixedHeight: boolean
}

const TightCardContent = styled(CardContent)({
  padding: theme.shape.cardPadding,
  '&:last-child': {
    padding: theme.shape.cardPadding,
  },
})

const Item = ({
  outcome,
  outcomeIndex,
  focuser,
  uncertaintyIndex,
  onChangeName,
  onDelete,
  onSelect,
  selected,
  included,
  colors,
  canChange,
  fixedHeight,
  onEditing,
}: ItemProps) => {
  const handleChange = useCallback(
    (name: string) => {
      onChangeName(outcome.id, name)
    },
    [onChangeName, outcome.id]
  )
  const handleDelete = useCallback(() => {
    onDelete(outcome.id)
  }, [onDelete, outcome.id])
  const handleClick = useCallback(
    (e: React.UIEvent) => {
      onSelect(outcome.id, selected, included)
      focuser.ref.current?.focus()
      e.stopPropagation()
    },
    [onSelect, outcome.id, selected, included, focuser.ref]
  )
  const classes = useTableStyles()

  // eslint-disable-next-line no-nested-ternary
  const className = selected ? classes.selected : included ? classes.included : ''
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <Card className={`${className} uncertaintytable_outcome`} onClick={handleClick}>
      <TightCardContent>
        <FocusableInput
          onEditing={onEditing}
          startTools={<Header outcomeIndex={outcomeIndex} uncertaintyIndex={uncertaintyIndex} colors={colors} />}
          focuser={focuser}
          value={outcome.name}
          onChange={canChange ? handleChange : undefined}
          onDelete={canChange ? handleDelete : undefined}
          display={outcome.name}
          fixedHeight={fixedHeight}
        />
      </TightCardContent>
    </Card>
  )
}

interface HeaderProps {
  outcomeIndex: number
  uncertaintyIndex: number
  colors: string[] | undefined
}

const Header = ({ outcomeIndex, uncertaintyIndex, colors }: HeaderProps) => (
  <>
    <div className='dragCell'>
      {formatOutcomeIndex(outcomeIndex, uncertaintyIndex)}
      {colors && colors.map((color, index) => <Circle key={String(index)} index={index} radius={5} color={color} />)}
    </div>
    <Divider />
  </>
)

const MemoizedItem = memo(Item)

export default OutcomeList
