import { Button, styled } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAnalysisId } from 'analyses/hooks'
import { get } from '../reducers'
import { getAnalysisProgress, runAnalysis } from '../analysis.api'

/**
 * A button to run the analysis and show progress.
 * This is kind of a placeholder but will be on production for many years to come.
 */
const RunResult = () => {
  const [progress, setProgress] = useState<number | undefined>(undefined)
  const dispatch = useDispatch()
  const id = useAnalysisId()

  const handleAnalysis = useCallback(() => {
    if (id === undefined) return
    runAnalysis(id).then((taskId) => {
      if (!taskId) return
      let i = 0
      const interval = setInterval(async () => {
        const response = await getAnalysisProgress(id, taskId)
        if (response.state === 'SUCCESS') {
          setProgress(undefined)
          dispatch(get())
          clearInterval(interval)
        }
        if (response.state === 'PENDING') {
          setProgress(0)
        }
        if (response.state === 'PROGRESS' && response.details) {
          setProgress(Math.round((100 * response.details.current) / response.details.total))
        }
        i += 1
        if (i > 100) {
          clearInterval(interval)
        }
      }, 1000)
    })
  }, [dispatch, id])

  return (
    <StyledButton size='large' variant='contained' color='secondary' onClick={handleAnalysis}>
      {`Run analysis ${progress !== undefined ? `(${progress}%)` : ''}`}{' '}
    </StyledButton>
  )
}

const StyledButton = styled(Button)({
  marginTop: '2rem',
  marginLeft: '50%',
})

export default RunResult
