import { IconButton, Button, useMediaQuery } from '@material-ui/core'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import SimpleTabs from 'components/SimpleTabs'
import AddIcon from '@material-ui/icons/Add'
import SubjectIcon from '@material-ui/icons/Subject'
import ResetZoomIcon from '@material-ui/icons/FullscreenExit'
import { useTranslate } from 'translation'
import IconTune from '@material-ui/icons/Tune'
import theme from 'theme'
import RowLayout from 'components/RowLayout'
import { Disabler } from 'components/Common'
import ConsistencyTable from './consistency_table/ConsistencyTable'
import ResultMapLayout from './result_map/Layout'
import UncertaintyTable from './uncertainty_table/UncertaintyTable'
import {
  setAnalysisId,
  addUncertainty,
  toggleShowToolbar,
  resetContourZoom,
  setLayout,
  resetUncertaintyTableLayout,
  toggleUncertaintyTableFixedHeight,
} from './reducers'
import { useCanChangeResult, useCanChangeModel } from './hooks'
import ResultMapSelector from './result_map/Selector'
import { useShowToolbar, useUncertaintyTableFixedHeight, useWidth, useHeightWithNavigation } from './hooks/ui'

const AddUncertaintyButton = () => {
  const dispatch = useDispatch()
  const t = useTranslate()
  const canChange = useCanChangeModel()
  const handleClick = useCallback(() => {
    dispatch(addUncertainty(t('Uncertainty')))
  }, [dispatch, t])
  if (!canChange) return null
  return (
    <Button onClick={handleClick} endIcon={<AddIcon />} color='secondary'>
      {t('Add uncertainty')}
    </Button>
  )
}

const ResetContourZoomButton = () => {
  const dispatch = useDispatch()
  const t = useTranslate()
  const handleClick = useCallback(() => {
    dispatch(resetContourZoom())
  }, [dispatch])
  return (
    <IconButton aria-label={t('Reset contour layout')} onClick={handleClick} color='secondary'>
      <ResetZoomIcon />
    </IconButton>
  )
}
const ResetUncertaintyTableLayoutButton = () => {
  const dispatch = useDispatch()
  const t = useTranslate()
  const handleClick = useCallback(() => {
    dispatch(resetUncertaintyTableLayout())
  }, [dispatch])
  return (
    <IconButton aria-label={t('Reset uncertainty table layout')} onClick={handleClick} color='secondary'>
      <ResetZoomIcon />
    </IconButton>
  )
}

const ShowResultMapToolbar = () => {
  const showToolbar = useShowToolbar()
  const dispatch = useDispatch()
  const canChange = useCanChangeResult()
  const t = useTranslate()
  const handleClick = useCallback(() => {
    dispatch(toggleShowToolbar())
  }, [dispatch])
  if (!canChange) return null
  return (
    <IconButton
      aria-label={showToolbar ? t('Hide toolbar') : t('Show toolbar')}
      aria-controls='contour-toolbar'
      aria-haspopup='menu'
      onClick={handleClick}
      color='secondary'
    >
      {showToolbar ? <IconTune /> : <IconNoTune />}
    </IconButton>
  )
}

const IconNoTune = () => (
  <Disabler>
    <IconTune />
  </Disabler>
)

const UseUncertaintyOverflow = () => {
  const uncertaintyTableFixedHeight = useUncertaintyTableFixedHeight()
  const dispatch = useDispatch()
  const t = useTranslate()
  const handleClick = useCallback(() => {
    dispatch(toggleUncertaintyTableFixedHeight())
  }, [dispatch])
  return (
    <IconButton
      aria-label={uncertaintyTableFixedHeight ? t('Use dynamic cell height') : t('Use fixed cell height')}
      onClick={handleClick}
      color='secondary'
    >
      {uncertaintyTableFixedHeight ? <IconNoSubject /> : <SubjectIcon />}
    </IconButton>
  )
}

const IconNoSubject = () => (
  <Disabler>
    <SubjectIcon />
  </Disabler>
)
const getLeftStartTools = (selected: string) =>
  selected === '1' ? <ResultMapSelector color='textSecondary' /> : undefined
const getLeftEndTools = (selected: string) =>
  selected === '1'
    ? [<ResetContourZoomButton key='resetContourZoomButton' />, <ShowResultMapToolbar key='showResultMapToolbar' />]
    : undefined
const getRightEndTools = () => [
  <UseUncertaintyOverflow key='useUncertaintyOverflow' />,
  <ResetUncertaintyTableLayoutButton key='resetUncertaintyTableLayoutButton' />,
  <AddUncertaintyButton key='addUncertaintyButton' />,
]

const columns = 20

const DesktopLayout = () => {
  const width = useWidth()
  const height = useHeightWithNavigation()

  const t = useTranslate()

  const leftLabels = useMemo(() => [t('Consistency'), t('Scenario map')], [t])
  const rightLabels = useMemo(() => [t('Uncertainties')], [t])

  return (
    <RowLayout initial={columns / 2} columns={columns} height={height} width={width} setValueAction={setLayout}>
      <SimpleTabs labels={leftLabels} startTools={getLeftStartTools} endTools={getLeftEndTools}>
        <ConsistencyTable />
        <ResultMapLayout columns={columns} />
      </SimpleTabs>
      <SimpleTabs labels={rightLabels} endTools={getRightEndTools}>
        <UncertaintyTable />
      </SimpleTabs>
    </RowLayout>
  )
}

const getEndTools = (selected: string) => {
  if (selected === '0') return undefined
  if (selected === '1') {
    return [
      <UseUncertaintyOverflow key='useUncertaintyOverflow' />,
      <ResetUncertaintyTableLayoutButton key='resetUncertaintyTableLayoutButton' />,
      <AddUncertaintyButton key='addUncertainty' />,
    ]
  }
  if (selected === '2') {
    return [
      <ResultMapSelector key='resultMapSelector' color='textSecondary' />,
      <ResetContourZoomButton key='resetContourZoomButton' />,
      <ShowResultMapToolbar key='showResultMapToolbar' />,
    ]
  }
  return undefined
}

const MobileLayout = () => {
  const t = useTranslate()
  const labels = useMemo(() => [t('Consistency'), t('Uncertainties'), t('Scenario map')], [t])

  return (
    <SimpleTabs labels={labels} endTools={getEndTools}>
      <ConsistencyTable />
      <UncertaintyTable />
      <ResultMapLayout columns={columns} />
    </SimpleTabs>
  )
}

const useLoadAnalysis = () => {
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  useEffect(() => {
    if (id !== undefined) dispatch(setAnalysisId(Number(id)))
  }, [dispatch, id])
}

const MainLayout = () => {
  useLoadAnalysis()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return isMobile ? <MobileLayout /> : <DesktopLayout />
}

export default MainLayout
