import type { IRootState } from 'config/state'
import { useSelector } from 'react-redux'
import { useActions } from 'shared/util/reducer-utils'
import { LoginActions } from './login.reducer'

export function useLogin() {
  const actions = useActions(LoginActions)
  const state = useSelector((root: IRootState) => root.login)

  return {
    state,
    actions,
  }
}

export const useAccount = () => useSelector((root: IRootState) => root.login.account)

export const useCanAdd = () => useSelector((root: IRootState) => root.login.account?.can_add)
