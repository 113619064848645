/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import Routes from 'appRoutes'
import ErrorBoundary from 'shared/error/ErrorBoundary'
import type { Role } from 'shared/model'
import type { IRootState } from 'config/state'

interface ComponentProps extends RouteProps {
  isAuthenticated: boolean
}
export const PrivateRouteComponent = ({ isAuthenticated, component: Component, ...rest }: ComponentProps) => {
  const NwComp: any = Component

  const renderRedirect = (props: any) =>
    isAuthenticated ? (
      <ErrorBoundary>
        <NwComp {...props} />
      </ErrorBoundary>
    ) : (
      <Redirect
        to={{
          pathname: Routes.Login,
          search: props.location.search,
          state: { from: props.location },
        }}
      />
    )

  if (!Component) throw new Error(`A component needs to be specified for private route for path ${(rest as any).path}`)

  return <Route {...rest} render={renderRedirect} />
}

export const hasAnyAuthority = (authorities: ReadonlyArray<Role>, hasAnyAuthorities: Role[]) => {
  if (authorities && authorities.length !== 0) {
    if (hasAnyAuthorities.length === 0) {
      return true
    }
    return hasAnyAuthorities.some((auth) => authorities.includes(auth))
  }
  return false
}

interface Props extends RouteProps {
  requiredRoles?: Role[]
}

const PrivateRoute = ({ requiredRoles, ...rest }: Props) => {
  const isAuthenticated = useSelector((state: IRootState) => state.login.isAuthenticated)
  return <PrivateRouteComponent isAuthenticated={isAuthenticated} {...rest} />
}

export default PrivateRoute
