import React, { ErrorInfo, Component } from 'react'

interface IErrorBoundaryProps {
  readonly children: JSX.Element | JSX.Element[]
}

interface IErrorBoundaryState {
  readonly error?: Error
  readonly errorInfo?: ErrorInfo
}

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props)
    this.state = {
      error: undefined,
      errorInfo: undefined,
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    })
  }

  render() {
    const { children } = this.props
    const { error, errorInfo } = this.state
    if (errorInfo) {
      const errorDetails =
        process.env.NODE_ENV === 'development' ? (
          <details className='preserve-space'>
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </details>
        ) : undefined
      return (
        <div>
          <h2 className='error'>An unexpected error has occurred.</h2>
          {errorDetails}
        </div>
      )
    }
    return children
  }
}

export default ErrorBoundary
