/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useEffect, useLayoutEffect, useCallback, useState, RefObject } from 'react'
import { useDispatch } from 'react-redux'

export const useRefreshingState = <T>(initial: T) => {
  const [state, setState] = useState(initial)

  useEffect(() => {
    setState(initial)
  }, [initial])
  return [state, setState] as const
}

export const useComponentRect = <T extends HTMLElement>() => {
  const ref = useRef<T>(null)
  const [rect, setRect] = useState<DOMRect | null>(null)

  const update = useCallback(() => {
    setRect(ref.current?.getBoundingClientRect() ?? null)
  }, [])
  // Debouncing for smoother resize.
  const [debouncedUpdate, cancel] = useDebouncedCallback(update, 50)

  useEffect(() => {
    debouncedUpdate()
    return () => cancel()
  })

  return { rect, ref }
}

// Copypaste with some changes from https://dev.to/n8tb1t/tracking-scroll-position-with-react-hooks-3bbj

const hasWindow = typeof window !== `undefined`

const getScrollPosition = (element: RefObject<HTMLElement> | null, parent: RefObject<HTMLElement>) => {
  if (!hasWindow) return { x: 0, y: 0 }

  const target = element?.current ?? document.body
  const position = target.getBoundingClientRect()
  const parentTarget = parent?.current
  const parentPosition = parentTarget?.getBoundingClientRect() ?? { left: 0, top: 0 }
  return { x: position.left - parentPosition.left, y: position.top - parentPosition.top }
}

export const useScrollPosition = (element: RefObject<HTMLElement>, parent: RefObject<HTMLElement>, wait: number) => {
  const timeout = useRef<NodeJS.Timeout | null>(null)
  const [scrollPosition, setScrollPosition] = useState(getScrollPosition(element, parent))

  const handleSetScroll = useCallback(() => {
    setScrollPosition(getScrollPosition(element, parent))
    timeout.current = null
  }, [element, parent])

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (timeout.current !== null) clearTimeout(timeout.current)
        timeout.current = setTimeout(handleSetScroll, wait)
      } else {
        handleSetScroll()
      }
    }

    const target = parent?.current ?? window
    target.addEventListener('scroll', handleScroll, { passive: true })

    return () => target.removeEventListener('scroll', handleScroll)
  }, [handleSetScroll, wait, parent])

  return scrollPosition
}

// Copypaste with some changes from https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
export const useWindowDimensions = () => {
  const getWindowDimensions = useCallback(() => {
    const width = hasWindow ? window.innerWidth : null
    const height = hasWindow ? window.innerHeight : null
    return {
      width,
      height,
    }
  }, [])

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useLayoutEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions())
      }

      window.addEventListener('resize', handleResize, { passive: true })
      return () => window.removeEventListener('resize', handleResize)
    }
    return undefined
  }, [getWindowDimensions])

  return windowDimensions
}

export const useStoreDimension = (element: RefObject<HTMLElement>, callback: (value: DOMRect | undefined) => void) => {
  const dispatch = useDispatch()
  const updateRect = useCallback(
    (ref: RefObject<HTMLElement>) => {
      dispatch(callback(ref.current?.getBoundingClientRect()))
    },
    [dispatch, callback]
  )
  const [debounced] = useDebouncedCallback(updateRect, 200)

  useLayoutEffect(() => {
    if (hasWindow) {
      const handleResize = () => debounced(element)
      debounced(element)
      window.addEventListener('resize', handleResize, { passive: true })
      return () => window.removeEventListener('resize', handleResize)
    }
    return undefined
  }, [element, debounced])
}

export const useTraceUpdate = (props: any) => {
  const prev = useRef(props)
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps: any, [k, v]) => {
      if (prev.current[k] !== v) {
        // eslint-disable-next-line no-param-reassign
        ps[k] = [prev.current[k], v]
      }
      return ps
    }, {})
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps)
    }
    prev.current = props
  })
}

/**
 * Returns a function that calls a given function with debouncing.
 */
export const useDebouncedDispatch = <T extends any[]>(callback: (...args: T) => void, delay: number) => {
  const timeout = useRef<NodeJS.Timeout | null>(null)
  const dispatch = useDispatch()

  return useCallback(
    (...args: T) => {
      if (timeout.current) clearTimeout(timeout.current)
      timeout.current = setTimeout(() => dispatch(callback(...args)), delay)
    },
    [dispatch, callback, delay]
  )
}

/**
 * Returns a function that calls a given function with debouncing.
 */
export const useDebouncedCallback = <T extends any[]>(callback: (...args: T) => void, delay: number) => {
  const timeout = useRef<NodeJS.Timeout | null>(null)

  const debounced = useCallback(
    (...args: T) => {
      if (timeout.current) clearTimeout(timeout.current)
      timeout.current = setTimeout(() => callback(...args), delay)
    },
    [callback, delay]
  )

  const clear = useCallback(() => {
    if (timeout.current) clearTimeout(timeout.current)
  }, [])

  return [debounced, clear] as const
}
