import { Backdrop, styled, Typography } from '@material-ui/core'
import React from 'react'
import theme from 'theme'
import { useTranslate } from 'translation'
import { useAnalysisId, useId as useUIId, useShowLoading } from './hooks'

const Blackdrop = styled(Backdrop)({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: '#000A',
})

/**
 * Shows a loading overlay.
 * Shown when forced with state or when loaded analysis doesn't match the URL.
 */
const LoadingOverlay = () => {
  const uiId = useUIId()
  const loadedId = useAnalysisId()
  const showLoading = useShowLoading()
  const t = useTranslate()

  return (
    <Blackdrop open={uiId !== loadedId || showLoading}>
      <Typography variant='h3' color='secondary'>
        {t('Loading')}
      </Typography>
    </Blackdrop>
  )
}

export default LoadingOverlay
