import { IAccount } from 'shared/model'
import { Storage } from 'shared/util/storage'

export const ACCOUNT_STORAGE_KEY = 'account'
export const AUTHENTICATED_STORAGE_KEY = 'authenticated'

function getAccount(doNotThrow = false): IAccount {
  const result = Storage.local.get(ACCOUNT_STORAGE_KEY)
  if (!doNotThrow && !result) {
    throw Error('Could not found user account from storage')
  }
  return result
}

function setAccount(account: IAccount) {
  Storage.local.set(ACCOUNT_STORAGE_KEY, account)
}

function removeAccount() {
  setAuthenticated(false)
  Storage.local.remove(ACCOUNT_STORAGE_KEY)
}

function isAuthenticated(): boolean {
  return Storage.local.get(AUTHENTICATED_STORAGE_KEY) || false
}

function setAuthenticated(authenticated: boolean) {
  Storage.local.set(AUTHENTICATED_STORAGE_KEY, authenticated)
}

export const AccountStorage = {
  get: () => getAccount() as IAccount,
  tryToGet: () => getAccount(true) as IAccount | undefined,
  set: setAccount,
  remove: removeAccount,
  isAuthenticated,
  setAuthenticated,
}
