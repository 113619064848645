/* eslint-disable react/jsx-props-no-spreading */
import { Input, InputProps, makeStyles } from '@material-ui/core'
import { useEnter } from 'analyses/hooks/ui'
import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import theme from 'theme'

interface Props extends Omit<InputProps, 'onChange'> {
  value: string
  onChange: (value: string) => void
  underline?: boolean
}

const useStyles = makeStyles({
  underline: {
    '&::after': {
      content: 'none',
    },
    '&::before': {
      borderBottomColor: theme.palette.text.secondary,
    },
  },
})

/**
 * An input that triggers onChange only when pressing enter or otherwise losing focus.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SimpleInput = ({ value, onChange, underline, ...rest }: Props, ref: any) => {
  const [currentValue, setCurrentValue] = useState(value)

  useEffect(() => {
    setCurrentValue(value)
  }, [setCurrentValue, value])

  const handleChange = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentValue(target.value)
    },
    [setCurrentValue]
  )

  const handleBlur = useCallback(() => {
    onChange(currentValue)
  }, [currentValue, onChange])

  const handleKeyDown = useEnter(handleBlur)

  const classes = useStyles()
  return (
    <Input
      classes={classes}
      inputRef={ref}
      color='secondary'
      onChange={handleChange}
      value={currentValue}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      disableUnderline={!underline}
      {...rest}
    />
  )
}

export default forwardRef(SimpleInput)
