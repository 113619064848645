import { useCallback, useRef } from 'react'

/**
 * Reusable logic for mouse dragging.
 * Returns a mouse down handler which calls a given callback with current coordinates.
 */
export const useDragging = (onMove: (x: number, y: number) => void) => {
  const ref = useRef<HTMLElement | null>(null)
  const handleMouseMove = useCallback(
    (event) => {
      if (ref.current) {
        const bounds = ref.current.getBoundingClientRect()
        const x = event.clientX - bounds.left
        const y = event.clientY - bounds.top
        onMove(x, y)
      }
    },
    [onMove]
  )

  const handleMouseUp = useCallback(() => {
    ref.current = null
    document.removeEventListener('mouseup', handleMouseUp, true)
    document.removeEventListener('mousemove', handleMouseMove, true)
  }, [handleMouseMove])

  const handleMouseDown = useCallback(
    (e) => {
      ref.current = e.target?.parentElement
      document.addEventListener('mouseup', handleMouseUp, true)
      document.addEventListener('mousemove', handleMouseMove, true)
    },
    [handleMouseUp, handleMouseMove]
  )

  return handleMouseDown
}
