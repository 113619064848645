import React from 'react'
import { useWindowDimensions } from 'shared/util/hooks'
import SectionMenu from 'components/SectionMenu'
import { useTranslate } from 'translation'
import { useShowToolbar, useResultMap, useSelectedScenarios } from '../../hooks'
import ScenarioList from './ScenarioList'
import ResultMapProperties from './ResultMapProperties'
import ScenarioProperties from './ScenarioProperties'
import ExportTools from './ExportTools'
import ScenarioCount from './ScenarioCount'

const Toolbar = ({ id }: { id?: string }) => {
  const visible = useShowToolbar()
  const resultMap = useResultMap()
  const selected = useSelectedScenarios()
  const t = useTranslate()

  const { height } = useWindowDimensions()
  if (!visible || height === null || !resultMap) return null

  return (
    <SectionMenu id={id} labels={[t('Result map'), 'Marker', t('Scenarios'), t('Export'), undefined]} stretch={2}>
      <ResultMapProperties />
      <ScenarioProperties id={resultMap.id} index={selected.length ? selected[0] : undefined} />
      <ScenarioList />
      <ExportTools />
      <ScenarioCount />
    </SectionMenu>
  )
}

export default Toolbar
