import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IAnalysis, IRevision } from 'analyses/analysis.model'
import { getActionName, getRootState } from 'shared/util/reducer-utils'
import * as api from '../analysis.api'
import * as service from './services'

export type AnalysisState = Readonly<IAnalysis | null>

const reducerName = 'analysis'

// Actions

// eslint-disable-next-line @typescript-eslint/ban-types
const createCallback = <P extends unknown[], R>(thunk: AsyncThunk<R, P, {}>, type: string) => {
  const callback = (...args: P) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const action = thunk(args) as any
    // Provided for tests.
    action.type = type
    action.payload = args
    return action as Promise<R>
  }
  callback.fulfilled = thunk.fulfilled
  callback.pending = thunk.pending
  callback.rejected = thunk.rejected
  // Provided for tests.
  callback.type = type
  return callback
}

const mapApiGet = <R>(apiCall: (id: number, onlyBookmarks: boolean) => Promise<R | undefined>) => {
  const thunk = createAsyncThunk(`${reducerName}/${getActionName(apiCall)}`, async (_, { getState }) => {
    const { analysisId, onlyBookmarks } = getRootState(getState).ui
    if (!analysisId) throw Error('Failure')
    const ret = await apiCall(analysisId, onlyBookmarks)
    if (!ret) throw Error('Failure')
    return ret
  })
  return thunk
}

const mapApiEdit = <P extends unknown[]>(apiCall: (id: number, ...args: P) => Promise<IRevision | undefined>) => {
  const type = `${reducerName}/${getActionName(apiCall)}`
  const thunk = createAsyncThunk(type, async (args: P, { dispatch, getState }) => {
    const { analysis } = getRootState(getState)
    if (!analysis) throw Error('Failure')
    const { id } = analysis
    const ret = await apiCall(id, ...args)
    if (!ret) {
      // Something went wrong so better get a fresh model.
      dispatch(get())
      throw Error('Failure')
    }
    // This must be retrieved after the api call because other api calls might have changed it.
    const currentVersion = getRootState(getState).analysis?.current_version
    if (ret.parent !== currentVersion) {
      // Another user has edited so better get a fresh model.
      dispatch(get())
    }
    return ret
  })

  return createCallback(thunk, type)
}

const mapApiCall = <P extends unknown[], R>(apiCall: (id: number, ...args: P) => Promise<R | undefined>) => {
  const type = `${reducerName}/${getActionName(apiCall)}`
  const thunk = createAsyncThunk(type, async (args: P, { getState }) => {
    const { analysisId } = getRootState(getState).ui
    if (!analysisId) throw Error('Failure')
    const ret = await apiCall(analysisId, ...args)
    if (!ret) throw Error('Failure')
    return ret
  })
  return createCallback(thunk, type)
}

const get = mapApiGet(api.get)
const getRevisions = mapApiGet(api.getRevisions)
const revertToRevision = mapApiCall(api.revertToRevision)
const bookmarkRevision = mapApiCall(api.bookmarkRevision)

const setConsistency = mapApiEdit(api.setConsistency)
const addUncertainty = mapApiEdit(api.addUncertainty)
const removeUncertainty = mapApiEdit(api.removeUncertainty)
const changeUncertaintyName = mapApiEdit(api.changeUncertaintyName)
const changeUncertaintyOrder = mapApiEdit(api.changeUncertaintyOrder)
const addOutcome = mapApiEdit(api.addOutcome)
const removeOutcome = mapApiEdit(api.removeOutcome)
const changeOutcomeName = mapApiEdit(api.changeOutcomeName)
const changeOutcomeOrder = mapApiEdit(api.changeOutcomeOrder)
const changeAnalysisName = mapApiEdit(api.changeAnalysisName)
const addResultMap = mapApiEdit(api.addResultMap)
const removeResultMap = mapApiEdit(api.removeResultMap)
const changeResultMapName = mapApiEdit(api.changeResultMapName)
const setShownScenarios = mapApiEdit(api.setShownScenarios)
const setContourLevels = mapApiEdit(api.setContourLevels)
const setPrimaryResultMap = mapApiEdit(api.setPrimaryResultMap)
const setColorScale = mapApiEdit(api.setColorScale)
const setShowSelectionDiamonds = mapApiEdit(api.setShowSelectionDiamonds)
const markScenario = mapApiEdit(api.markScenario)

// Action / reducer mapping.
const slice = createSlice({
  name: reducerName,
  initialState: null as AnalysisState,
  reducers: {
    clearAnalysisState: service.clearAnalysisState,
  },
  extraReducers: (builder) => {
    builder.addCase(get.fulfilled, service.setAnalysis)
    builder.addCase(getRevisions.fulfilled, service.setRevisions)
    builder.addCase(bookmarkRevision.fulfilled, service.editRevisionDescription)

    builder.addCase(revertToRevision.pending, service.revertToRevision)
    builder.addCase(revertToRevision.fulfilled, service.setAnalysis)

    builder.addCase(addUncertainty.fulfilled, service.addUncertainty)

    builder.addCase(changeUncertaintyName.pending, service.changeUncertaintyName)
    builder.addCase(changeUncertaintyName.fulfilled, service.addRevision)

    builder.addCase(changeUncertaintyOrder.pending, service.changeUncertaintyOrder)
    builder.addCase(changeUncertaintyOrder.fulfilled, service.addRevision)

    builder.addCase(removeUncertainty.pending, service.removeUncertainty)
    builder.addCase(removeUncertainty.fulfilled, service.addRevision)

    builder.addCase(setConsistency.pending, service.setConsistency)
    builder.addCase(setConsistency.fulfilled, service.addRevision)

    builder.addCase(addOutcome.fulfilled, service.addOutcome)

    builder.addCase(changeOutcomeName.pending, service.changeOutcomeName)
    builder.addCase(changeOutcomeName.fulfilled, service.addRevision)

    builder.addCase(changeOutcomeOrder.pending, service.changeOutcomeOrder)
    builder.addCase(changeOutcomeOrder.fulfilled, service.addRevision)

    builder.addCase(removeOutcome.pending, service.removeOutcome)
    builder.addCase(removeOutcome.fulfilled, service.addRevision)

    builder.addCase(changeAnalysisName.pending, service.changeAnalysisName)
    builder.addCase(changeAnalysisName.fulfilled, service.addRevision)

    builder.addCase(addResultMap.fulfilled, service.addResultMap)

    builder.addCase(removeResultMap.pending, service.removeResultMap)
    builder.addCase(removeResultMap.fulfilled, service.addRevision)

    builder.addCase(setPrimaryResultMap.pending, service.setPrimaryResultMap)
    builder.addCase(setPrimaryResultMap.fulfilled, service.addRevision)

    builder.addCase(setShownScenarios.pending, service.setShownScenarios)
    builder.addCase(setShownScenarios.fulfilled, service.addRevision)

    builder.addCase(setContourLevels.pending, service.setContourLevels)
    builder.addCase(setContourLevels.fulfilled, service.addRevision)

    builder.addCase(setColorScale.pending, service.setColorScale)
    builder.addCase(setColorScale.fulfilled, service.addRevision)

    builder.addCase(changeResultMapName.pending, service.changeResultMapName)
    builder.addCase(changeResultMapName.fulfilled, service.addRevision)

    builder.addCase(setShowSelectionDiamonds.pending, service.setShowSelectionDiamonds)
    builder.addCase(setShowSelectionDiamonds.fulfilled, service.addRevision)

    builder.addCase(markScenario.pending, service.markScenario)
    builder.addCase(markScenario.fulfilled, service.addRevision)
  },
})
export const { clearAnalysisState } = slice.actions
export default slice.reducer
export {
  addOutcome,
  changeOutcomeName,
  get,
  revertToRevision,
  getRevisions,
  bookmarkRevision,
  setConsistency,
  addUncertainty,
  changeUncertaintyName,
  removeUncertainty,
  removeOutcome,
  changeUncertaintyOrder,
  changeOutcomeOrder,
  changeAnalysisName,
  addResultMap,
  removeResultMap,
  changeResultMapName,
  setPrimaryResultMap,
  setShownScenarios,
  setContourLevels,
  setColorScale,
  markScenario,
  setShowSelectionDiamonds,
}
